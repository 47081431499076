import React from 'react'
import { SingleHelpSectionInterface } from "../../apps/help_center/HelpSectionsApp"

interface Props {
    section: SingleHelpSectionInterface
}

export default function SingleSectionBox(props: Props) {

    const { title, thumbnail, slug } = props.section

    return(
        <a href={`/help/sections/${slug}`}>
            <div className="single-help-section-box">
                <div className="content-of-single-help-section-box">

                    <div className="thumb-box-of-single-help-section-box">
                        <div className="content-of-thumb-box-of-single-help-section-box">
                            <img src={thumbnail} />
                        </div>
                    </div>

                    <div className="title-box-of-single-help-section-box">
                        <div className="content-of-title-box-of-single-help-section-box">
                            <h3>{title}</h3>
                        </div>
                    </div>

                </div>
            </div>
        </a>
    )
}
