import React, { useLayoutEffect } from "react"
import Grid from '@mui/material/Grid';
import Logo from "../../../assets/svg/hitap-logo.svg"
import GenerateIcon from '../../FormElements/GenerateIcon'
import { IconButton } from "@mui/material";
import { hideAdminSidebar } from "./Sidebar"
import AdminMenu from "./AdminMenu";

export default function AdminHeader(){

    useLayoutEffect(() => {

        if (window.innerWidth <= 1600){
            hideAdminSidebar(true)
        }

    }, [])

    return(
        <div className="admin-header-container">
            <div className="content-of-admin-header-container">

                <Grid container spacing={2}>

                    <Grid item xs={2}>
                        <div className="logo-box-of-admin-header-container">
                            <div className="content-of-logo-box-of-admin-header-container">

                                <img src={Logo} />

                                <div className="hide-sidebar-box">
                                    <div className="content-of-hide-sidebar-box">
                                        <IconButton onClick={() => hideAdminSidebar(window.innerWidth <= 1600 ? false : true)}>
                                            <GenerateIcon icon="menu" />
                                        </IconButton>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={10}>
                        <div className="menu-container-of-admin-header-container">
                            <div className="content-of-menu-container-of-admin-header-container">
                                <AdminMenu />
                            </div>
                        </div>
                    </Grid>

                </Grid>

            </div>
        </div>
    )
}