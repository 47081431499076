import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FolderIcon from '@mui/icons-material/Folder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import StorageIcon from '@mui/icons-material/Storage';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import GroupIcon from '@mui/icons-material/Group';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import BusinessIcon from '@mui/icons-material/Business';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import GroupsIcon from '@mui/icons-material/Groups';
import DiamondIcon from '@mui/icons-material/Diamond';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Menu';
import StyleIcon from '@mui/icons-material/Style';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Icon, SvgIcon, makeStyles, Theme } from '@mui/material';
import { ReactComponent as VimeoLogo } from "../../assets/svg/vimeo.svg"
import { ReactComponent as ViberLogo } from "../../assets/svg/viber.svg"
import { ReactComponent as SkypeLogo } from "../../assets/svg/skype.svg"
import { ReactComponent as TiktokLogo } from "../../assets/svg/tiktok.svg"
import { ReactComponent as SnapchatLogo } from "../../assets/svg/snapchat.svg"
import MapIcon from '@mui/icons-material/Map';
import CodeIcon from '@mui/icons-material/Code';
import ContactlessIcon from '@mui/icons-material/Contactless';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SubjectIcon from '@mui/icons-material/Subject';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import ContactsIcon from '@mui/icons-material/Contacts';
import SearchIcon from '@mui/icons-material/Search';
import QuizIcon from '@mui/icons-material/Quiz';
import ListAltIcon from '@mui/icons-material/ListAlt';
import QrCodeIcon from '@mui/icons-material/QrCode';
import CommentIcon from '@mui/icons-material/Comment';

interface Props {
    icon: string
}
export default function GenerateIcon(props: Props) {

    const { icon } = props

    switch(icon){

        case "dashboard":
            return <HomeIcon />

        case "error":
            return <ErrorOutlineIcon />

        case "warning":
            return <WarningIcon />

        case "warehouse":
            return <WarehouseIcon />

        case "categories":
            return <FolderIcon />

        case "view":
            return <VisibilityIcon />

        case "edit":
            return <EditIcon />

        case "delete":
            return <DeleteIcon />

        case "info":
            return <InfoIcon />

        case "assignment":
            return <AssignmentIcon />

        case "add":
            return <AddIcon />

        case "back":
            return <ArrowBackIosNewIcon />

        case "forward":
            return <ArrowForwardIosIcon />

        case "refresh":
            return <RefreshIcon />

        case "storage":
            return <StorageIcon />

        case "upload":
            return <CloudUploadIcon />

        case "image":
            return <PhotoSizeSelectActualIcon />

        case "cart":
            return <ShoppingCartIcon />

        case "send":
            return <SendIcon />

        case "close":
            return <CloseIcon />

        case "approve":
            return <DoneAllIcon />

        case "pdf":
            return <PictureAsPdfIcon />

        case "arrow-down":
            return <KeyboardArrowDownIcon />

        case "arrow-up":
            return <KeyboardArrowUpIcon />

        case "success":
            return <DoneAllIcon />

        case "location":
            return <LocationOnIcon />

        case "checkbox-checked":
            return <CheckBoxIcon />

        case "checkbox-blank":
            return <CheckBoxOutlineBlankIcon />

        case "user":
            return <PersonIcon />

        case "company":
            return <BusinessIcon />

        case "designation":
            return <HomeRepairServiceIcon />

        case "users":
            return <GroupIcon />

        case "projects":
            return <FormatListNumberedRtlIcon />

        case "user-groups":
            return <GroupsIcon />

        case "diamond":
            return <DiamondIcon />

        case "shipping":
            return <LocalShippingIcon />

        case "email":
            return <EmailIcon />

        case "password":
            return <LockIcon />

        case "menu":
            return <MenuIcon />

        case "nfc":
            return <StyleIcon />

        case "cards":
            return <RecentActorsIcon />

        case "phone":
            return <LocalPhoneIcon />

        case "website":
            return <LanguageIcon />

        case "instagram":
            return <InstagramIcon />

        case "facebook":
            return <FacebookIcon />

        case "address":
            return <BusinessIcon />

        case "telegram":
            return <TelegramIcon />

        case "link":
            return <InsertLinkIcon />

        case "drag":
            return <DragHandleIcon />

        case "up":
            return <KeyboardArrowUpIcon />

        case "down":
            return <KeyboardArrowDownIcon />

        case "linkedin":
            return <LinkedInIcon />

        case "whatsapp":
            return <WhatsAppIcon />

        case "twitter":
            return <TwitterIcon />


        case "youtube":
            return <YouTubeIcon />

        case "vimeo":
            return (
                <SvgIcon>
                    <VimeoLogo />
                </SvgIcon>
            )

        case "viber":
            return (
                <SvgIcon>
                    <ViberLogo />
                </SvgIcon>
            )

        case "skype":
            return (
                <SvgIcon>
                    <SkypeLogo />
                </SvgIcon>
            )

        case "tiktok":
            return (
                <SvgIcon>
                    <TiktokLogo />
                </SvgIcon>
            )


        case "snapchat":
            return (
                <SvgIcon>
                    <SnapchatLogo />
                </SvgIcon>
            )  
            
        case "googlemaps":
            return <MapIcon />

        case "iframe":
            return <CodeIcon />

        case "nfc":
            return <ContactlessIcon />

        case "folder":
            return <FolderIcon />

        case "folder-open":
            return <FolderOpenIcon />

        case "title":
            return <SubjectIcon />

        case "images":
            return <PermMediaIcon />

        case "contact-box":
            return <ContactMailIcon />

        case "hide":
            return <VisibilityOffIcon />

        case "chart":
            return <InsertChartIcon />

        case "contacts":
            return <ContactsIcon />
            
        case "search":
            return <SearchIcon />

        case "quiz":
            return <QuizIcon />

        case "posts":
            return <ListAltIcon />

        case "qr-code":
            return <QrCodeIcon />

        case "comment":
            return <CommentIcon />

        default:
            return <HomeIcon />

    }

}