import React from 'react'
import WhatImg from "../../../assets/svg/what-is-nfc.svg"

export default function WhatsIsNFCCard() {
    return(
        <div className="why-nfc-card-section-container">
            <div className="content-of-why-nfc-card-section-container content-section-wrapper">

                <div className="wrapper-of-intro-section-home-page">

                    <div className="text-box-of-intro-section-home-page">
                        <div className="content-of-text-box-of-intro-section-home-page">

                            <h1>
                                What is NFC Card?
                            </h1>

                            <p>
                                NFC or Near Field Communication is a short-range wireless communication technology that enables users to exchange data content with a simple tap using their smartphone devices. Move the device closer to the contactless cards NFC, wait a few seconds and get all necessary information. It is cost-effective, fast, and does not require app installation.
                            </p>

                        </div>
                    </div>

                    <div className="img-box-of-intro-section-home-page">
                        <div className="content-of-img-box-of-intro-section-home-page">
                            <img src={WhatImg} />
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}