import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { RootState, store } from "../../app/store"
import { FormElementsForm, FormElementsFormElement, FormSelectSingleItem } from "./Interfaces"
import { Forms } from "./Types"

const initialState: Forms = []

const formsSlice = createSlice({
    name: "forms",
    initialState,
    reducers: {
        addNewForm: (state: Forms, action: PayloadAction<FormElementsForm>) => {
            const forms = state
            if (forms.filter(form => form.name === action.payload.name).length === 0){
                state.push(action.payload)
            }
        },
        updateSelectItems: (state: Forms, action: PayloadAction<{
            items: Array<FormSelectSingleItem>,
            name: string,
            formName: string
        }>) => {
            const payload = action.payload
            let elementIndex = 0
            let formIndex = 0

            if (state.length > 0){
                state.map((form) => {
                    if (form.name === payload.formName){

                        const elements = form.elements

                        if (elements.length > 0){
                            const findElement = elements.findIndex(object => {
                                return object.id === payload.name;
                            });
                            

                            if (findElement === 0 || findElement > 0){
                                elementIndex = findElement
                            }

                        }

                    }
                })

                formIndex = state.findIndex(form => form.name === payload.formName)
            }

            if (formIndex === 0 || formIndex > 0){
                if (state[formIndex]){
                    if (state[formIndex].elements[elementIndex]) {
                        state[formIndex].elements[elementIndex].items = payload.items
                    }
                }
            }

        },
    }
})

export const { addNewForm, updateSelectItems } = formsSlice.actions

/**
 * Return Forms
 */
export const useReturnForms = () => useSelector((state: RootState) => state.forms)

/**
 * Return single form by its name
 */
export const useReturnSingleForm = (name: string) => {
    return useSelector((state: RootState) => {
        const forms = state.forms

        if (forms && forms.length > 0){
            const singleForm = forms.filter(form => form.name === name)![0]

            if (singleForm){
                return singleForm
            }else{
                return false
            }

        }else{
            return false
        }

    })
}

export const returnSelectItems = (name: string, formName: string) => {
    let elementIndex = 0
    let formIndex = 0

    const state = store.getState().forms

    if (state.length > 0){
        state.map((form) => {
            if (form.name === formName){

                const elements = form.elements

                if (elements.length > 0){
                    const findElement = elements.findIndex(object => {
                        return object.id === name;
                    });
                    

                    if (findElement === 0 || findElement > 0){
                        elementIndex = findElement
                    }

                }

            }
        })

        formIndex = state.findIndex(form => form.name === formName)
    }

    if (formIndex === 0 || formIndex > 0){
        if (state[formIndex]){
            if (state[formIndex].elements[elementIndex]) {
                return state[formIndex].elements[elementIndex].items
            }
        }
    }

    return []
}

export default formsSlice.reducer