import { FormControl, FormHelperText, Grid, TextField } from '@mui/material'
import React from 'react'
import { FormElementData, FormTextFieldElement } from '../Interfaces'
import GenerateIcon from "../GenerateIcon"
import FormElementTextField from "./FormElementTextField"

interface Props extends FormTextFieldElement{
    title?: string,
    data: FormElementData,
    handleOnChange: (name: string, value: any) => void,
    handleSubmitForm?: () => void
}

export default function TwoFactorAuthCodeField(props: Props) {
    return(
        <div className="two-factor-auth-code-field-container">
            <div className="content-of-two-factor-auth-code-field-container">

                    <div className="two-factor-auth-head-box">
                        <div className="content-of-two-factor-auth-head-box">

                            <div className="icon-box-of-two-factor-auth-head-box">
                                <div className="content-of-icon-box-of-two-factor-auth-head-box">
                                    <GenerateIcon icon="password" />
                                </div>
                            </div>

                            <div className="text-box-of-two-factor-auth-head-box">
                                <div className="content-of-text-box-of-two-factor-auth-head-box">
                                    <h3>{props.title ? props.title : "Two-Factor Authentication"}</h3> 
                                    <p>{props.label}</p>
                                </div>
                            </div>

                        </div>
                    </div>  

                    <div className="actual-form-of-login-form-container">
                        <div className="two-factor-auth-form">
                            <FormElementTextField {...props} label="OTP" />
                        </div>
                    </div>

            </div>
        </div>
    )
}