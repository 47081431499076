import React, { useState } from 'react'
import { SingleCardInterface, SingleCardLinkInterface } from './CardsApp'
import { addManyLinks, useReturnCardDetailsFromBuilder, useReturnCardLinksFromBuilder } from './cardBuilderSlice'
import { useDispatch } from 'react-redux'
import GenerateMessageBox from '../../FormElements/GenerateMessageBox'
import AddLinksModal from "./AddLinksModal"
import { IconButton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'
import SingleBuilderLink from "./SingleBuilderLink"
import { DragDropContext, Droppable, Draggable, DropResult, DragStart, ResponderProvided } from 'react-beautiful-dnd';

interface Props {
    update?: boolean
}

export default function LinksCardBuilder(props: Props) {

    const cardDetails: SingleCardInterface = useReturnCardDetailsFromBuilder()
    const cardLinks: Array<SingleCardLinkInterface> = useReturnCardLinksFromBuilder()
    const dispatch = useDispatch()

    const [itemBeingMovedId, setItemBeingMovedId] = useState<number | boolean>(false)

    /**
     * Links Modal status
     */
    const [linksModalStatus, setLinksModalStatus] = useState<boolean>(false)

    /**
     * Open links modal status
     */
    const handleOpenLinksModalStatus = () => setLinksModalStatus(true)

    /**
     * Close links modal status
     */
    const handleCloseLinksModalStatus = () => setLinksModalStatus(false)

    /**
     * Drag and drop
     */
    const onBeforeCapture = () => {
        
    }
    
    const onBeforeDragStart = () => {
        
    }
    
    const onDragStart = (start: DragStart, provided: ResponderProvided) => {
        setItemBeingMovedId(Number(start.draggableId))
    }
    
    const onDragUpdate = () => {
    
    }

    const reorder = (list: Array<SingleCardLinkInterface>, startIndex: number, endIndex: number) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        let reorderedItems: Array<SingleCardLinkInterface> = []

        let orderNum: number = 1
        result.map((item: SingleCardLinkInterface) => reorderedItems.push({
            id: item.id,
            card_id: item.card_id,
            url: item.url,
            title: item.title,
            type: item.type,
            is_embeded_video: item.is_embeded_video,
            order_num: orderNum++,
            date_created: item.date_created
        }))

        return reorderedItems
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const content = reorder(cardLinks, result.source.index, result.destination.index)
        dispatch(addManyLinks(content))

        setItemBeingMovedId(false)
    }

    return(
        <div className="card-links-builder-container">
            <div className="content-of-card-links-builder-container">

                <AddLinksModal open={linksModalStatus} handleClose={handleCloseLinksModalStatus} />

                <div className="card-builder-title-box">
                    <div className="content-of-card-builder-title-box">
                        <h3>
                            Additional Information
                            <IconButton onClick={() => handleOpenLinksModalStatus()} style={{ marginLeft: 5, marginTop: -2 }}>
                                <GenerateIcon icon="add" />
                            </IconButton>
                        </h3>
                    </div>
                </div>

                {/* {cardLinks.length === 0 && <GenerateMessageBox type="info" title="Nothing yet..." message="" customMessage={() => <p>You haven't added any link yet. <a style={{ cursor: "pointer" }} onClick={() => handleOpenLinksModalStatus()}>Create a new link</a></p>} />} */}

                <div className="all-the-links-of-card-links-builder-container">
                    <div className="content-of-all-the-links-of-card-links-builder-container">
                        <DragDropContext
                            onBeforeCapture={onBeforeCapture}
                            onBeforeDragStart={onBeforeDragStart}
                            onDragStart={onDragStart}
                            onDragUpdate={onDragUpdate}
                            onDragEnd={onDragEnd}
                        >
                            <Droppable droppableId="links">
                                {(provided) => <ul ref={provided.innerRef} {...provided.droppableProps}>{cardLinks.length > 0 && cardLinks.map((link: SingleCardLinkInterface, index: number) => <Draggable key={index} draggableId={index.toString()} index={index}>
                                    {(provided) => (
                                        <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <SingleBuilderLink {...link} update={props.update} itemBeingMovedId={itemBeingMovedId} index={index} />
                                        </li>
                                    )}
                                </Draggable>)}{provided.placeholder}</ul>}
                            </Droppable>
                        </DragDropContext>
                                        
                        <div className="add-new-link-box" onClick={() => handleOpenLinksModalStatus()}>
                            <div className="content-of-add-new-link-box">
                                <GenerateIcon icon="add" />
                                <h3>Add a new link</h3>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}