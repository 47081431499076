import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import FoldersApp, { FolderInterface } from './FoldersApp';
import { addNewFolder } from './foldersSlice';

interface Props {
    open: boolean,
    handleClose: () => void
}

export default function NewFolderModal(props: Props) {

    const { open, handleClose } = props
    const dispatch = useDispatch()

    const [title, setTitle] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    const foldersApp = new FoldersApp()

    /**
     * Add new folder
     */
    const createFolder = (folder: FolderInterface) => dispatch(addNewFolder(folder))

    /**
     * Handle submit
     */
    const handleSubmit = () => {
        setLoading(true)

        foldersApp.createNewInstance({ title })
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:
                        createFolder(data)
                        break

                }

                setLoading(false)
                handleClose()
                setTitle("")

            })
            .catch(error => {
                foldersApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setErrorMessage(message)
            })

    }

    const handleOnKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (title){
            if (event.key === "Enter"){
                handleSubmit()
            }
        }
    }

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                {"New folder"}
            </DialogTitle>

            <DialogContent>
                <div style={{ marginTop: 5 }}>
                    <TextField id="outlined-basic" disabled={loading} label="Title" variant="outlined" fullWidth value={title} onChange={(e) => setTitle(e.target.value)} onKeyUp={handleOnKeyUp} helperText={errorMessage} error={errorMessage ? true : false} />
                </div>
            </DialogContent>

            <DialogActions>
                <LoadingButton loading={loading} disabled={title ? false : true} onClick={handleSubmit}>Create</LoadingButton>
                <Button onClick={handleClose} autoFocus>
                    Cancel
                </Button>
            </DialogActions>

      </Dialog>
    )
}