import React, { useEffect, useState } from 'react'
import CardsApp, { SingleCardLinkInterface } from './CardsApp'
import CardLink from "./CardLink"

interface Props {
    cardId: number,
    customLinks?: Array<SingleCardLinkInterface>,
    showCustomLinks?: boolean,
    cardLoading?: boolean,
    primaryColor?: string,
    textColor?: string,
    personName?: string,
    subTitleColor?: string
}

export default function CardLinks(props: Props) {

    const [links, setLinks] = useState<Array<SingleCardLinkInterface>>([])
    const [loading, setLoading] = useState<boolean>(true)


    const { cardId } = props

    const cardsApp = new CardsApp()

    /**
     * Load links
     */
    const loadLinks = () => {
        setLoading(true)

        cardsApp.getListOfLinks(cardId)
            .then(response => {

                const { data, status, statusText } = response

                switch(status){

                    case 200:
                        
                        setLinks(data)
                        setLoading(false)
                        break

                }

            })  
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }

    /**
     * Use effect
     */
    useEffect(() => {

        if (props.showCustomLinks){
            setLinks(props.customLinks ? props.customLinks : [])
            setLoading(false)
        }else{
            loadLinks()
        }

    }, [props])

    const loadingLinks: Array<SingleCardLinkInterface> = [
        {
            id: 1,
            type: "phone",
            url: "",
            title: "",
            is_embeded_video: false,
            order_num: 1,
            date_created: "",
            card_id: 0
        },
        {
            id: 2,
            type: "phone",
            url: "",
            title: "",
            is_embeded_video: false,
            order_num: 1,
            date_created: "",
            card_id: 0
        },
        {
            id: 3,
            type: "phone",
            url: "",
            title: "",
            is_embeded_video: false,
            order_num: 1,
            date_created: "",
            card_id: 0
        },
        {
            id: 4,
            type: "phone",
            url: "",
            title: "",
            is_embeded_video: false,
            order_num: 1,
            date_created: "",
            card_id: 0
        },
        {
            id: 5,
            type: "phone",
            url: "",
            title: "",
            is_embeded_video: false,
            order_num: 1,
            date_created: "",
            card_id: 0
        }
    ]

    const renderLinks = (links: Array<SingleCardLinkInterface>, loading: boolean = false) => links.map((link: SingleCardLinkInterface, index: number) => <CardLink {...link} key={index} loading={loading} primaryColor={props.primaryColor} textColor={props.textColor} subTitleColor={props.subTitleColor} personName={props.personName} />)

    return(
        <div className="card-links-container">
            <div className="content-of-card-links-container">
                {loading || props.cardLoading ? renderLinks(loadingLinks, true) : links.length > 0 && renderLinks(props.showCustomLinks ? props.customLinks ? props.customLinks : [] : links, false)}
            </div>
        </div>
    )
}