import React from 'react'
import UserInfoBox from '../../AdminPanel/components/UserInfoBox'
import ProfileMenu from "./ProfileMenu"
import { User } from '../../Api/Interfaces'

interface Props {
    user: User
}

export default function ProfileSidebar(props: Props) {

    return(
        <div className="user-profile-sidebar-container">
            <div className="content-of-user-profile-sidebar-container">

                <UserInfoBox />
                
                <div className="profile-menu-container-wrapper">
                    <ProfileMenu />
                </div>

            </div>
        </div>
    )
}