import React from 'react'
import { FormElementModal, FormElementModalSingleButton } from './Interfaces'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function GenerateModal(props: FormElementModal) {

    const { open, title, message, buttons } = props

    if (buttons?.length === 0){
        buttons.push({
            label: "Close",
            onClick: () => handleClose
        })
    }

    /**
     * Handle close
     */
    const handleClose = () => props.updateModalDetails(false, title, message, buttons)

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>

            <DialogContent>
                {props.customContent ? <>{props.customContent()}</> : (
                     <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                )}
            </DialogContent>

            <DialogActions>
                {buttons && buttons.length > 0 && buttons.map((button: FormElementModalSingleButton, index: number) => <Button onClick={button.onClick} key={index}>{button.label}</Button>)}
            </DialogActions>
      </Dialog>
    )
}