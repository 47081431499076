import { Grid, TextField, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ChipsApp, { NFCChipInterface } from './ChipsApp'
import LoadingFieldElement from '../../FormElements/Elements/LoadingFieldElement'
import GenerateMessageBox, { MessageBoxTypes } from '../../FormElements/GenerateMessageBox'
import { AxiosDefaultDataObject } from '../../Api/Interfaces'
import { LoadingButton } from '@mui/lab'
import FormElementSelect from '../../FormElements/Elements/FormElementSelect'
import { FormElementData } from '../../FormElements/Interfaces'

export default function CreateNewChipPage() {

    const chipsApp = new ChipsApp()

    const [chipDetails, setChipDetails] = useState<NFCChipInterface>({
        id: 0,
        title: "",
        unique_name: "",
        type: "card",
        chip_type: "card",
        redirect_url: "",
        card_id: 0,
        company_id: 0,
        user_id: 0,
        is_registered: false,
        is_public: true,
        is_active: true,
        date_created: "",
        folder_id: 0,
        company_name: "",
        user_name: ""
    })
    const [loadingUniqueName, setLoadingUniqueName] = useState<boolean>(true)
    const [submitButtonLoading, setSubmitButtonLoading] = useState<boolean>(false)
    const [submitButtonDisabledStatus, setSubmitButtonDisabledStatus] = useState<boolean>(false)

    const [chipTypeData, setChipTypeData] = useState<FormElementData>({
        name: "chip_type",
        value: ""
    })

    const updateChipTypeData = (value: any) => setChipTypeData({
        name: "chip_type",
        value
    })

    /**
     * Submit Request Response Text
     */
    interface ISubmitRequestResponse {
        type: MessageBoxTypes,
        title: string,
        message: string,
        display: boolean
    }
    const [submitRequestResponse, setSubmitRequestResponse] = useState<ISubmitRequestResponse>({
        type: "info",
        title: "No title",
        message: "No message",
        display: false
    })

    /**
     * Load unique name
     */
    const loadUniqueName = () => {
        chipsApp.generateChipUniqueName()
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:

                        let chipData = chipDetails
                        chipData.unique_name = data.unique_name ? data.unique_name : ""
                        chipData.title = data.title ? data.title : ""
                        setChipDetails(chipData)

                        setLoadingUniqueName(false)
                        break

                }

                setSubmitButtonLoading(false)

            })
            .catch(error => {
                chipsApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setSubmitRequestResponse({
                    display: true,
                    type: "error",
                    message: message,
                    title: "Error"
                })
                setSubmitButtonLoading(false)
                setLoadingUniqueName(false)
            })
    }

    /**
     * Create new chip
     */
    const createNewChip = () => {

        let data: AxiosDefaultDataObject = {
            ...chipDetails
        }

        delete data.date_created
        delete data.company_name
        delete data.id

        setSubmitButtonLoading(true)
       
        chipsApp.createNewInstance(data)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:
                        setSubmitRequestResponse({
                            display: true,
                            type: "success",
                            message: `${chipDetails.title} has been successfully added!`,
                            title: "Success"
                        })

                        setTimeout(() => window.location.replace("/panel/chips"), 1500)
                        break

                }

                setSubmitButtonLoading(false)
                setSubmitButtonDisabledStatus(true)

            })
            .catch(error => {
                chipsApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setSubmitRequestResponse({
                    display: true,
                    type: "error",
                    message: message,
                    title: "Error"
                })
                setSubmitButtonLoading(false)
            })

    }

    /**
     * use effect
     */
    useEffect(() => {
        loadUniqueName()
        updateChipTypeData(chipDetails.chip_type)
    }, [])

    /**
     * Generate Response Message
     */
    const generateResponseMessage = () => {
        if (!submitRequestResponse.display){
            return <></>
        }

        return (
            <div className="request-response-message-container">
                <div className="content-of-request-response-message-container">
                    <GenerateMessageBox type={submitRequestResponse.type} title={submitRequestResponse.title} message={submitRequestResponse.message} />
                </div>
            </div>
        )
    }

    /**
     * Update title
     */
    const updateTitle = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setChipDetails({
            ...chipDetails,
            title: e.target.value
        })
    }

    /**
     * Update title
     */
    const updateUniqueName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setChipDetails({
            ...chipDetails,
            unique_name: e.target.value
        })
    }

    /**
     * Update title
     */
    const updateChipType = (value: any) => {
        setChipDetails({
            ...chipDetails,
            chip_type: value
        })
        updateChipTypeData(value)
    }

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">
                
                <Grid container spacing={2}>

                    <Grid item xs={6}>
                        {loadingUniqueName ? <LoadingFieldElement label="Chip title" /> : <TextField id="title" label="Chip title" variant="outlined" fullWidth value={chipDetails.title} onChange={updateTitle} />}
                    </Grid>
                    
                    <Grid item xs={6}>
                        {loadingUniqueName ? <LoadingFieldElement label="Unique name" /> : <TextField id="unique_name" label="Unique name" variant="outlined" fullWidth value={chipDetails.unique_name} onChange={updateUniqueName} />}
                    </Grid>

                    <Grid item xs={12}>
                        {loadingUniqueName ? <LoadingFieldElement label="Chip Type" /> : <FormElementSelect fullWidth id="chip_type" label="Chip type" data={chipTypeData} handleOnChange={(name, value) => updateChipType(value)} items={chipsApp.nfcChipTypes} />}
                    </Grid>

                    <Grid item xs={12}>
                        <LoadingButton loading={submitButtonLoading} disabled={submitButtonDisabledStatus} variant="contained" onClick={() => createNewChip()}>Create</LoadingButton>
                    </Grid>

                </Grid>

                {generateResponseMessage()}

            </div>
        </div>
    )
}