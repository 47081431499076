import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, store } from "../../../app/store"
import { SlideInterface, SliderInterface } from "./SlidersApp"
import { useSelector } from "react-redux"

interface SlidersInitialStateInterface {
    sliders: Array<SliderInterface>
}

const initialState: SlidersInitialStateInterface = {
    sliders: []
}

const slidersSlice = createSlice({
    name: "sliders",
    initialState,
    reducers: {
        addSliders: (state: SlidersInitialStateInterface, action: PayloadAction<Array<SliderInterface>>) => {
            const payload = action.payload
            state.sliders = payload
        },
        updateSlider: (state: SlidersInitialStateInterface, action: PayloadAction<SliderInterface>) => {
            const payload = action.payload

            let sliders: Array<SliderInterface> = [
                ...state.sliders
            ]
            const index = sliders.map(f => f.id).indexOf(payload.id)

            if (sliders[index]){
                sliders[index] = payload
            }

            state.sliders = sliders
            
        },
        addSlide: (state: SlidersInitialStateInterface, action: PayloadAction<{ slide: SlideInterface, sliderId: number }>) => {
            const payload = action.payload

            let sliders: Array<SliderInterface> = [
                ...state.sliders
            ]
            const index = sliders.map(f => f.id).indexOf(payload.sliderId)

            if (sliders[index]){
                let slider = sliders[index]
                let slides: Array<SlideInterface> = []

                if (slider.slides.length > 0){
                    slider.slides.map(singleSlide => slides.push(singleSlide))
                }

                slides.push(payload.slide)

                slider.slides = slides 
            
                
                sliders[index] = slider
            }

            state.sliders = sliders
        },
        updateSlides: (state: SlidersInitialStateInterface, action: PayloadAction<{ slides: Array<SlideInterface>, sliderId: number }>) => {
            const payload = action.payload

            let sliders: Array<SliderInterface> = [
                ...state.sliders
            ]
            const index = sliders.map(f => f.id).indexOf(payload.sliderId)

            if (sliders[index]){                
                sliders[index].slides = payload.slides
            }

            state.sliders = sliders
        },
        removeSlide: (state: SlidersInitialStateInterface, action: PayloadAction<{ sliderId: number, slideId: number }>) => {
            const payload = action.payload

            let sliders: Array<SliderInterface> = [
                ...state.sliders
            ]
            const index = sliders.map(f => f.id).indexOf(payload.sliderId)

            if (sliders[index]){                
                
                let slides = sliders[index].slides
                slides = slides.filter(slide => slide.id !== payload.slideId)

                sliders[index].slides = slides
            }

            state.sliders = sliders
        },
        updateSlide: (state: SlidersInitialStateInterface, action: PayloadAction<{ sliderId: number, slide: SlideInterface }>) => {
            const payload = action.payload

            let sliders: Array<SliderInterface> = [
                ...state.sliders
            ]
            const index = sliders.map(f => f.id).indexOf(payload.sliderId)

            if (sliders[index]){                
                
                let slides = sliders[index].slides
                const slideIndex = slides.map(sl => sl.id).indexOf(payload.slide.id)

                if (slides[slideIndex]){
                    slides[slideIndex] = payload.slide
                    sliders[index].slides = slides
                }

            }

            state.sliders = sliders
        }
    }
})

/**
 * Return sliders
 */
export const useReturnSliders = () => useSelector((state: RootState) => state.sliders.sliders)

/**
 * Return slider
 */
export const useReturnSingleSlider = (sliderId: number) => {
    const sliders = useSelector((state: RootState) => state.sliders.sliders)
    let slider: SliderInterface = {
        id: 0,
        title: "",
        slides: [],
        user_id: 0,
        date_created: ""
    }

    sliders.map(s => {
        if (s.id === sliderId){
            slider = s
        }
    })

    return slider

}

export const { addSliders, updateSlider, addSlide, updateSlide, removeSlide } = slidersSlice.actions
export default slidersSlice.reducer