import React from 'react'

import InRealTimeVideo from "../../../assets/video/real-time-updates.mp4"
import StatisticsVideo from "../../../assets/video/statistics.mp4"
import Video from "../components/Video"

export default function Features() {
    return(
        <div className="features-container-section" id="features-section">
            <div className="content-of-features-container-section">

                <div className="single-feature-container grayed-out-feature">
                    <div className="content-of-single-feature-container content-section-wrapper">

                        <div className="wrapper-of-single-feature-container">
                            <div className="video-content-box-of-single-feature-container">
                                <div className="content-of-video-content-box-of-single-feature-container">

                                    <div className="video-box-of-single-feature-container">
                                        <div className="content-of-video-box-of-single-feature-container">
                                            <Video video={InRealTimeVideo} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="text-box-of-single-feature-container">
                                <div className="content-of-text-box-of-single-feature-container">
                                    <h3>Real-Time Updates</h3>
                                    <p>Update your information in real-time as often as you want with easy to use editor. No more reprinting business cards every time you need to update your information.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="single-feature-container mirrored-container">
                    <div className="content-of-single-feature-container content-section-wrapper">

                        <div className="wrapper-of-single-feature-container">
                            <div className="text-box-of-single-feature-container">
                                <div className="content-of-text-box-of-single-feature-container">
                                    <h3>Statistics</h3>
                                    <p>Track how many times your business cards have been viewed and from which countries.</p>
                                </div>
                            </div>

                            <div className="video-content-box-of-single-feature-container">
                                <div className="content-of-video-content-box-of-single-feature-container">

                                    <div className="video-box-of-single-feature-container">
                                        <div className="content-of-video-box-of-single-feature-container">
                                            <Video video={StatisticsVideo} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>                

            </div>
        </div>
    )
}
