import BaseApp from "../BaseApp";

export interface SingleArticleInterface {
    id: number,
    title: string,
    slug: string,
    body: string,
    section_id: number,
    section_name: string,
    section_slug: string,
    thumbnail: string,
    is_active: boolean,
    date_created: string
}

/**
 * Companies App
 */
export default class HelpArticleApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "articles"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "help-center/articles"

    /**
     * Required fields
     */
    requiredFields = [
        "title"
    ]

    /**
     * Section article by slug
     */
    getSingleArticleDetailsBySlug = (slug: string) => this.sendRequest({
        method: "GET",
        url: this.appBaseEndPointPath + "/details/" + slug,
        withCredentials: true
    })


}