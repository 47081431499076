import React, { useEffect } from 'react'
import Authentication from '../../Api/Authentication/Authentication'

export default function LogoutPage() {

    const authentication = new Authentication("", "")

    useEffect(() => {

        authentication.logoutUser()
            .then(response => {
                window.location.replace("/sign-in")
            })

    }, [])

    return(
        <div></div>
    )
}