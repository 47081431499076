import BaseApp from "../BaseApp";

/**
 * Company Interface 
 */
export interface CompanyInterface {
    id: number,
    title: string,
    description: string,
    address: string,
    email: string,
    phone: string,
    thumbnail: string,
    is_active: boolean,
    date_created: string
}

/**
 * Companies App
 */
export default class CompaniesApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "companies"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "companies"

    /**
     * Required fields
     */
    requiredFields = [
        "title",
        "email",
        "phone"
    ]

}