import ApiEndpoint from "./ApiEndpoint"
import { AxiosRequestConfig, AxiosDefaultDataObject } from "./Interfaces";

export default class MirajWarehouseApiEndpoint extends ApiEndpoint{
    
    /**
     * Create new instance
     */
    createNewInstance = async(data: AxiosDefaultDataObject) => {

        if (data.update){
            return this.updateInstance(data)
        }

        return this.sendRequest({
            method: "POST",
            data,
            url: this.appBaseEndPointPath,
            withCredentials: true
        })
    }

    /**
     * Update Instance
     */
    updateInstance = async(data: AxiosDefaultDataObject) => {
        const pluralize = require("pluralize")
        const itemId = data[pluralize.singular(this.appName) + "_id"]


        return this.sendRequest({
            method: "PUT",
            data,
            url: this.appBaseEndPointPath + "/" + itemId,
            withCredentials: true
        })
    }

}