import { AxiosRequestConfig } from "../../Api/Interfaces";
import { DataDisplayerSingleSearchParam } from "../../DataDisplayer/Interfaces";
import BaseApp from "../BaseApp";

export interface SingleHelpSectionInterface {
    id: number,
    title: string,
    slug: string,
    description: string,
    thumbnail: string,
    is_active: boolean,
    date_created: string
}

/**
 * Companies App
 */
export default class HelpSectionsApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "sections"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "help-center/sections"

    /**
     * Required fields
     */
    requiredFields = [
        "title"
    ]

    /**
     * Section details by slug
     */
    getSingleSectionDetailsBySlug = (slug: string) => this.sendRequest({
        method: "GET",
        url: this.appBaseEndPointPath + "/details/" + slug,
        withCredentials: true
    })

    /**
     * List of instances
     */
    listOfArticlesInSection = async(page: number, search: string | null, section_slug: string, disablePagination: boolean = false) => {

        let path: string = this.appBaseEndPointPath + "/details/" + section_slug + "/list"

        path += `?page=${page ? page : 1}`

        if (search !== null){
            path += "&search=" + search
        }

        if (disablePagination){
            path += "&disable_pagination=1"
        }

        const config: AxiosRequestConfig = {
            method: "GET",
            url: path,
            withCredentials: true
        }

        return this.sendRequest(config)
    }

}