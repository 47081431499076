import React, { useEffect, useState } from 'react'
import Authentication from './Authentication'
import { Alert, Button, Grid, Skeleton, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import GenerateIcon from '../../FormElements/GenerateIcon'

export default function SetUpTwoFactorAuth() {

    const [loading, setLoading] = useState<boolean>(true)
    const [loadingRequest, setLoadingRequest] = useState<boolean>(false)
    const [qrCode, setQrCode] = useState<string>("")

    interface AlertInterface {
        type: "info" | "error" | "success",
        message: string,
        display: boolean
    }

    const [alertDetails, setAlertDetails] = useState<AlertInterface>({
        type: "info",
        message: "",
        display: false
    })

    const [otp, setOtp] = useState<string>("")

    const auth = new Authentication("", "")

    /**
     * Register 
     */
    const register = () => {
        setLoading(true)

        auth.registerTwoFactorAuth()
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:

                        setQrCode(data.topt_qrcode)
                        
                        setAlertDetails({
                            type: "info",
                            message: "",
                            display: false
                        })

                        setLoading(false)

                        break

                }

            })
            .catch(error => {

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setLoading(false)
                setAlertDetails({
                    type: "error",
                    message: message,
                    display: true
                })
            })

    }

    /**
     * Handle submit
     */
    const handleSubmit = () => {
        setLoadingRequest(true)

        auth.verifyOTPCode(Number(otp))
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:

                        setAlertDetails({
                            type: "success",
                            message: "Two-Factor Authentication has been set!",
                            display: true
                        })

                        setLoadingRequest(false)

                        setTimeout(() => {
                            window.location.reload()
                        }, 1500)
                        break

                }

            })
            .catch(error => {

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setLoadingRequest(false)
                setAlertDetails({
                    type: "error",
                    message: message,
                    display: true
                })
            })
        
    }

    /**
     * Use Effect
     */
    useEffect(() => {

        register()

    }, [])

    return(
        <div className="sign-in-page-container">
            <div className="content-of-sign-in-page-container">

                <div className="login-form-container set-up-two-factor-auth-page" style={{ minWidth: 500}}>
                    <div className="content-of-login-form-container">

                        <div className="two-factor-auth-head-box">
                            <div className="content-of-two-factor-auth-head-box">

                                <Grid container spacing={2}>

                                    <Grid item xs={2}>
                                        <div className="icon-box-of-two-factor-auth-head-box">
                                            <div className="content-of-icon-box-of-two-factor-auth-head-box">
                                                <GenerateIcon icon="password" />
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={10}>
                                        <div className="text-box-of-two-factor-auth-head-box">
                                            <div className="content-of-text-box-of-two-factor-auth-head-box">
                                                <h3>Two-Factor Authentication</h3> 
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <p className="two-factor-auth-description">To enable Two-Factor Authentication, you need to scan the QR Code below with your Google Authenticator App, and enter the OTP verification code down below.</p>
                                    </Grid>

                                </Grid>

                            </div>
                        </div>

                        <div className="two-factor-auth-qr-code-container">
                            <div className="content-of-two-factor-auth-qr-code-container">
                                {loading ? <Skeleton variant="rectangular" width={150} height={150} /> : <img src={qrCode} />}
                            </div>
                        </div>

                        <div className="actual-form-of-login-form-container">
                            <div className="two-factor-auth-form">
                                <TextField onKeyUp={(e) => {
                                    if (e.key === "Enter"){
                                        if (String(otp).length === 6){
                                            handleSubmit()
                                        }
                                    }
                                }} id="outlined-basic" label="OTP" variant="outlined" fullWidth value={otp} type="number" onChange={(e) => setOtp(e.target.value)} />
                                {alertDetails.display && <Alert style={{ marginTop: 5 }} severity={alertDetails.type}>{alertDetails.message}</Alert>}
                                <LoadingButton disabled={loading ? true : String(otp).length === 6 ? false : true} loading={loadingRequest} variant="contained" fullWidth style={{ marginTop: 10 }} onClick={() => handleSubmit()}>Register</LoadingButton>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
      
    )
}