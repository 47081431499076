import axios from "axios";
import BaseApp from "../BaseApp";
import CardBuilderPage from "./CardBuilderPage"
import {decode as base64_decode, encode as base64_encode} from 'base-64'
import CardStatisticsPage from "./CardStatisticsPage"

/**
 * Single Card Interface
 */
export interface SingleCardInterface {
    id: number,
    title: string,
    first_name: string,
    last_name: string,
    middle_name: string | null,
    position: string | null,
    headline: string | null,
    user_id: number,
    company_id: number,
    design_id: number,
    is_public: boolean,
    is_active: boolean,
    unique_name: string,
    thumbnail: string | null,
    date_created: string,
    primary_color: string,
    company_name: string,
    background_color: string,
    company_logo: string,
    background_image: string,
    card_color: string,
    text_color: string,
    subtext_color: string,
    custom_css?: string
    hide_position?: boolean,
    hide_company_name?: boolean
}

/**
 * Single Card Link Interface
 */
export interface SingleCardLinkInterface {
    id: number,
    card_id: number,
    url: string,
    title: string,
    type: string,
    is_embeded_video: boolean,
    order_num: number,
    date_created: string
}

/**
 * Link Type Interface
 */
export interface LinkTypeInterface {
    title: string,
    icon: string,
    name: string,
    isSocial?: boolean
}

/**
 * Card Template Interface
 */
export interface CardTemplateInterface {
    id: number,
    title: string
}

/**
 * Companies App
 */
export default class CardsApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "cards"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "cards"

    /**
     * Required fields
     */
    requiredFields = [
        "title",
        "first_name",
        "last_name",
        "position"
    ]

    /**
     * Add New Page
     */
    addNewAdminContentPage = () => {
        return <CardBuilderPage />
    }

    /**
     * Edit page
     */
    editAdminContentPage = () => {
        return <CardBuilderPage update={true} />
    }

    /**
     * Get single instance details
     */
    getSingleCardDetails = (id: string, no_visit: boolean = false) => {

        let link: string = this.appBaseEndPointPath + "/" + id

        if (no_visit){
            link = link + "?no_visit=1"
        }

        return this.sendRequest({
            method: "GET",
            url: link,
            withCredentials: true
        })
    }

    /**
     * Get single card statistics
     */
    getSingleCardStatistics = (id: string, date_from: boolean | string = false, date_to: boolean | string = false) => {

        let link: string = this.appBaseEndPointPath + "/" + id + "/visits"

        if (date_from && date_to){
            link = link + `?date_from=${date_from}&date_to=${date_to}`
        }

        return this.sendRequest({
            method: "GET",
            url: link,
            withCredentials: true
        })
    }

    /**
     * Get list of links
     */
    getListOfLinks = (cardId: number) => this.sendRequest({
        method: "GET",
        url: this.appBaseEndPointPath + `/${cardId}/links/?disable_pagination=1`,
        withCredentials: true
    })

    /**
     * Delete link
     */
    deleteLink = (linkId: number) => this.sendRequest({
        method: "DELETE",
        url: this.appBaseEndPointPath + "/links/" + linkId,
        withCredentials: true
    })

    /**
     * Generate VCF file
     */
    generateVCFFIle = (cardUniqueName: string) => this.sendRequest({
        method: "GET",
        url: this.appBaseEndPointPath + "/" + cardUniqueName + "/vcf-card",
        withCredentials: false
    })

    /**
     * Link types
     */
    linkTypes: Array<LinkTypeInterface> = [
        {
            title: "Phone",
            name: "phone",
            icon: "phone"
        },
        {
            title: "Email",
            name: "email",
            icon: "email"
        },
        {
            title: "Google Maps",
            name: "googlemaps",
            icon: "googlemaps"
        },
        {
            title: "Address",
            name: "address",
            icon: "address"
        },
        {
            title: "Website",
            name: "website",
            icon: "website"
        },
        {
            title: "link",
            name: "link",
            icon: "link"
        },
        {
            title: "WhatsApp",
            name: "whatsapp",
            icon: "whatsapp"
        },
        {
            title: "Vimeo",
            name: "vimeo",
            icon: "vimeo"
        },
        {
            title: "YouTube",
            name: "youtube",
            icon: "youtube"
        },
        // {
        //     title: "Viber",
        //     name: "viber",
        //     icon: "viber"
        // },
        {
            title: "Facebook",
            name: "facebook",
            icon: "facebook",
            isSocial: true
        },
        {
            title: "Telegram",
            name: "telegram",
            icon: "telegram",
            isSocial: true
        },
        {
            title: "Skype",
            name: "skype",
            icon: "skype",
            isSocial: true
        },
        {
            title: "Instagram",
            name: "instagram",
            icon: "instagram",
            isSocial: true
        },
        {
            title: "Twitter",
            name: "twitter",
            icon: "twitter",
            isSocial: true
        },
        {
            title: "TikTok",
            name: "tiktok",
            icon: "tiktok",
            isSocial: true
        },
        {
            title: "Snapchat",
            name: "snapchat",
            icon: "snapchat",
            isSocial: true
        },
        {
            title: "LinkedIn",
            name: "linkedin",
            icon: "linkedin",
            isSocial: true
        },
        {
            title: "Iframe",
            name: "iframe",
            icon: "iframe",
            isSocial: false
        },
        {
            title: "Title",
            name: "title",
            icon: "title",
            isSocial: false
        },
        {
            title: "Text",
            name: "text",
            icon: "comment",
            isSocial: false
        },
        {
            title: "Slider",
            name: "slider",
            icon: "images",
            isSocial: false
        },
        {
            title: "Image",
            name: "image",
            icon: "image",
            isSocial: false
        },
        {
            title: "Editor",
            name: "editor",
            icon: "edit",
            isSocial: false
        },
        {
            title: "PDF",
            name: "pdf",
            icon: "pdf",
            isSocial: false
        },
    ]
    
    /**
     * Find link details by name
     */
    findLinkDetailsByName = (name: string) => {

        let object: LinkTypeInterface = {
            title: "",
            icon: "",
            name: ""
        }

        if (this.linkTypes.length > 0){
            this.linkTypes.map(link => {
                if (link.name === name){
                    object = link
                }
            })
        }

        return object

    }

    /**
     * Return Full name
     */
    returnFullName = (firstName: string, lastName: string, middleName: string | null) => {
        let fullName: string = firstName + " " + lastName

        if (middleName){
            fullName = firstName + " " + middleName + " " + lastName
        }

        return fullName
    }

    /**
     * Templates
     */
    templates: Array<CardTemplateInterface> = [
        {
            id: 1,
            title: "Template 01"
        },
        {
            id: 2,
            title: "Template 02"
        }
    ]

    /**
     * Format link
     */
    formatLink = (link: string, isSocial?: boolean, isLink?: boolean) => {
        let newLink: string = link.toLowerCase()
        let replace: Array<string> = ["http://", "https://", "www."]

        if (isSocial){
            replace.push("instagram.com/", "facebook.com/", "snapchat.com/", "snapchat.com/add", "linkedin.com/in/", "linkedin.com/", "vimeo.com", "vimeo.com/", "www.tiktok.com", "www.tiktok.com/", "tiktok.com")
        }

        replace.map(toReplace => {
            newLink = newLink.replace(toReplace, "")
        })

        if (isLink){
            return link
        }else{
            return newLink
        }
    }

    /**
     * Check link
     */
    checkLink = (link: string, type: string, person_name: string = "") => {
        let newLink: string = link 


        switch(type){

            case "phone":
                newLink = "tel:" + link
                break

            case "email":
                newLink = "mailto:" + link
                break

            case "instagram":
                newLink = "https://instagram.com/" + this.formatLink(link, true)
                break

            case "facebook":
                newLink = "https://facebook.com/" + this.formatLink(link, true)
                break

            case "snapchat":
                newLink = "https://snapchat.com/add/" + this.formatLink(link, true)
                break

            case "linkedin":
                newLink = "https://linkedin.com/in/" + this.formatLink(link, true)
                break

            case "whatsapp":
                newLink = "https://api.whatsapp.com/send?phone=" + this.formatLink(link.replace("+", "").replace(/\s+/g, ''), true) + "&text=" + `Hello ${person_name}`.replace(" ", "%20")
                break

            case "telegram":
                newLink = "https://t.me/" + this.formatLink(link.replace(/\s+/g, ''), true)
                break

            case "twitter":
                newLink = "https://twitter.com/" + this.formatLink(link, true)
                break

            case "tiktok":
                newLink = "https://www.tiktok.com/@" + this.formatLink(link, true)
                break

            case "website":
                //newLink = "//" + this.formatLink(link, true)
                newLink = "http://" + this.formatLink(link, true)
                break

            case "skype":
                newLink = `skype:${this.formatLink(link, true)}?chat`
                break

        }

        return newLink
    }

    /**
     * Return vimeo embeded video
     */
    returnVimeoEmbededVideoLink = (link: string) => {
        let newLink: string = this.formatLink(link, true) 
        let splittedLink: Array<string> = newLink.split("/")
        splittedLink = splittedLink.filter(Boolean)

        newLink = `https://player.vimeo.com/video/${splittedLink[0] ? splittedLink[0] : ""}?h=${splittedLink[1] ? splittedLink[1] : ""}`

        return newLink
    }

    /**
     * 
     */
    returnVimeoEmbededVideo = (link: string) => {
        return (
            <iframe src={this.returnVimeoEmbededVideoLink(link)} allow="autoplay; fullscreen; picture-in-picture"></iframe>
        )
    }

    /**
     * Replace Iframe link
     */
    findIframeLink = (iframe: string) => {
        let result = iframe.match(/src\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)/)

        if (result){
            if (result[1]){
                return result[1]
            }
        }

        return ""

    }

    /**
     * Encode editor 
     */
    encodeEditor = (value: string) => base64_encode(value)

    /**
     * Decode editor 
     */
    decodeEditor = (value: string) => value

    /**
     * vcf-card
     */
    downloadVCFCard = async(uniqueName: string) => axios({
        baseURL: this.baseApiUrl,
        url: this.appBaseEndPointPath + "/" + uniqueName + "/vcf-card",
        method: "GET",
        data: {},
        withCredentials: false,
        responseType: "blob"
    })
    
    /**
     * Statistics page
     */
    viewAdminContentPage = () => {
        return <CardStatisticsPage />
    }

    /**
     * Download qr code
     */
    downloadQrCode = async(uniqueName: string) => axios({
        baseURL: this.baseApiUrl,
        url: this.appBaseEndPointPath + "/" + uniqueName + "/generate-qr-code",
        method: "GET",
        data: {},
        withCredentials: true,
        responseType: "blob"
    })

}