import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ChipsApp, { NFCChipInterface } from './ChipsApp'
import { CircularProgress } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateErrorPageDetails } from '../../Pages/Error/errorPageSlice'
import NotFoundPage from '../../Pages/NotFound/NotFoundPage'
import SingleCardPage from '../cards/SingleCardPage'
import NotFoundMessage from '../../Pages/NotFound/NotFoundMessage'

export default function ViewChipPage() {

    const dispatch = useDispatch()

    const params = useParams()
    const uniqueName = params.unique_name ? params.unique_name : ""

    const chipsApp = new ChipsApp()

    const [loading, setLoading] = useState<boolean>(true)
    const [notFound, setNotFound] = useState<boolean>(false)
    const [chipDetails, setChipDetails] = useState<NFCChipInterface>({
        id: 0,
        title: "",
        unique_name: "",
        type: "card",
        chip_type: "",
        redirect_url: "",
        card_id: 0,
        company_id: 0,
        user_id: 0,
        is_registered: false,
        is_public: true,
        is_active: true,
        date_created: "",
        folder_id: 0,
        company_name: "",
        user_name: ""
    }) 
    const [cardUniqueName, setCardUniqueName] = useState<string>("")

    /**
     * Load chip details
     */
    const loadChipDetails = () => {
        setLoading(true)

        chipsApp.getSingleChipDetails(uniqueName)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:

                        if (data.type === "link"){
                            window.location.replace(data.redirect_url)
                        }

                        if (data.type === "card"){
                            setCardUniqueName(data.card_unique_name ? data.card_unique_name : "")
                        }

                        if (!data.is_registered){
                            window.location.replace("/panel/chips/new?unique_name=" + data.unique_name)
                        }

                        setLoading(false)
                        setChipDetails(data)
                        break

                }

            })
            .catch(error => {
                chipsApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                if (status === 404){
                    setNotFound(true)
                }else{
                    dispatch(updateErrorPageDetails({
                        show: true,
                        title: "Error",
                        message: message,
                        status: status,
                    }))
                }

                setLoading(false)

            })

    }

    /**
     * Preloader
     */
    let preloaderClassName: string = "preloader-of-view-single-chip-page-container"

    if (loading){
        preloaderClassName = preloaderClassName + " active"
    }

    useEffect(() => {
        loadChipDetails()
    }, [])

    if (notFound){
        return <NotFoundMessage title="Nothing was found..." message="This page doesn't exist" />
    }

    return(
        <div className="view-single-chip-page-container">
            <div className="content-of-view-single-chip-page-container">

                <div className={preloaderClassName}>
                    <div className="content-of-preloader-of-view-single-chip-page-container">
                        <CircularProgress />
                    </div>
                </div>

                {chipDetails.type === "card" && cardUniqueName && <SingleCardPage customUniqueName={cardUniqueName} />}

            </div>
        </div>
    )
}