import React, { useEffect, useState } from 'react'
import BaseStructuredPage from "../components/BaseStructuredPage"
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import GenerateIcon from '../../FormElements/GenerateIcon';
import HelpSectionsApp, { SingleHelpSectionInterface } from "../../apps/help_center/HelpSectionsApp"
import SingleSectionBox from "./SingleSectionBox"

export default function HelpPage() {

    const [search, setSearch] = useState<string>("")

    const [loading, setLoading] = useState<boolean>(true)
    const [sections, setSections] = useState<Array<SingleHelpSectionInterface>>([])

    const helpSectionsApp = new HelpSectionsApp()

    /**
     * Load sections
     */
    const loadSections = () => {
        setLoading(true)
        helpSectionsApp.listOfInstances(1, "", true, [])
            .then(response => {
                setSections(response.data)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadSections()
    }, [])

    return(
        <BaseStructuredPage>
            <div className="intro-section-home-page">
                <div className="content-of-intro-section-home-page content-section-wrapper">

                    <div className="text-box-of-intro-section-home-page" style={{ width: "100%" }}>
                            <div className="content-of-text-box-of-intro-section-home-page" style={{ width: "100%" }}>

                                <h1>
                                    Help Center
                                </h1>
{/* 
                                <div className="search-field-container">
                                    <div className="content-of-search-field-container">
                                        <TextField
                                            id="outlined-adornment-amount"
                                            label="Search"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><GenerateIcon icon="search" /></InputAdornment>,
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                </div> */}

                            </div>
                        </div>

                </div>
            </div>
            
            <div className="wrapper-of-help-sections-boxes-container content-section-wrapper">
                {sections.length > 0 && sections.map((section: SingleHelpSectionInterface, index: number) => <SingleSectionBox section={section} key={index} />)}
            </div>
        </BaseStructuredPage>
    )
}