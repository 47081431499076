import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { store } from "../../app/store"
import { PagePreloader } from "./Interfaces"

const initialState: PagePreloader = {
    loading: false
} 

const pagePreloaderSlice = createSlice({
    name: "pagePreloaderSlice",
    initialState,
    reducers: {
        updateLoadingStatus: (state: PagePreloader, action: PayloadAction<{loading: boolean}>) => {
            state.loading = action.payload.loading
        }
    }
})

export const retunrPageLoadingStatus = () => {
    return store.getState().pagePreloader.loading
}

export const { updateLoadingStatus } = pagePreloaderSlice.actions
export default pagePreloaderSlice.reducer