import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import User from '../../../Api/User/User'
import { returnCurrentUser } from '../../../Api/User/userSlice'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementDisabledStatus, updateElementLoadingStatus } from '../../../FormElements/formsDataSlice'
import { addNewForm } from '../../../FormElements/formsSlice'
import FormValidator from '../../../FormElements/FormValidator'
import GenerateForm from '../../../FormElements/GenerateForm'

export default function ProfileDetailsSection() {

    const dispatch = useDispatch()

    const currentUser = returnCurrentUser()

    const formDetails = {
        name: "profile_form_details",
        elemPrefix: "profile_form_details_"
    }

    const elemPrefix = formDetails.elemPrefix

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value

    /**
     * Handle submit
     */
    const handleSubmit = () => {
        updateButtonLoadingStatus(true)
        // setTimeout(() => updateButtonLoadingStatus(false), 1000)

        const email: string = getSingleFormElementValue("email")
        const first_name: string = getSingleFormElementValue("first_name")
        const last_name: string = getSingleFormElementValue("last_name")
        const middle_name: string = getSingleFormElementValue("middle_name")
        const phone: string = getSingleFormElementValue("phone")
        const thumbnail: string = getSingleFormElementValue("thumbnail")

        const requiredFiels: Array<string> = [
            elemPrefix + "first_name",
            elemPrefix + "last_name",
            elemPrefix + "email"
        ]

        /**
         * Form Validator
         */
        const formValidator = new FormValidator(requiredFiels)

        if (formValidator.isValid().valid){

            const userApp = new User()
            userApp.updateCurrentUserDetails(first_name, last_name, email, thumbnail, middle_name, phone)
                .then(response => {
                        
                    const { status, statusText, data } = response

                    switch(status){

                        case 200:
                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: data.message! ? data.message : statusText,
                                    severity: "success"
                                })
                            )
                            break

                    }

                    updateButtonLoadingStatus(false)
                    updateButtonDisabledStatus(true)

                })
                .catch(error => {
                    updateButtonLoadingStatus(false)
                    
                    const response = error.response
                    console.log(response)
                    
                    switch(response.status){

                        default:
                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                                    severity: "error"
                                })
                            )
                    }
                
                })

        }else{
            updateButtonLoadingStatus(false)
            formValidator.showRequiredFields(dispatch)
        }

    }

    /**
     * Use effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
         dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 4,
                        id: elemPrefix + "first_name",
                        label: "First Name",
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 4,
                        id: elemPrefix + "middle_name",
                        label: "Middle Name",
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 4,
                        id: elemPrefix + "last_name",
                        label: "Last Name",
                        fullWidth: true
                    },
                    // {
                    //     type: "TextField",
                    //     fieldType: "text",
                    //     xs: 6,
                    //     id: elemPrefix + "email",
                    //     label: "Email",
                    //     fullWidth: true
                    // },
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "phone",
                        label: "Phone",
                        fullWidth: true
                    },
                    {
                        type: "ImageUploader",
                        xs: 12,
                        id: elemPrefix + "thumbnail",
                        label: "Thumbnail",
                        description: "Here you can update your profile image"
                     },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: "Update",
                        fullWidth: false,
                        onClick: () => {},
                        isSubmit: true
                    }
                ]
            })
        )

        /**
         * Add new form elements data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "email",
                    value: currentUser.email
                },
                {
                    name: elemPrefix + "first_name",
                    value: currentUser.first_name
                },
                {
                    name: elemPrefix + "last_name",
                    value: currentUser.last_name
                },
                {
                    name: elemPrefix + "thumbnail",
                    value: currentUser.thumbnail
                },
                {
                    name: elemPrefix + "phone",
                    value: currentUser.phone
                },
                {
                    name: elemPrefix + "middle_name",
                    value: currentUser.middle_name
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
                {
                    name: elemPrefix + "submit",
                    value: ""
                }
            ])
        )

    }, [])

    return(
        <div className="user-details-form-container">
            <div className="content-of-user-details-form-container">
                <GenerateForm name={formDetails.name} handleSubmitForm={handleSubmit} />
            </div>
        </div>
    )
}