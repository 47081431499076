import React, { useEffect, useState } from 'react'
import { returnSingleCardDetails, returnSingleCardLoadingStatus, updateCard } from './cardSlice'
import { retunrPageLoadingStatus, updateLoadingStatus } from "../../PagePreloader/pagePreloaderSlice"
import { useDispatch } from 'react-redux'
import CardsApp, { SingleCardInterface } from './CardsApp'
import { useParams } from 'react-router-dom'
import SingleCard from "./SingleCard"
import { Button } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'
import { Helmet } from "react-helmet";
import NotFoundMessage from '../../Pages/NotFound/NotFoundMessage'
import { LoadingButton } from '@mui/lab'

// import "../../../assets/css/nghc.css"

/**
 * NGHC Styles
 */
export const NGHCStyles =   <style type="text/css">{`
    body {
        font-family: "Brown Light", !important;
    }
    .card-template-02-user-info-box h3 {
        font-family: "Brown Bold";
    }
    .text-box-of-single-card-link-box h3 {
        font-family: 'Brown Bold';
    }
    .card-template-02-user-info-box h4, .card-template-02-user-info-box p {
        font-family: 'Brown Light';
    }
    .editor-for-single-card-link-box h1 {
        font-family: 'Brown Bold';
    }
    .editor-for-single-card-link-box {
        font-family: 'Brown Light';
    }
`}</style>

interface Props {
    customUniqueName?: string
    customId?: number
}

export default function SingleCardPage(props: Props) {

    const cardsApp = new CardsApp()
    const dispatch = useDispatch()

    const pageLoadingStatus: boolean = retunrPageLoadingStatus()

    const [showNotFoundPage, setShowNotFoundPage] = useState<boolean>(false)

    const [cardDetails, setCardDetails] = useState<SingleCardInterface>({
        id: 0,
        title: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        position: "",
        headline: "",
        user_id: 0,
        company_id: 0,
        design_id: 1,
        is_public: true,
        is_active: true,
        unique_name: "",
        thumbnail: "",
        date_created: "",
        primary_color: "#e5e5e5",
        company_name: "No name",
        company_logo: "",
        background_color: "#f2f2f2",
        background_image: "",
        card_color: "#FFFFFF",
        text_color: "#000000",
        subtext_color: "#919191"
    })

    const params = useParams()

    /**
     * Download card loading
     */
    const [downloadCardLoading, setDownloadCardLoading] = useState<boolean>(false)

    /**
     * Update page loading status
     */
    const updatePageLoadingStatus = (status: boolean) => dispatch(updateLoadingStatus({ loading: status }))

    /**
     * Get single card details
     */
    const getSingleCardDetails = () => {
        updatePageLoadingStatus(true)

        let uniqueName = props.customUniqueName ? props.customUniqueName : params.unique_name

        if (uniqueName){
            cardsApp.getSingleCardDetails(uniqueName)
                .then(response => {

                    const { status, data, statusText } = response

                    switch(status){

                        case 200:
                            updatePageLoadingStatus(false)

                            const cardDetails: SingleCardInterface = data
                            dispatch(updateCard(cardDetails))
                            setCardDetails(cardDetails)
                            break

                    }

                })
                .catch(error => {
                    const { status, statusText, data } = error.response 
                    const message = data.message ? data.message : statusText

                    switch(status){

                        case 404:
                            setShowNotFoundPage(true)
                            break

                    }

                    updatePageLoadingStatus(false)



                })
        }
    }

    /**
     * Effect
     */
    useEffect(() => {

        getSingleCardDetails()

    }, [])

    /**
     * Downlaod card
     */
    const downloadCard = () => {
        setDownloadCardLoading(true)
        cardsApp.downloadVCFCard(cardDetails.unique_name)
            .then(response => {
                const href = URL.createObjectURL(response.data)
            
                const link = document.createElement('a')
                link.href = href
                link.setAttribute('download', `${cardDetails.first_name} ${cardDetails.last_name}.vcf`)
                document.body.appendChild(link)
                link.click()
            
                document.body.removeChild(link)
                URL.revokeObjectURL(href)
                setDownloadCardLoading(false)
            })
    }

    const addStyles = <style type="text/css">{`
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${cardDetails.primary_color};
        transition: all 0.35s ease-in-out;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${cardDetails.primary_color};
    }
    `}</style>

    const pageFavicon = cardDetails.thumbnail ? cardDetails.thumbnail : cardDetails.company_logo

    /**
     * Show not found page
     */
    if (showNotFoundPage){
        return <NotFoundMessage title="Nothing was found..." message="This card doesn't exist" />
    }

    return(
        <>
            <Helmet>
                <title>{cardDetails.first_name} {cardDetails.last_name}</title>
                <meta property="og:title" content={cardDetails.first_name + " "  + cardDetails.last_name} />
                {pageFavicon && <link rel="icon" href={pageFavicon} />}
                {pageFavicon && <meta property="og:image" content={pageFavicon} />}
                {cardDetails.company_name === "Neom Green Hydrogen Company" && NGHCStyles}
                {addStyles}
                {cardDetails.custom_css && <style type="text/css">{`${cardDetails.custom_css}`}</style>}
            </Helmet>
            <div className="single-business-card-page-container" style={{ backgroundColor: cardDetails.background_color, backgroundImage: `url(${cardDetails.background_image})` }}>
                <div className="content-of-single-business-card-page-container">
                    {!pageLoadingStatus && <SingleCard {...cardDetails} loading={cardDetails.first_name ? false : true} />}
                    {!pageLoadingStatus && cardDetails.unique_name && (
                        <div className="add-to-contacts-card-button">
                            <div className="content-of-add-to-contacts-card-button">
                                <LoadingButton loading={downloadCardLoading} fullWidth startIcon={<GenerateIcon icon="contact-box" />} variant="outlined" style={{ backgroundColor: cardDetails.primary_color }} onClick={() => downloadCard()}>
                                    Save Contact
                                </LoadingButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}