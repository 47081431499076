import React, { useEffect } from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom"
import { useReturnPages } from './pagesSlice'
import { Page } from './Interfaces'
import BasePage from '../Pages/BasePage'

export default function Router() {

    const pages = useReturnPages()

    // useEffect(() => {
    //     /**
    //      * Redirect to https
    //      */
    //     const location = window.location
    //     const protocol = location.protocol

    //     if (protocol === "http:"){
    //         location.replace(location.href.replace("http:", "https:"))
    //     }

    // }, [])

    /**
     * Render routes
     */
    const renderRoutes = () => pages.map((page: Page, index: number) => <Route path={page.path} key={index} element={<BasePage page={page} component={page.component} />} />)

    return(
        <BrowserRouter>
            <Routes>
                {renderRoutes()}
            </Routes>
        </BrowserRouter>
    )
}