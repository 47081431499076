import React, { useEffect } from 'react'
import { SlideInterface } from './SlidersApp'
import { FormElementData } from '../../FormElements/Interfaces'
import { useDispatch } from 'react-redux'
import FormElementImageUploader from '../../FormElements/Elements/FormElementImageUploader'
import { updateSlide, removeSlide } from './slidersSlice'
import { createOrUpdateElementData, useReturnSingleFormElementData, returnSingleFormElementData } from '../../FormElements/formsDataSlice'
import { Grid, IconButton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'

interface Props extends SlideInterface {
    sliderId: number
}

export default function SingleEditableSlide(props: Props) {

    const { id, img, sliderId, order_num } = props

    const slideId = "single-slide-" + id
    const data: FormElementData = {
        name: slideId,
        value: img
    }

    const dispatch = useDispatch()

    /**
     * Update form element data
     */
    const updateFormElementData = () => dispatch(createOrUpdateElementData({
        name: slideId,
        value: img
    }))

    /**
     * Handle update image
     */
    const handleUpdateImage = (name: string, value: any) => {

        let slide: SlideInterface = {
            id,
            img: value,
            order_num
        }

        dispatch(updateSlide({
            sliderId,
            slide
        }))

        updateFormElementData()
    }

    /**
     * Delete slide
     */
    const handleDeleteSlide = () => dispatch(removeSlide({ sliderId: sliderId, slideId: id }))

    /**
     * Use effect
     */
    useEffect(() => {

        updateFormElementData()

    }, [])

    return(
        <div className="single-editable-slide-box" id={slideId}>
            <div className="content-of-single-editable-slide-box">
                
                <Grid container spacing={2}>

                    <Grid item xs={1}>
                        <div className="buttons-box-of-single-editable-slide-box">
                            <GenerateIcon icon="drag" />
                        </div>
                    </Grid>

                    <Grid item xs={10}>
                        <FormElementImageUploader data={data} handleOnChange={handleUpdateImage} id={slideId} label="Slide Image" />
                    </Grid>

                    <Grid item xs={1}>
                        <div className="buttons-box-of-single-editable-slide-box">
                            <IconButton color="error" onClick={() => handleDeleteSlide()}>
                                <GenerateIcon icon="delete" />
                            </IconButton>
                        </div>
                    </Grid>

                </Grid>

            </div>
        </div>
    )
}