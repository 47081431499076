import React from 'react'
import { ISingleCardProps } from "../SingleCard"
import CardsApp from '../CardsApp'
import { Skeleton } from '@mui/material'
import NoThumb from "../../../../assets/svg/no-user.svg"
import CardLinks from '../CardLinks'
export default function CardTemplate01(props: ISingleCardProps) {

    const { id, title, first_name, last_name, middle_name, position, headline, user_id, company_id, design_id, is_public, is_active, unique_name, thumbnail, date_created, primary_color, company_name, loading, company_logo, text_color, card_color, subtext_color, hide_company_name, hide_position } = props
    const cardsApp = new CardsApp()

    const fullName: string = cardsApp.returnFullName(first_name, last_name, middle_name)

    return(
        <div className="single-business-card-container" style={{ backgroundColor: card_color }}>
            <div className="content-of-single-business-card-container">

                <div className="header-box-of-single-business-card-container" style={{ background: primary_color, height: company_logo ? 355 : 300 }}>
                    <div className="content-of-header-box-of-single-business-card-container">

                        <div className="card-profile-details-box">
                            <div className="content-of-card-profile-details-box">
                                {company_logo && <img src={company_logo} className="single-card-company-logo-img" />}
                                {loading ? <><Skeleton variant="text" width={100} height={30} /><Skeleton variant="text" width={150} height={30} /></> : <h2>{fullName}</h2>}
                                {!hide_position && <h3>{loading ? <Skeleton variant="text" width={75} /> : position}</h3>}
                                {!hide_company_name && <p style={{ paddingTop: hide_position ? 25 : 0 }}>{loading ? <Skeleton variant="text" width={45} /> : company_name}</p>}
                            </div>
                        </div>

                    </div>
                </div>

                <div className="img-thumbnail-box-of-header-box-of-single-business-card-container" style={{ top: company_logo ? 200 : 140 }}>
                    <div className="content-of-img-thumbnail-box-of-header-box-of-single-business-card-container">
                        {loading ? <Skeleton variant="circular" width={150} height={150} /> : <div className="img-thumb-div-template01" style={{ backgroundImage: `url(${thumbnail ? thumbnail : NoThumb})`, width: "100%", height: "100%", backgroundPosition: "center", backgroundSize: "cover" }}></div>}
                    </div>
                </div>

                {headline && (
                    <div className="headline-box-of-single-business-card-container">
                        <div className="content-of-headline-box-of-single-business-card-container">
                            {loading ? (
                                <div>
                                    <Skeleton variant="text" width="100%" />
                                    <Skeleton variant="text" width="80%" />
                                    <Skeleton variant="text" width="60%" />
                                </div>
                            ): <p style={{ color: text_color }}>{headline}</p>}
                        </div>
                    </div>
                )}

                {<CardLinks cardId={id} customLinks={props.customLinks} showCustomLinks={props.showCustomLinks} cardLoading={loading} primaryColor={primary_color} personName={first_name} textColor={text_color} subTitleColor={subtext_color} />}

            </div>
        </div>
    )
}