import React, { useEffect, useState } from 'react'
import CardsApp, { SingleCardLinkInterface } from './CardsApp'
import { CircularProgress, FormControlLabel, Grid, IconButton, InputAdornment, Switch, TextField } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'
import { useDispatch } from 'react-redux'
import { deleteLinkById, updateLink, updateLinksOrder } from './cardBuilderSlice'
import FormElementDynamicSelect from '../../FormElements/Elements/FormElementDynamicSelect'
import SlidersApp, { SliderInterface } from '../sliders/SlidersApp'
import { createOrUpdateElementData } from '../../FormElements/formsDataSlice'
import { FormElementData, FormSelectSingleItem } from '../../FormElements/Interfaces'
import FormElementSelect from '../../FormElements/Elements/FormElementSelect'
import LoadingFieldElement from '../../FormElements/Elements/LoadingFieldElement'
import FormElementImageUploader from '../../FormElements/Elements/FormElementImageUploader'
import FormElementPDFUploader from "../../FormElements/Elements/FormElementPDFUploader"
import TextEditor from "../../FormElements/Elements/TextEditor"

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// type DroppableProps = {
//     'data-rbd-droppable-context-id': string,
//     'data-rbd-droppable-id': string,
// }

interface Props extends SingleCardLinkInterface{
    update?: boolean,
    itemBeingMovedId?: number | boolean,
    index?: number
    // ref: () => void,
    // droppableProps: DroppableProps,
}

export default function SingleBuilderLink(props: Props) {

    const { id, type, url, title, is_embeded_video, order_num, date_created, card_id, itemBeingMovedId, index } = props
    const dispatch = useDispatch()
    const cardsApp = new CardsApp()

    const [isItemBeingMoved, setIsItemBeingMoved] = useState<boolean>(false)

    // useEffect(() => {
       
    //     if (index){
    //         if (itemBeingMovedId === index){
    //             setIsItemBeingMoved(true)
    //         }else{
    //             setIsItemBeingMoved(false)
    //         }
    //     }
    // }, [itemBeingMovedId])

    useEffect(() => {
        console.log("props: ", props)
    }, [props])

    const currentLinkType = cardsApp.findLinkDetailsByName(type)

    const [loadingDeleteLink, setLoadingDeleteLink] = useState<boolean>(false)
    const [deleteMessage, setDeleteMessage] = useState<string>("")

    let showEmbededVideo: boolean = false
    const [helperTextMessage, setHelperTextMessage] = useState<string>("")

    const [editorValue, setEditorValue] = useState<string>(url)

    /**
     * Update show emebeded video status
     */
    if (type === "vimeo"){
        showEmbededVideo = true
    }

    /**
     * Update form element data
     */
    const imgFieldName: string = "img-" + id
    const updateFormElementData = () => dispatch(createOrUpdateElementData({
        name: imgFieldName,
        value: url
    }))

    const thumbData: FormElementData = {
        name: imgFieldName,
        value: url
    }

    /**
     * Handle update image
     */
    const handleUpdateImage = (name: string, value: any) => {
        let object: SingleCardLinkInterface = {
            id,
            type,
            url: String(value),
            title,
            is_embeded_video,
            order_num,
            date_created,
            card_id
        } 

        dispatch(updateLink(object))
        updateFormElementData()
    }

    /**
     * Delete Link by id
     */
    const deleteLink = (id: number) => {

        const handleDeleteLink = () => dispatch(deleteLinkById(id))

        if (!props.update){
            dispatch(deleteLinkById(id))
        }else{
            setLoadingDeleteLink(true)

            cardsApp.deleteLink(id)
                .then(response => {
    
                    const { status, statusText, data } = response
            
                    switch(status){
    
                        case 200:
                            setLoadingDeleteLink(false)
                            handleDeleteLink()
                            break
    
                    }
    
                })
                .catch(error => {
                    cardsApp.logResults(error)
    
                    const { status, statusText, data } = error.response 
                    const message = data.message ? data.message : statusText

                    switch(status){

                        case 404:
                            handleDeleteLink()
                            break

                    }
    
                    setDeleteMessage(message)
                    setLoadingDeleteLink(false)
                })
        }
    }

    /**
     * use effect
     */
    useEffect(() => {

        if (type === "googlemaps"){
            setHelperTextMessage("Paste iframe from Google Maps share window")
        }

        if (type === "slider"){
            loadSliders()
        }

    }, [type])

    let hideTitleTextField: boolean = false

    if (type === "title"){
        hideTitleTextField = true
    }

    if (type === "editor"){
        hideTitleTextField = true
    }

    /**
     * Update editor
     */
    const updateEditorData = (value: string) => {
        let object: SingleCardLinkInterface = {
            id,
            type,
            url: value,
            title,
            is_embeded_video,
            order_num,
            date_created,
            card_id
        } 

        dispatch(updateLink(object))
    }

    useEffect(() => {
        updateEditorData(editorValue)
    }, [editorValue])

    /**
     * Render text field
     */
    const renderTextField = () => {

        switch(type){

            case "slider":

                let sliderData: FormElementData = {
                    name: "slider-data-id-" + id,
                    value: url
                }

                if (slidersListLoading){
                    return <LoadingFieldElement label="Sliders" />
                }

                return(
                    <div>
                        <FormElementSelect id={sliderData.name} data={sliderData} label="Slider" handleOnChange={(name, value) => {
                            let object: SingleCardLinkInterface = {
                                id,
                                type,
                                url: String(value),
                                title,
                                is_embeded_video,
                                order_num,
                                date_created,
                                card_id
                            } 
    
                            dispatch(updateLink(object))
                        }} items={slidersList} fullWidth />
                    </div>
                )
                
            case "image":
                return(
                    <div className="card-link-builder-image-uploader-container">
                        <div className="content-of-card-link-builder-image-uploader-container">
                            <FormElementImageUploader data={thumbData} handleOnChange={handleUpdateImage} id={imgFieldName} label="Image" />
                        </div>
                    </div>
            )

            case "pdf":
                return(
                    <div className="card-link-builder-image-uploader-container">
                        <div className="content-of-card-link-builder-image-uploader-container">
                            <FormElementPDFUploader data={thumbData} handleOnChange={handleUpdateImage} id={imgFieldName} label="Upload PDF" />
                        </div>
                    </div>
            )

            case "editor":
                return(
                    <div className="card-link-builder-editor-box">
                        <div className="content-of-card-link-builder-editor-box">
                            <TextEditor value={editorValue} onChange={setEditorValue} placeholder="" />
                        </div>
                    </div>
                )

                // const modules = {
                //     toolbar: [
                //       [{ 'header': [1, 2, false] }],
                //       ['bold', 'italic', 'underline','strike', 'blockquote'],
                //       [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                //       ['link', 'image'],
                //       ['clean']
                //     ],
                //     // toolbar : {
                //     //     contaier: [

                //     //     ],
                //     //     handlers: {
                //     //         image: imageHandler,
                //     //     }
                //     // }
                // }
                
                // const formats = [
                //     'header',
                //     'bold', 'italic', 'underline', 'strike', 'blockquote',
                //     'list', 'bullet', 'indent',
                //     'link', 'image'
                // ]


                // return (
                //     <div className="card-link-builder-editor-box">
                //         <div className="content-of-card-link-builder-editor-box">
                //             <ReactQuill theme="snow" value={editorValue} onChange={(val) => setEditorValue(val)} modules={modules} formats={formats} />
                //         </div>
                //     </div>
                // )

            default:
                return(
                    <TextField 
                    label={currentLinkType.title} 
                    variant="outlined" 
                    fullWidth 
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><GenerateIcon icon={currentLinkType.icon} /></InputAdornment>,
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const { value } = event.target

                        let url = value

                        if (type === "googlemaps" || type === "iframe"){
                            url = cardsApp.findIframeLink(url)
                        }

                        let object: SingleCardLinkInterface = {
                            id,
                            type,
                            url,
                            title,
                            is_embeded_video,
                            order_num,
                            date_created,
                            card_id
                        } 

                        dispatch(updateLink(object))
                    }}
                    value={url}
                />
                )
        }

    }

    /**
     * Load sliders
     */

    const [slidersList, setSlidersList] = useState<Array<FormSelectSingleItem>>([])
    const [slidersListLoading, setSliderListLoading] = useState<boolean>(true)

    const slidersApp = new SlidersApp()

    const loadSliders = () => {
        setSliderListLoading(true)

        slidersApp.listOfInstances(1, "", true)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:

                        if (data){
                            let items: Array<FormSelectSingleItem> = []

                            if (data.length > 0){
                                data.map((item: SliderInterface) => {
                                    items.push({
                                        id: item.id,
                                        title: item.title,
                                        subItems: []
                                    })
                                })
                            }

                            setSlidersList(items)

                        }

                        break

                }

                setSliderListLoading(false)

            })
            .catch(error => {
                slidersApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                alert(message)
                setSliderListLoading(false)
            })

    }

    if (isItemBeingMoved){
        return(
            <div className="single-builder-link-box">
                <div className="content-of-single-builder-link-box">
                    <Grid container spacing={2}>

                        <Grid item xs={1}>
                            <div className="drag-icon-box-of-single-builder-link-box">
                                <div className="content-of-drag-icon-box-of-single-builder-link-box">
                                <GenerateIcon icon="drag" />
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={10}>
                            <div className="form-box-of-single-builder-link-box">
                                <div className="content-of-form-box-of-single-builder-link-box">
                                    <h3>Item Being Moved</h3>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={1}>
                            <div className="drag-icon-box-of-single-builder-link-box">
                                <div className="content-of-drag-icon-box-of-single-builder-link-box">
                                    
                                </div>
                            </div>
                        </Grid>

                    </Grid>
                </div>
            </div>
        )
    }

    return(
        <div className="single-builder-link-box">
            <div className="content-of-single-builder-link-box">

                <div className={`loading-box-of-single-builder-link-box ${loadingDeleteLink ? "active" : ""}`}>
                    <div className="content-of-loading-box-of-single-builder-link-box">
                        <CircularProgress />
                    </div>
                </div>

                <Grid container spacing={2}>

                    <Grid item xs={1}>
                        <div className="drag-icon-box-of-single-builder-link-box">
                            <div className="content-of-drag-icon-box-of-single-builder-link-box">
                                {/* <IconButton onClick={() => handleUpdateLinksOrder(id, true, order_num)}>
                                    <GenerateIcon icon="up" />
                                </IconButton>

                                <IconButton onClick={() => handleUpdateLinksOrder(id, false, order_num)}>
                                    <GenerateIcon icon="down" />
                                </IconButton> */}
                               <GenerateIcon icon="drag" />
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={10}>
                        <div className="form-box-of-single-builder-link-box">
                            <div className="content-of-form-box-of-single-builder-link-box">
                               {renderTextField()}
                               {!hideTitleTextField && <TextField 
                                    label="Display Title"
                                    variant="outlined" 
                                    fullWidth 
                                    style={{ marginTop: 10 }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const { value } = event.target
                                        let object: SingleCardLinkInterface = {
                                            id,
                                            type,
                                            url,
                                            title: value,
                                            is_embeded_video,
                                            order_num,
                                            date_created,
                                            card_id
                                        } 

                                        dispatch(updateLink(object))
                                    }}
                                    value={title}
                                />}
                                {showEmbededVideo &&  <FormControlLabel control={
                                    <Switch checked={is_embeded_video ? true : false} inputProps={{ 'aria-label': 'controlled' }}   onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const { checked } = event.target
                                        let object: SingleCardLinkInterface = {
                                            id,
                                            type,
                                            url,
                                            title,
                                            is_embeded_video: checked,
                                            order_num,
                                            date_created,
                                            card_id
                                        } 

                                        dispatch(updateLink(object))
                                    }}/>
                                } label="Embed Video" />}
                                {deleteMessage && <p style={{ color: "red" }}>{deleteMessage}</p>}
                                {helperTextMessage && <p style={{ color: "#595959" }}>{helperTextMessage}</p>}
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={1}>
                        <div className="drag-icon-box-of-single-builder-link-box">
                            <div className="content-of-drag-icon-box-of-single-builder-link-box">
                                <IconButton onClick={() => deleteLink(id)}>
                                    <GenerateIcon icon="delete" />
                                </IconButton>
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </div>
        </div>
    )
}