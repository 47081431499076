import React, { useEffect, useState } from 'react'
import GenerateIcon from '../../../FormElements/GenerateIcon'
import { Alert, Button, Grid, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { QrCode } from '@mui/icons-material'

interface Props {
    alertType: "info" | "error" | "success",
    alertMessage: string,
    submitButtonLoading: boolean,
    handleSubmit: (opt: number) => void,
    display: boolean
}

export default function TwoFactorAuthLogin(props: Props) {

    const { alertType, alertMessage, submitButtonLoading, handleSubmit, display } = props
    const [opt, setOpt] = useState<string>()

    const [disableButton, setDisableButton] = useState<boolean>(false)

    useEffect(() => {

        if (String(opt).length === 6){
            handleSubmit(Number(opt))
        }

    }, [opt])

    // useEffect(() => {
    //     if (String(opt).length < 6){
    //         setDisableButton(true)
    //     }

    //     if (alertType === "success"){
    //         setDisableButton(true)
    //     }
    
    //     if (alertType === "error"){
    //         setDisableButton(true)
    //     }
    // }, [])

    return(
        <div className="login-form-container" style={{ minWidth: 400, display: display ? "block" : "none" }}>
            <div className="content-of-login-form-container">

                <div className="two-factor-auth-head-box">
                    <div className="content-of-two-factor-auth-head-box">

                        <Grid container spacing={2}>

                            <Grid item xs={2}>
                                <div className="icon-box-of-two-factor-auth-head-box">
                                    <div className="content-of-icon-box-of-two-factor-auth-head-box">
                                        <GenerateIcon icon="password" />
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={10}>
                                <div className="text-box-of-two-factor-auth-head-box">
                                    <div className="content-of-text-box-of-two-factor-auth-head-box">
                                        <h3>Two-Factor Authentication</h3> 
                                        <p>Enter 6-digit code from Google Authenticator</p>
                                    </div>
                                </div>
                            </Grid>

                        </Grid>

                    </div>
                </div>  

                <div className="actual-form-of-login-form-container">
                    <div className="two-factor-auth-form">
                        <TextField id="outlined-basic" label="OTP" variant="outlined" fullWidth value={opt} type="number" onChange={(e) => setOpt(e.target.value)} />
                        {alertMessage && <Alert style={{ marginTop: 5 }} severity={alertType}>{alertMessage}</Alert>}
                        <LoadingButton disabled={alertType === "success" ? true : String(opt).length === 6 ? false : true} loading={submitButtonLoading} variant="contained" fullWidth style={{ marginTop: 10 }} onClick={() => handleSubmit(Number(opt))}>Login</LoadingButton>
                    </div>
                </div>

            </div>
        </div>
    )
}