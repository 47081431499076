import { Action } from "@reduxjs/toolkit"
import { Dispatch } from "react"
import { store } from "../../app/store"
import { returnSingleFormElementData, updateHelperText } from "./formsDataSlice"
import { FormElementData, FormElementsData } from "./Interfaces"

/**
 * Form Validator
 */
export default class FormValidator{
    requiredFields: Array<string>

    constructor(requiredFields: Array<string>){
        this.requiredFields = requiredFields
    }

    /**
     * Return forms elements data
     */
    returnFormsElementsData = () => {
        return store.getState().formsData
    }

    /**
     * Is form valida
     */
    isValid = () => {
        const requiredFields = this.requiredFields
        const data = this.returnFormsElementsData()

        interface Response{
            valid: boolean,
            required_fields: Array<string>
        }

        let response: Response = {
            valid: true,
            required_fields: []
        }

        let fieldsRequiredCount = 0

        if (requiredFields){
            if (data){
                requiredFields.map(field => {
                    const elem = returnSingleFormElementData(field)
                    if (elem.name !== ""){
                        if (elem.value === ""){
                            fieldsRequiredCount++
                            response.required_fields.push(field)
                        }else{
                            response.required_fields.filter(item => item === field)
                        }
                    }
                })

            }else{
                response = {
                    valid: false,
                    required_fields: requiredFields
                }
            }
        }

        if(fieldsRequiredCount > 0){
            response.valid = false
        }else{
            response.valid = true
        }

        return response
    }

    /**
     * Show required fields
     */
    showRequiredFields = (dispatch: Dispatch<Action>) => {
        const requiredFiels = this.isValid().required_fields

        requiredFiels.map(field => {
            dispatch(
                updateHelperText({
                    name: field,
                    displayHelperText: true,
                    helperText: "This field is required!",
                    helperTextType: "error"
                })
            )
        })

    }

}