import React, { useEffect, useState } from 'react'
import HelpSectionsApp from '../../apps/help_center/HelpSectionsApp';
import { SingleArticleInterface } from '../../apps/help_center/HelpArticleApp';
import { DataDisplayerPagination } from '../../DataDisplayer/Interfaces';
import { Skeleton } from '@mui/material';
import GenerateMessageBox from '../../FormElements/GenerateMessageBox';

interface Props {
    section_slug: string,
    search?: string
}

export function SingleArticleBox(props: SingleArticleInterface){

    const { title, slug, section_name, thumbnail, section_slug } = props

    return(
        <div className="article-single-item-box">
            <div className="content-of-article-single-item-box">

                <div className="text-box-of-article-single-item-box">
                    <div className="content-of-text-box-of-article-single-item-box">
                        <h4>Title</h4>
                        <h3>
                            <a href={`/help/articles/${slug}`}>
                                {title}
                            </a>
                        </h3>
                    </div>
                </div>

                <div className="text-box-of-article-single-item-box">
                    <div className="content-of-text-box-of-article-single-item-box">
                        <h4>Section</h4>
                        <h3>
                            <a href={`/help/sections/${section_slug}`}>
                                {section_name}
                            </a>
                        </h3>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default function Articles(props: Props) {

    const { section_slug, search } = props

    const [loading, setLoading] = useState<boolean>(true)
    const [articles, setArticles] = useState<Array<SingleArticleInterface>>([])

    const [pagination, setPagination] = useState<DataDisplayerPagination>({
        count: 0,
        current_page: 0,
        total_pages: 0
    })

    const helpSectionsApp = new HelpSectionsApp()

    /**
     * Load articles
     */
    const loadArticles = (page: number = 1) => {
        setLoading(true)
        helpSectionsApp.listOfArticlesInSection(page, search ? search : "", section_slug, false)
            .then(response => {

                const { data } = response

                if (data){

                    if (data.results){
                        setArticles(data.results)
                    }

                    setPagination({
                        count: data.count,
                        current_page: page,
                        total_pages: data.total_pages
                    })
                }

                setLoading(false)

            })
    }

    useEffect(() => {
        loadArticles()
    }, [])

    useEffect(() => {
        loadArticles()
    }, [search])

    /**
     * Render loading elements
     */
    const renderLoadingElements = () => {
        return(
            <>
                <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 15 }} />
                <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 15 }} />
                <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 15 }} />
                <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 15 }} />
                <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 15 }} />
            </>
        )
    }

    return(
        <div className="articles-container">
            <div className="content-of-articles-container content-section-wrapper">
                {loading ? renderLoadingElements() : articles.length > 0 ? articles.map((article: SingleArticleInterface, index: number) => <SingleArticleBox {...article} key={index} />) : <GenerateMessageBox type="info" title="Nothing..." message="No articles have been found" />}
            </div>
        </div>
    )
}