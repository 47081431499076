import React, { useEffect } from 'react'
import { PageComponentProps } from '../../Router/Interfaces'
import BaseStructuredPage from '../components/BaseStructuredPage'
import IntroSection from "./IntroSection"
import ItemsAfterIntro from "./ItemsAfterIntro"
import WhatsIsNFCCard from "./WhatsIsNFCCard"
import Features from "./Features"
import MoreFeatures from "./MoreFeatures"
import FaqSection from "./FaqSection"

export default function HomePage(props: PageComponentProps) {

    // useEffect(() => {
    //     window.location.replace("/panel/digitalcards")
    // }, [])
    
    return (
        <BaseStructuredPage>
            
            <IntroSection />
            <ItemsAfterIntro />
            <WhatsIsNFCCard />
            <Features />
            <MoreFeatures />
            <FaqSection />

        </BaseStructuredPage>
    )
}