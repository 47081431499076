import React, { useState, useEffect } from 'react'
import FoldersApp from './FoldersApp'
import { useDispatch } from 'react-redux'
import { FolderInterface } from './ChipsApp'
import { addFolders, updateFolderLoadingStatus, useReturnFolders, useReturnFoldersLoading } from './foldersSlice'
import GenerateMessageBox, { MessageBoxTypes } from '../../FormElements/GenerateMessageBox'

import { Button, IconButton, Skeleton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'
import ChipsByFolder from "./ChipsByFolder"
import { DataDisplayerPagination } from '../../DataDisplayer/Interfaces'
import { LoadingButton } from '@mui/lab'
import NewFolderModal from "./NewFolderModal"
import SingleFolder from "./SingleFolder"

export default function FoldersList() {

    const dispatch = useDispatch()
    const foldersApp = new FoldersApp()

    const folders = useReturnFolders()
    const loading = useReturnFoldersLoading()

    const [pagination, setPagination] = useState<DataDisplayerPagination>({
        count: 1,
        current_page: 1,
        total_pages: 1
    })

    const [newFolderModalOpenStatus, setNewFolderModalOpenStatus] = useState<boolean>(false)

    /**
     * Close new folder modal
     */
    const closeNewFolderModal = () => setNewFolderModalOpenStatus(false)

    /**
     * Open new folder modal
     */
    const openNewFolderModal = () => setNewFolderModalOpenStatus(true)

    /**
     * Submit Request Response Text
     */
    interface ISubmitRequestResponse {
        type: MessageBoxTypes,
        title: string,
        message: string,
        display: boolean
    }
    const [submitRequestResponse, setSubmitRequestResponse] = useState<ISubmitRequestResponse>({
        type: "info",
        title: "No title",
        message: "No message",
        display: false
    })

    /**
     * Update folders 
     */
    const updateFolders = (folders: Array<FolderInterface>) => dispatch(addFolders(folders))

    /**
     * Update Folders loading
     */
    const updateFoldersLoading = (status: boolean) => dispatch(updateFolderLoadingStatus(status))

    /**
     * Load Folders
     */
    const loadFolders = (page = 1) => {
        updateFoldersLoading(true)

        foldersApp.listOfInstances(page, "", false)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:

                        let foldersArray: Array<FolderInterface> = [
                            ...folders
                        ]
                        
                        if (data.results && data.results.length > 0){
                            data.results.map((folder: FolderInterface) => {
                                foldersArray.push(folder)
                            })
                        }

                        updateFolders(foldersArray)
                        updateFoldersLoading(false)

                        setPagination({
                            count: data.count ? data.count : 0,
                            current_page: page,
                            total_pages: data.total_pages ? data.total_pages : 0
                        })
                        break

                }

            })
            .catch(error => {
                foldersApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setSubmitRequestResponse({
                    display: true,
                    type: "error",
                    message: message,
                    title: "Error"
                })
                updateFoldersLoading(false)
            })
    }

    /**
     * Use effect
     */
    useEffect(() => {
        loadFolders()
    }, [])

    /**
     * Load more
     */
    const renderLoadMore = () => {
        if (pagination.current_page !== pagination.total_pages){
            return(
                <div className="load-more-item-container">
                    <div className="content-of-load-more-item-container">
                        <LoadingButton loading={loading} variant="contained" startIcon={<GenerateIcon icon="refresh" />} onClick={() => loadFolders(pagination.current_page + 1)}>
                            Load more
                        </LoadingButton>
                    </div>
                </div>
            )
        }
    }

    /**
     * Render folders
     */
    const renderFolders = () => {

        if (loading){
            return(
                <div>
                    <Skeleton variant="rectangular" height={50} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" height={50} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" height={50} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" height={50} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" height={50} style={{ marginBottom: 10 }} />
                </div>
            )
        }

        if (folders.length > 0){
            return folders.map((folder: FolderInterface, index: number) => <SingleFolder {...folder} key={index} />)
        }else{
            return <GenerateMessageBox type="info" title="Nothing..." message="You haven't created any folder yet" />
        }

    }

    return(
        <div className="folders-list-container">
            <div className="content-of-folders-list-container">
                <ChipsByFolder folderId={0} disanbleNothingFoundMessage={true} />
                <NewFolderModal open={newFolderModalOpenStatus} handleClose={closeNewFolderModal} />

                <h3>Folders <IconButton onClick={openNewFolderModal}><GenerateIcon icon="add" /></IconButton></h3>
                {renderFolders()}
                {renderLoadMore()}
            </div>
        </div>
    )
}