import React from 'react'
import User from '../../../Api/User/User'
import { DataDisplayerProps, DataDisplayerSingleSearchParam } from '../../../DataDisplayer/Interfaces'
import UsersApp from '../../../apps/users/UsersApp'
import DataDisplayer from '../../../DataDisplayer/DataDisplayer'
import DateAndTime from '../../../DateAndTime/DateAndTime'

export default function ProfileLoginSessions() {

    const user = new User()
    const userApp = new UsersApp()

    const dateAndTime = new DateAndTime()

    const loadItems = (page: number, search: string|null, disablePagination: boolean, searchParams?: Array<DataDisplayerSingleSearchParam> ) => user.getCurrentUserLoginSessionsList(page, search, disablePagination, searchParams)

    const dataDisplayerProps: DataDisplayerProps = {
        sectionName: "users",
        defaultMessages:  {
            notFound: "No sessions have been found",
            sureToDelete: "Are you sure you want to delete this session",
            addNewText: "Add new labour"
         },
        appClass: userApp,
        enableSearch: false,
        loadItems: loadItems,
        customDataDisplayerFields: [
            {
                type: "text",
                name: "ip_address",
                title: "IP Address:",
                maxWidth: 200,
                minWidth: 200,
            },
            {
                type: "text",
                name: "location",
                title: "Location:",
                maxWidth: 200,
                minWidth: 200,
            },
            {
                type: "text",
                name: "user_agent",
                title: "User Agent:",
                // maxWidth: 200,
                // minWidth: 200,
            },
            {
                type: "text",
                name: "date_created",
                title: "Login Time:",
                // maxWidth: 200,
                // minWidth: 200,
                customContent(data) {
                    return <h3>{data.date_created ? dateAndTime.formatDateAndTime(data.date_created) : "-"}</h3>
                },
            },
        ],
        customDataDisplayerButtons: [
            {
                type: "custom",
                label: "Edit",
                icon: "",
                customContent(details) {
                    return
                },
            }
        ]
        // customDataDisplayerButtons: [
        //     {
        //         type: "custom",
        //         label: "Edit",
        //         icon: "",
        //         customContent(details) {
        //             return <IconButton href={`/panel/labour/edit/${details.id}?back=/panel/teams/edit/${teamId}`}><GenerateIcon icon="edit" /></IconButton>
        //         },
        //     },
        //     // {
        //     //     type: "custom",
        //     //     label: "Delete",
        //     //     icon: "",
        //     //     customContent(details) {
        //     //         return <DeleteLabourButton id={details.id} handleDelete={deleteLabour} />
        //     //     },
        //     // }
        // ]
    }
    
    return <DataDisplayer {...dataDisplayerProps} />
}