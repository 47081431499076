import { Alert } from '@mui/material'
import React from 'react'
import { FormAlertElement, FormElementData } from '../Interfaces'

interface Props extends FormAlertElement{
    data: FormElementData,
}

export default function FormElementAlert(props: Props) {
    const data = props.data
    return <Alert severity={data.severity ? data.severity : props.severity ? props.severity : "info"}>{props.label ? props.label : data.value ? data.value : ""}</Alert>
}
