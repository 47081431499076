import React from 'react'

import TapIcon from "../../../assets/svg/tap.svg"
import Sustainability from "../../../assets/svg/sustainability.svg"
import Sustainability2 from "../../../assets/svg/sustainability-2.svg"
import PoweredByPeople from "../../../assets/svg/powered-by-people.svg"

export interface ItemInLineInterface {
    icon: string,
    title?: string,
    sub_title: string
}

const itemsInLineList: Array<ItemInLineInterface> = [
    {
        icon: TapIcon,
        title: "Easy one tap solution and paperless networking",
        sub_title: ""
    },
    {
        icon: Sustainability,
        title: "Keep sustainability at the core of your business",
        sub_title: ""
    },
    {
        icon: PoweredByPeople,
        title: "Cutting edge technology, powered by people",
        sub_title: ""
    },
    {
        icon: Sustainability2,
        title: "Underpinning solution towards a greener future",
        sub_title: ""
    }
]

export function SingleItemInLine(props: ItemInLineInterface){

    const { icon, title, sub_title } = props

    return(
        <div className="single-item-in-line-box">
            <div className="content-of-single-item-in-line-box">
                <img src={icon} />
                {title && <h3>{title}</h3>}
                {sub_title && <h4>{sub_title}</h4>}
            </div>
        </div>
    )
}

export default function ItemsAfterIntro() {
    return(
        <div className="items-after-intro-section">
            <div className="content-of-items-after-intro-section content-section-wrapper">

                <div className="items-in-line-container">
                    {itemsInLineList.map((item: ItemInLineInterface, index: number) => <SingleItemInLine {...item} key={index} />)}
                </div>

            </div>
        </div>
    )
}