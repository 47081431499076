import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementLoadingStatus, useReturnSingleFormElementData, updateElementDisabledStatus } from '../../FormElements/formsDataSlice'
import { addNewForm } from '../../FormElements/formsSlice'
import GenerateForm from '../../FormElements/GenerateForm'
import AppLogo from "../../../assets/svg/hitap-logo.svg"
import Api from '../../Api/Api'
import FormValidator from '../../FormElements/FormValidator'
import { useParams } from 'react-router-dom'
import Authentication from '../../Api/Authentication/Authentication'

export default function BlockAccountPage() {

    const dispatch = useDispatch()
    const params = useParams()

    const formDetails = {
        name: "block_account_form",
        elemPrefix: "block_account_form_elem_"
    }

    const elemPrefix = formDetails.elemPrefix

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value
    
    /**
     * Submit form
     */
    const HandleSubmitForm = () => {
        updateButtonLoadingStatus(true)
        // setTimeout(() => updateButtonLoadingStatus(false), 1000)

        const email: string = getSingleFormElementValue("email")
        const otp: string = getSingleFormElementValue("otp")
        const password: string = getSingleFormElementValue("password")

        /**
         * Form Validator
         */
        let requiredFields: Array<string> = [elemPrefix + "email", elemPrefix + "password", elemPrefix + "otp"]


        const formValidator = new FormValidator(requiredFields)


        if (formValidator.isValid().valid){

            const auth = new Authentication("", "")

            /**
             * Send request to API
             */
            auth.blockUserAccount(email, password, otp)
                .then(response => {
                    
                    const { status, statusText, data } = response

                    switch(status){

                        case 200:
                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: data.message! ? data.message : statusText,
                                    severity: "success"
                                })
                            )
                            setTimeout(() => window.location.replace("/sign-in"), 1500)
                            break

                    }

                    updateButtonLoadingStatus(false)
                    updateButtonDisabledStatus(true)

                })
                .catch(error => {
                    updateButtonLoadingStatus(false)
                    
                    const response = error.response
                    Api.log(response)
                    
                    switch(response.status){

                        default:
                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                                    severity: "error"
                                })
                            )
                    }
                
                })
        }else{
            updateButtonLoadingStatus(false)
            formValidator.showRequiredFields(dispatch)
        }



    }

    /**
     * Use Effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
        dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "email",
                        label: "Email",
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "password",
                        xs: 12,
                        id: elemPrefix + "password",
                        label: "Password",
                        fullWidth: true
                    },
                    {
                        type: "TwoFactorAuth",
                        fieldType: "number",
                        xs: 12,
                        id: elemPrefix + "otp",
                        label: "Enter 6-digit code from Google Authenticator to block your account",
                        submitFormOnPressingEnter: true,
                        fullWidth: true,
                    },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: "Block Account",
                        fullWidth: true,
                        onClick: () => {},
                        isSubmit: true
                    },
                ]
            })
        )

        /**
         * Add new form elements data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "email",
                    value: ""
                },
                {
                    name: elemPrefix + "password",
                    value: ""
                },
                {
                    name: elemPrefix + "otp",
                    value: "",
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
                {
                    name: elemPrefix + "submit",
                    value: ""
                }
            ])
        )
    }, [])

    return(
        <div className="sign-in-page-container">
            <div className="content-of-sign-in-page-container">

                <div className="login-form-container reset-password-form">
                    <div className="content-of-login-form-container">

                        <div className="app-logo-of-login-form-container">
                            <div className="content-of-app-logo-of-login-form-container">
                                <img src={AppLogo} />
                            </div>
                        </div>

                        <div className="title-box-of-login-form-container">
                            <div className="content-of-title-box-of-login-form-container">
                                <h3>Block Account</h3>
                            </div>
                        </div>

                        <div className="actual-form-of-login-form-container">
                            <GenerateForm name={formDetails.name} handleSubmitForm={HandleSubmitForm} />
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}