import React, { useEffect, useState } from 'react'
import SlidersApp, { SliderInterface } from "./SlidersApp"
import SingleSlider from './SingleSlider'
import { Skeleton } from '@mui/material'

interface Props {
    sliderId: number
}

export default function LoadSingleSlider(props: Props) {

    const slidersApp = new SlidersApp()

    const { sliderId } = props

    const [loading, setLoading] = useState<boolean>(true)
    const [slider, setSlider] = useState<SliderInterface>({
        id: 0,
        title: "",
        slides: [],
        display_title: false,
        user_id: 0,
        date_created: ""
    })

    const [errorMessage, setErrorMessage] = useState<string>("")

    /**
     * Load slider
     */
    const loadSlider = () => {
        setLoading(true)

        slidersApp.getSingleInstanceDetails(sliderId)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:
                        const slider: SliderInterface = data 
                        setSlider(slider)
                        break

                }

                setLoading(false)

            })
            .catch(error => {
                slidersApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setErrorMessage(message)
                setLoading(false)
            })

    }

    /**
     * Effect
     */
    useEffect(() => {

        loadSlider()

    }, [sliderId])

    if (loading){
        return(
            <div className="loading-slider-box">
                <div className="content-of-loading-slider-box">
                    <Skeleton variant="rectangular" width={"100%"} height={210} />
                </div>
            </div>
        )
    }

    return <SingleSlider {...slider} />
}