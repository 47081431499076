import React, { useEffect, useState, KeyboardEvent, Dispatch } from 'react'
import GenerateMessageBox from '../FormElements/GenerateMessageBox'
import { DataDisplayerItem, DataDisplayerItemButton, DataDisplayerItemField, DataDisplayerPagination, DataDisplayerProps, DataDisplayerSingleSearchParam, SingleDataDisplayerStateItem } from './Interfaces'
import DataSingleDisplayerItem from "./DataSingleDisplayerItem"
import { useDispatch } from 'react-redux'
import { addNewDataDisplayerItems, useReturnSingleDataDisplayerSection, updateDataDisplayerSection, updateSearch, returnSingleDataDisplayerSection } from './dataDisplayerItemsSlice'
import { BaseAppInterface } from '../apps/BaseApp'
import { FormElementModalSingleButton } from '../FormElements/Interfaces'
import GenerateModal from '../FormElements/GenerateModal'
import AdminPanel from '../AdminPanel/AdminPanel'
import { AdminPanelSection, DataDisplayerSectionField } from '../AdminPanel/Interfaces'
import { Pagination, TextField } from '@mui/material'
import FormElementTextField from '../FormElements/Elements/FormElementTextField'
import { addManyElementsData, returnSingleFormElementData } from "../FormElements/formsDataSlice"
import { addNewForm } from '../FormElements/formsSlice'
import GenerateForm from '../FormElements/GenerateForm'
import { AppDispatch } from '../../app/store'
import { AnyAction } from '@reduxjs/toolkit'

export const returnDataDisplayerItemsState = (sectionName: string, items: Array<DataDisplayerItem>, loading: boolean, pagination: DataDisplayerPagination, search: string | null) => {
    const state: SingleDataDisplayerStateItem = {
        sectionName,
        items,
        loading,
        pagination,
        search
    }

    return state
}


/**
 * Update items
*/
export const updateDataDisplayerItems = (dispatch: AppDispatch ,currentSection: AdminPanelSection, sectionName: string, page: number, search: string | null, disablePagination: boolean, data: any, customDataDisplayerButtons?: Array<DataDisplayerItemButton>, customDataDisplayerFields?: Array<DataDisplayerSectionField>) => {

        interface SingleItemsDetails {
            [key: string | number]: any
        }

        let items: Array<DataDisplayerItem> = []
        let loadedItems: Array<SingleItemsDetails> = []

        if (disablePagination){
            loadedItems = data
        }else{
            loadedItems = data.results ? data.results : []
        }

        if (loadedItems && loadedItems.length > 0){

            loadedItems.map((item: SingleItemsDetails) => {

                const renderItemObject = (item: SingleItemsDetails) => {
                    let fields: Array<DataDisplayerItemField> = []

                    let dataDisplayerFields: Array<DataDisplayerSectionField> = []

                    if (currentSection.dataDisplayerFields && currentSection.dataDisplayerFields.length > 0){
                        dataDisplayerFields = currentSection.dataDisplayerFields
                    }

                    if (customDataDisplayerFields){
                        dataDisplayerFields = customDataDisplayerFields
                    }

                    if (dataDisplayerFields && dataDisplayerFields.length > 0){
                        dataDisplayerFields.map((field: DataDisplayerSectionField) => {
                            if (item[field.name] !== undefined){
                                fields.push({
                                    type: field.type,
                                    label: field.title,
                                    value: item[field.name],
                                    maxWidth: field.maxWidth,
                                    minWidth: field.minWidth,
                                    customContent: field.customContent
                                })
                            }
                        })
                    }

                    let subItems: Array<DataDisplayerItem> = []
                    let itemButtons: Array<DataDisplayerItemButton> = [
                        {
                            type: "edit",
                            label: "Edit",
                            icon: ""
                        },
                        {
                            type: "delete",
                            label: "Delete",
                            icon: ""
                        }
                    ]

                    if (currentSection.customDataDisplayerButtons){
                        if (currentSection.customDataDisplayerButtons.length > 0){
                            itemButtons = currentSection.customDataDisplayerButtons
                        }
                    }

                    if (customDataDisplayerButtons){
                        itemButtons = customDataDisplayerButtons
                    }

                    if (item.sub_items) {
                        if (item.sub_items.length > 0){
                            item.sub_items.map((singleSubItem: SingleItemsDetails) => subItems.push(renderItemObject(singleSubItem)))
                        }
                    }
 
                    let object: DataDisplayerItem = {
                        id: item.id ? item.id: 0,
                        fields,
                        subItems,
                        buttons: itemButtons,
                        details: item
                    }

                    return object
                }

               

                items.push(renderItemObject(item))

            })
        }

        console.log("items", items)

        dispatch(updateDataDisplayerSection({
            sectionName: sectionName,
            items,
            loading: false,
            pagination: {
                total_pages: data.total_pages ? data.total_pages : 1,
                count: data.count ? data.count : items.length,
                current_page: page
            },
            search: search
        }))

    }

export default function DataDisplayer(props: DataDisplayerProps) {

    const dispatch = useDispatch()
    const searchParams = props.searchParams

    /**
     * use effect
     */
    useEffect(() => {

        /**
         * Search params
         */
        if (searchParams){
            if (searchParams.length > 0){
                dispatch(addManyElementsData(searchParams))
            }
        }

        /**
         * Search form
         */
        if (props.customSearchForm){
            dispatch(addNewForm(props.customSearchForm))
        }

        /**
        * Register section
        */
        dispatch(addNewDataDisplayerItems(returnDataDisplayerItemsState(sectionName, [], true, {
            current_page: 1,
            total_pages: 1,
            count: 1
        }, null)))

        /**
         * Load items
         */
        loadItems(1, "", false)
    
    }, [])

    /**
     * Refresh
     */
    useEffect(() => {
        if (props.refreshData){
            loadItems(1, "", false)
            if (props.setRefreshData){
                props.setRefreshData(false)
            }
        }
    }, [props.refreshData])


    // if (!props.appClass){
    //     return <GenerateMessageBox type="error" title="Fatal Error" message="appClass - was not provided for the Data Displayer. Check the section config" />
    // }

    const appClass = props.appClass
    const { sectionName, defaultMessages } = props
    const adminPanel = new AdminPanel()

    const currentSection = adminPanel.returnSingleAdminSectionDetails(sectionName)

    const [ modalDetails, setModalDetails ] = useState({
        open: false,
        title: "No title",
        message: "No message",
    })

    /**
     * Update modal details
     */
    const updateModalDetails = (open: boolean, title: string, message: string, buttons?: Array<FormElementModalSingleButton>) => setModalDetails({
        open,
        title,
        message,
    })

    /**
     * Load items
     */
    const loadItems = (page: number, search: string | null, disablePagination: boolean) => {

        dispatch(updateDataDisplayerSection({
            sectionName: dataDisplayerSection.sectionName,
            items: dataDisplayerSection.items,
            loading: true,
            pagination: dataDisplayerSection.pagination,
            search: dataDisplayerSection.search,
        }))

        let searchParamsArray: Array<DataDisplayerSingleSearchParam> = []

        if (searchParams && searchParams.length > 0){
            searchParams.map((singleSearchParam: DataDisplayerSingleSearchParam) => {
                const singleFormElementData = returnSingleFormElementData(singleSearchParam.name)
                searchParamsArray.push({
                    name: singleFormElementData.name,
                    value: singleFormElementData.value === "none" ? "" : singleFormElementData.value
                })
            })
        }

        let loadItemsFunc = appClass.listOfInstances(page, search, disablePagination, searchParamsArray)

        if (props.loadItems){
            loadItemsFunc = props.loadItems(page, search, disablePagination, searchParamsArray)
        }

        if (props.customLoadItemsFunction){
            return props.customLoadItemsFunction(page, search, disablePagination, searchParamsArray, props.customDataDisplayerButtons, props.customDataDisplayerFields)
        }else{
            loadItemsFunc
                .then(response => {
                    appClass.logResults(response)

                    const { status, statusText, data } = response

                    switch(status){

                        case 200:
                            updateDataDisplayerItems(dispatch, currentSection, sectionName, page, search, disablePagination, data, props.customDataDisplayerButtons, props.customDataDisplayerFields)
                            break;

                    }

                })
                .catch(error => {
                    appClass.logResults(error)
                })
        }

    }
    

    let dataDisplayerSection = useReturnSingleDataDisplayerSection(sectionName)
    
    if (dataDisplayerSection === undefined){
        dataDisplayerSection = {
            sectionName: "noname",
            items: [],
            loading: true,
            pagination: {
                current_page: 1,
                total_pages: 1,
                count: 1
            },
            search: null
        }
    }

    let items: Array<DataDisplayerItem> = dataDisplayerSection.items
    const loading = dataDisplayerSection.loading

    /**
     * Render no items found
     */
    const renderNoItemsFound = () => {
        return(
            <div className="data-displayer-nothing-found-container">
                <div className="content-of-data-displayer-nothing-found-container">
                    <GenerateMessageBox type="info" title="Nothing Found..." message={defaultMessages.notFound} />
                </div>
            </div>
        )
    }

    /**
     * Render items
     */
    const renderItems = () => {

        const outputItems = (items: Array<DataDisplayerItem>) => items.map((item: DataDisplayerItem, index: number) => <DataSingleDisplayerItem {...item} key={index} sectionName={sectionName} appClass={appClass} />)
        let itemsArray = items

        if (loading){

            const loadingItems: Array<DataDisplayerItem> = []

            for(let i = 0; i < 5;i++){
                loadingItems.push({
                    id: i,
                    fields: [
                        {
                            type: "thumbnail",
                            label: "",
                            value: ""
                        },
                        {
                            type: "text",
                            label: "",
                            value: ""
                        },
                        {
                            type: "text",
                            label: "",
                            value: ""
                        }
                    ],
                    buttons: [
                        {
                            type: "edit",
                            label: "Edit",
                            icon: ""
                        },
                        {
                            type: "delete",
                            label: "Delete",
                            icon: ""
                        }
                    ],
                    loading: true
                })
            }

            return outputItems(loadingItems)
        }else{
            if (items && items.length > 0){
                return outputItems(itemsArray)
            }else{
                return renderNoItemsFound()
            }
        }
    }

    /**
     * Render pagination
     */
    const renderPagination = () => {

        const { pagination } = dataDisplayerSection

        const handleOnChange = (event: React.ChangeEvent<unknown>, value: number) => {
            loadItems(value, "", false)
        }

        return(
            <div className="data-displayer-pagination-container">
                <div className="content-of-data-displayer-pagination-container">
                    <Pagination count={pagination.total_pages} page={pagination.current_page} onChange={handleOnChange} shape="rounded" />
                </div>
            </div>
        )
    }

    /**
     * Render search
     */
    const renderSearch = () => {

        const { search } = dataDisplayerSection

        const keyPress = (e: KeyboardEvent<HTMLInputElement>) => {
            if(e.key == "Enter"){
                loadItems(1, search, false)
            }
         }

        return(
            <div className="data-displayer-search-container">
                <div className="content-of-data-displayer-search-container">

                    <div className="search-textfield-box-of-data-displayer-search-container">
                        <div className="content-of-search-textfield-box-of-data-displayer-search-container">
                            <TextField 
                                variant="outlined"
                                label="Search"
                                fullWidth
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    dispatch(updateSearch({
                                        sectionName: sectionName,
                                        search: e.target.value
                                    }))
                                }}
                                value={search ? search : ""}
                                onKeyDown={keyPress}
                            />
                        </div>
                    </div>

                    {props.customSearchForm && (
                        <div className="params-form-of-data-displayer-search-container">
                            <div className="content-of-params-form-of-data-displayer-search-container">
                                <GenerateForm name={props.customSearchForm.name} handleSubmitForm={handleSubmitSearchForm} />
                            </div>
                        </div>
                    )}

                </div>
            </div>
        )
    }


    /**
     * Submit Seacrh form
     */
     const handleSubmitSearchForm = () => {
        const dataDisplayerSection = returnSingleDataDisplayerSection(sectionName)
        loadItems(1, dataDisplayerSection.search, false)
    }

    return(
        <div className="data-displayer-container">
            <div className="content-of-data-displayer-container">

                <GenerateModal {...modalDetails} updateModalDetails={updateModalDetails} />

                {/* Seacrh */}
                {props.enableSearch && renderSearch()}

                {/* Data Displayer Items */}
                <div className="data-displayer-items-container">
                    <div className="content-of-data-displayer-items-container">
                        {renderItems()}
                    </div>
                </div>

                {/* Pagination */}
                {dataDisplayerSection.pagination.total_pages > 1 && renderPagination()}

            </div>
        </div>
    )
}