import React, { useEffect, useState } from 'react'
import { useReturnCurrentUser } from '../../Api/User/userSlice'
import { boolean } from 'yargs'
import CompaniesApp, { CompanyInterface } from './CompaniesApp'
import { Skeleton } from '@mui/material'
import { MessageBoxTypes } from '../../FormElements/GenerateMessageBox'
import CompanyEditForm from "./CompanyEditForm"

export default function CompanyPage() {

    const currentUser = useReturnCurrentUser()
    const companyId = currentUser.company_id

    const companiesApp = new CompaniesApp()
    
    const [loading, setLoading] = useState<boolean>(true)
    const [company, setCompany] = useState<CompanyInterface>({
        id: 0,
        title: "",
        description: "",
        address: "",
        email: "",
        phone: "",
        thumbnail: "",
        is_active: false,
        date_created: ""
    })

    /**
     * Submit Request Response Text
     */
    interface ISubmitRequestResponse {
        type: MessageBoxTypes,
        title: string,
        message: string,
        display: boolean
    }
    const [submitRequestResponse, setSubmitRequestResponse] = useState<ISubmitRequestResponse>({
        type: "info",
        title: "No title",
        message: "No message",
        display: false
    })

    /**
     * Load company details
     */
    const loadCompanyDetails = () => {
        setLoading(true)

        companiesApp.getSingleInstanceDetails(companyId)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:
                        
                        setSubmitRequestResponse({
                            display: false,
                            type: "info",
                            message: "",
                            title: ""
                        })

                        const company: CompanyInterface = data

                        setCompany(company)

                        setLoading(false)

                        break

                }

            })
            .catch(error => {
                companiesApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setSubmitRequestResponse({
                    display: true,
                    type: "error",
                    message: message,
                    title: "Error"
                })
            })


    }

    /**
     * Effect
     */
    useEffect(() => {
        loadCompanyDetails()
    }, [])

    return(
        <div className="company-page-container">
            <div className="content-of-company-page-container">

                <div className="admin-panel-head-section">
                    <div className="content-of-admin-panel-head-section">
                        <h1>{loading ? <Skeleton variant="text" width={150} /> : company.title}</h1>
                    </div>
                </div>

                <div className="admin-panel-single-section-container">
                    <div className="content-of-admin-panel-single-section-container">
                        <CompanyEditForm company={company} loading={loading} update={true} />
                    </div>
                </div>

            </div>
        </div>
    )
}