import { Avatar } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { returnImageUrl } from '../../FormElements/UploadImage'

export default function UserInfoBox() {

    const user = useSelector((state: RootState) => state.user.currentUser)
    let thumbnail = user.thumbnail

    if (thumbnail !== null){
        thumbnail = returnImageUrl(thumbnail)
    }

    if (user){
        return (
            <div className="user-info-box-sidebar">
                <div className="content-of-user-info-box-sidebar">

                    <div className="user-thumb-of-user-info-box-sidebar">
                        <div className="content-of-user-thumb-of-user-info-box-sidebar">
                            <Avatar alt={user.first_name} src={thumbnail}>{user.first_name.charAt(0)}</Avatar>
                        </div>
                    </div>

                    <div className="text-box-of-user-info-box-sidebar">
                        <div className="content-of-text-box-of-user-info-box-sidebar">
                            <h3>{user.first_name} {user.last_name}</h3>
                            <div className="links-box-of-text-box-of-user-info-box-sidebar">
                                <a href="/profile">Profile</a>
                                <a href="/logout">Logout</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }else{
        return <React.Fragment></React.Fragment>
    }
}