import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface SingleFaqInterface {
    title: string,
    description: string
}

const faqList: Array<SingleFaqInterface> = [
    {
        title: "What happens when a card arrives faulty?",
        description: "It never happened. All hitap cards are subject to extreme measures of Quality Assurance and Quality Control before sending them to the end user. Nevertheless, if a card appears to be faulty or doesn’t function upon receipt, it can be replaced if reported within 30 days of receipt."
    },
    {
        title: "How long do the hitap NFC cards last?",
        description: "When well maintained and kept away from strong electromagnetic fields, the NFC card could last between 5-10 years."
    },
    {
        title: "What happens if a card is lost or damaged?",
        description: "A replacement of a lost or damaged card can be issued anytime with the same previous information of the owner with no changes to the content at an additional cost. The only cost that will be incured will be the cost for the card and printing itself."
    },
    // {
    //     title: "Points to note regarding the on going contract for 50 digital business cards",
    //     description: "2 template designs will be shared initially. Once one design is approved, the same will be adapted to the rest to maintain consistency. In case a global profile is required (only company information) this can be included as well."
    // }
]

export function SingleQuestion(props: SingleFaqInterface) {

    const { title, description } = props

    return(
        <div className="single-question-box">
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{title}</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Typography className="faq-description">
                        {description}
                    </Typography>
                </AccordionDetails>

            </Accordion>
        </div>
    )
}

export default function FaqSection() {
    return(
        <div className="faq-section-container">
            <div className="content-of-faq-section-container content-section-wrapper">

                <h3 className="section-title">Frequently Asked Questions</h3>

                <div className="wrapper-of-faq-section-container">
                    {faqList.map((item: SingleFaqInterface, index: number) => <SingleQuestion {...item} key={index} />)}
                </div>

            </div>
        </div>
    )
}