import React from 'react'
import BasePageContent from '../../AdminPanel/components/BasePageContent'
import { useReturnCurrentUser } from '../../Api/User/userSlice'
import FoldersList from "./FoldersList"

export default function ViewChipsPage() {

    const currentUser = useReturnCurrentUser()

    /**
     * Check user rights
     */
    if (currentUser.is_superuser){
        return <BasePageContent />
    }

    return <FoldersList />
}