import { AdminPanelSection } from "./Interfaces";
import RegisterAdminPage from "./components/RegisterAdminPage"
import DashboardPage from "../apps/dashboard/DashboardPage";

import CardsApp from "../apps/cards/CardsApp"
import ChipsApp from "../apps/chips/ChipsApp"
import SlidersApp from "../apps/sliders/SlidersApp"
import { ApiRequestDataInterface } from "../DataDisplayer/Interfaces";
import { IconButton } from "@mui/material";
import GenerateIcon from "../FormElements/GenerateIcon";
import CompanyPage from "../apps/companies/CompanyPage"
import CompaniesApp from "../apps/companies/CompaniesApp"
import UsersApp from "../apps/users/UsersApp"
import User from "../Api/User/User";
import DemoRequestsApp from "../apps/demo_requests/DemoRequestsApp"
import DateAndTime from "../DateAndTime/DateAndTime";

import HelpSectionsApp from "../apps/help_center/HelpSectionsApp"
import HelpArticleApp from "../apps/help_center/HelpArticleApp"

let registeredAdminPages: Array<AdminPanelSection> = []

/**
 * Register Dashboard Page
 */
 registeredAdminPages.push(RegisterAdminPage({
    type: "page",
    name: "dashboard",
    title: "Dashboard",
    icon: "home",
    usersAllowed: {
        onlyAdmin: true,
        onlySuperUser: false,
        anyUser: false
    },
    displayInMenu: false,
    optionalPages: {
        addNewPage: false,
        editPage: false,
        viewPage: false
    },
    pageComponent: DashboardPage,
    disableSectionTitleBox: true
}))

/**
 * Register Cards
 */
const cardsPrefix = "cards_"
registeredAdminPages.push(RegisterAdminPage({
     type: "section",
     name: "digitalcards",
     title: "Digital Business Cards",
     icon: "cards",
     usersAllowed: {
         onlyAdmin: false,
         onlySuperUser: false,
         anyUser: true
     },
     displayInMenu: true,
     optionalPages: {
         addNewPage: true,
         editPage: true,
         viewPage: false
     },
     forms: {
         defaultForm: {
             name: cardsPrefix + "default_form",
             elements: [
                 {
                     type: "TextField",
                     fieldType: "text",
                     xs: 12,
                     id: cardsPrefix + "title",
                     label: "Title",
                     fullWidth: true
                 },
                 {
                    type: "TextField",
                    fieldType: "text",
                    xs: 12,
                    id: cardsPrefix + "description",
                    label: "Description",
                    fullWidth: true
                },
                 {
                     type: "ImageUploader",
                     xs: 12,
                     id: cardsPrefix + "thumbnail",
                     label: "Thumbnail",
                     description: "Here you can upload the project image"
                  },
                  {
                     type: "Button",
                     xs: 12,
                     id: cardsPrefix + "submit",
                     label: "Submit",
                     fullWidth: false,
                     onClick: () => {},
                     isSubmit: true
                 }
             ]
         }
     },
     formsData: [
         {
             name: cardsPrefix + "title",
             value: ""
         },
         {
             name: cardsPrefix + "description",
             value: ""
         },
         {
             name: cardsPrefix + "thumbnail",
             value: ""
         },
         {
            name: cardsPrefix + "submit",
            value: ""
        },
     ],
     appClass: new CardsApp(),
     dataDisplayerDefaultMessages: {
        notFound: "No cards have been found",
        sureToDelete: "Are you sure you want to delete this card?",
        addNewText: "Add new card"
     },
     dataDisplayerFields: [
        {
            name: "thumbnail",
            title: "Thumbnail",
            type: "thumbnail"
        },
        {
            name: "title",
            title: "Title",
            type: "text",
            minWidth: 300,
            maxWidth: 300
        },
        {
            name: "user_name",
            title: "User",
            type: "text",
            minWidth: 200,
            maxWidth: 200
        },
        {
            name: "company_name",
            title: "Company",
            type: "text",
            minWidth: 200,
            maxWidth: 200
        },
     ],
     enableSearch: true,
     customDataDisplayerButtons: [
        {
            type: "custom",
            icon: "view",
            label: "",
            customContent: (details: ApiRequestDataInterface) => {
                return(
                    <IconButton href={`/c/${details.unique_name ? details.unique_name : ""}`} target="_blank">
                        <GenerateIcon icon="view" />
                    </IconButton>
                )
            },
        },
        {
            type: "custom",
            icon: "view",
            label: "",
            customContent: (details: ApiRequestDataInterface) => {
                return(
                    <IconButton href={`/panel/digitalcards/view/${details.unique_name ? details.unique_name : ""}`}>
                        <GenerateIcon icon="chart" />
                    </IconButton>
                )
            },
        },
        {
            type: "custom",
            icon: "edit",
            label: "",
            customContent: (details: ApiRequestDataInterface) => {

                const downloadQrCode = () => {
                    const cardsApp = new CardsApp()
                    cardsApp.downloadQrCode(details.unique_name)
                        .then(response => {
                            const href = URL.createObjectURL(response.data)
                        
                            const link = document.createElement('a')
                            link.href = href
                            link.setAttribute('download', `${details.title} - ${details.unique_name}.svg`)
                            document.body.appendChild(link)
                            link.click()
                        
                            document.body.removeChild(link)
                            URL.revokeObjectURL(href)
                        })
                }

                return(
                    <IconButton onClick={() => downloadQrCode()}>
                        <GenerateIcon icon="qr-code" />
                    </IconButton>
                )
            },
        },
        {
            type: "custom",
            icon: "edit",
            label: "",
            customContent: (details: ApiRequestDataInterface) => {
                return(
                    <IconButton href={`/panel/digitalcards/edit/${details.unique_name ? details.unique_name : ""}`}>
                        <GenerateIcon icon="edit" />
                    </IconButton>
                )
            },
        },
        {
            type: "delete",
            icon: "delete",
            label: "delete"
        }
     ],
     disableSectionTitleBoxInViewPage: true
 }))

/**
 * Register Companies
 */
const companiesPrefix = "companies_"
registeredAdminPages.push(RegisterAdminPage({
     type: "section",
     name: "companies",
     title: "Companies",
     icon: "assignment",
     usersAllowed: {
         onlyAdmin: false,
         onlySuperUser: true,
         anyUser: false
     },
     displayInMenu: true,
     optionalPages: {
         addNewPage: true,
         editPage: true,
         viewPage: false
     },
     forms: {
         defaultForm: {
             name: companiesPrefix + "default_form",
             elements: [
                 {
                     type: "TextField",
                     fieldType: "text",
                     xs: 12,
                     id: companiesPrefix + "title",
                     label: "Title",
                     fullWidth: true
                 },
                 {
                    type: "TextField",
                    fieldType: "text",
                    xs: 12,
                    id: companiesPrefix + "description",
                    label: "Description",
                    fullWidth: true
                },
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 12,
                    id: companiesPrefix + "address",
                    label: "Address",
                    fullWidth: true
                },
                {
                    type: "TextField",
                    fieldType: "email",
                    xs: 6,
                    id: companiesPrefix + "email",
                    label: "Email",
                    fullWidth: true
                },
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 6,
                    id: companiesPrefix + "phone",
                    label: "Phone",
                    fullWidth: true
                },
                 {
                     type: "ImageUploader",
                     xs: 12,
                     id: companiesPrefix + "thumbnail",
                     label: "Thumbnail",
                     description: "Here you can upload the company logo"
                  },
                  {
                     type: "Button",
                     xs: 12,
                     id: companiesPrefix + "submit",
                     label: "Submit",
                     fullWidth: false,
                     onClick: () => {},
                     isSubmit: true
                 }
             ]
         }
     },
     formsData: [
         {
             name: companiesPrefix + "title",
             value: ""
         },
         {
             name: companiesPrefix + "description",
             value: ""
         },
         {
            name: companiesPrefix + "address",
            value: ""
        },
        {
            name: companiesPrefix + "email",
            value: ""
        },
        {
            name: companiesPrefix + "phone",
            value: ""
        },
         {
             name: companiesPrefix + "thumbnail",
             value: ""
         },
         {
            name: companiesPrefix + "submit",
            value: ""
        },
     ],
     appClass: new CompaniesApp(),
     dataDisplayerDefaultMessages: {
        notFound: "No companies have been found",
        sureToDelete: "Are you sure you want to delete this company?",
        addNewText: "Add new company"
     },
     dataDisplayerFields: [
        {
            name: "thumbnail",
            title: "Thumbnail",
            type: "thumbnail"
        },
        {
            name: "title",
            title: "Title",
            type: "text"
        },
     ],
     enableSearch: true,
 }))

/**
 * Register Company Page
 */
registeredAdminPages.push(RegisterAdminPage({
    type: "page",
    name: "company",
    title: "Company",
    icon: "assignment",
    usersAllowed: {
        onlyAdmin: true,
        onlySuperUser: false,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: false,
        editPage: false,
        viewPage: false
    },
    pageComponent: CompanyPage,
    disableSectionTitleBox: true
}))

/**
 * Register Sliders
 */
const slidersPrefix = "sliders_"
registeredAdminPages.push(RegisterAdminPage({
     type: "section",
     name: "sliders",
     title: "Image Sliders",
     icon: "images",
     usersAllowed: {
         onlyAdmin: false,
         onlySuperUser: false,
         anyUser: true
     },
     displayInMenu: true,
     optionalPages: {
         addNewPage: true,
         editPage: true,
         viewPage: false
     },
     forms: {
         defaultForm: {
             name: slidersPrefix + "default_form",
             elements: [
                 {
                     type: "TextField",
                     fieldType: "text",
                     xs: 12,
                     id: slidersPrefix + "title",
                     label: "Title",
                     fullWidth: true
                 },
                  {
                     type: "Button",
                     xs: 12,
                     id: slidersPrefix + "submit",
                     label: "Submit",
                     fullWidth: false,
                     onClick: () => {},
                     isSubmit: true
                 }
             ]
         }
     },
     formsData: [
         {
             name: slidersPrefix + "title",
             value: ""
         },
         {
            name: slidersPrefix + "submit",
            value: ""
        },
     ],
     appClass: new SlidersApp(),
     dataDisplayerDefaultMessages: {
        notFound: "No sliders have been found",
        sureToDelete: "Are you sure you want to delete this slider?",
        addNewText: "Add new slider"
     },
     dataDisplayerFields: [
        {
            name: "thumbnail",
            title: "Thumbnail",
            type: "thumbnail"
        },
        {
            name: "title",
            title: "Title",
            type: "text"
        },
     ],
     enableSearch: true,
 }))

 /**
 * Register Chips
 */
const chipsPrefix = "chips_"
registeredAdminPages.push(RegisterAdminPage({
     type: "section",
     name: "chips",
     title: "NFC Chips",
     icon: "nfc",
     usersAllowed: {
         onlyAdmin: true,
         onlySuperUser: true,
         anyUser: false
     },
     usersAllowedArray: [
        "admin", "superuser"
     ], 
     displayInMenu: true,
     optionalPages: {
         addNewPage: true,
         editPage: true,
         viewPage: false
     },
     forms: {
         defaultForm: {
             name: chipsPrefix + "default_form",
             elements: [
                 {
                     type: "TextField",
                     fieldType: "text",
                     xs: 8,
                     id: chipsPrefix + "title",
                     label: "Title",
                     fullWidth: true
                 },
                 {
                    type: "Select",
                    xs: 4,
                    id: chipsPrefix + "type",
                    label: "Type",
                    fullWidth: true,
                    items: [
                        {
                            id: "admin",
                            title: "Admin"
                        },
                        {
                            id: "staff",
                            title: "Staff"
                        },
                        {
                            id: "guest",
                            title: "Guest"
                        }
                    ]
                },
                  {
                     type: "Button",
                     xs: 12,
                     id: chipsPrefix + "submit",
                     label: "Submit",
                     fullWidth: false,
                     onClick: () => {},
                     isSubmit: true
                 }
             ]
         }
     },
     formsData: [
         {
             name: chipsPrefix + "title",
             value: ""
         },
         {
             name: chipsPrefix + "unique_name",
             value: ""
         },
         {
             name: chipsPrefix + "type",
             value: ""
         },
         {
            name: chipsPrefix + "redirect_url",
            value: ""
         },
         {
            name: chipsPrefix + "card_id",
            value: ""
         },
         {
            name: chipsPrefix + "company_id",
            value: ""
         },
         {
            name: chipsPrefix + "user_id",
            value: ""
         },
         {
            name: chipsPrefix + "folder_id",
            value: ""
         },
         {
            name: chipsPrefix + "submit",
            value: ""
        },
     ],
     appClass: new ChipsApp(),
     dataDisplayerDefaultMessages: {
        notFound: "No chips have been found",
        sureToDelete: "Are you sure you want to delete this chip?",
        addNewText: "Add new chip"
     },
     dataDisplayerFields: [
        {
            name: "title",
            title: "Title",
            type: "text",
            maxWidth: 200,
            minWidth: 200
        },
        {
            name: "title",
            title: "Status",
            type: "text",
            maxWidth: 200,
            minWidth: 200,
            customContent: (data) => {

                let status = "not-active"
                let textStatus = "Not Registered"

                if (data.is_registered){
                    status = "active"
                    textStatus = "registered"
                }

                return <div style={{ paddingTop: 10 }}><span className={"span-button-status " + status}>{textStatus}</span></div>
            },
        },
        {
            name: "chip_type",
            title: "Type",
            type: "text",
            maxWidth: 200,
            minWidth: 200,
            customContent: (data) => {
                return <h3>{data.chip_type ? new ChipsApp().returnNFCChipTypeTitle(data.chip_type) : "-"}</h3>
            },
        },
        {
            name: "company_name",
            title: "Company",
            type: "text",
            maxWidth: 200,
            minWidth: 200,
            customContent: (data) => {
                return <h3>{data.company_name ? data.company_name : "-"}</h3>
            },
        },
        {
            name: "company_name",
            title: "User",
            type: "text",
            maxWidth: 200,
            minWidth: 200,
            customContent: (data) => {
                return <h3>{data.user_name ? data.user_name : "-"}</h3>
            },
        },
        {
            name: "unique_name",
            title: "Unique name",
            type: "text",
            maxWidth: 300,
            minWidth: 300
        },
     ],
     enableSearch: true,
     customDataDisplayerButtons: [
        // {
        //     type: "custom",
        //     icon: "view",
        //     label: "",
        //     customContent: (details: ApiRequestDataInterface) => {
        //         return(
        //             <IconButton href={`/c/${details.unique_name ? details.unique_name : ""}`} target="_blank">
        //                 <GenerateIcon icon="view" />
        //             </IconButton>
        //         )
        //     },
        // },
        {
            type: "custom",
            icon: "edit",
            label: "",
            customContent: (details: ApiRequestDataInterface) => {
                return(
                    <IconButton href={`/panel/chips/edit/${details.unique_name ? details.unique_name : ""}`}>
                        <GenerateIcon icon="edit" />
                    </IconButton>
                )
            },
        },
        {
            type: "custom",
            icon: "edit",
            label: "",
            customContent: (details: ApiRequestDataInterface) => {

                const downloadQrCode = () => {
                    const chipsApp = new ChipsApp()
                    chipsApp.downloadQrCode(details.unique_name)
                        .then(response => {
                            const href = URL.createObjectURL(response.data)
                        
                            const link = document.createElement('a')
                            link.href = href
                            link.setAttribute('download', `${details.title} - ${details.unique_name}.svg`)
                            document.body.appendChild(link)
                            link.click()
                        
                            document.body.removeChild(link)
                            URL.revokeObjectURL(href)
                        })
                }

                return(
                    <IconButton onClick={() => downloadQrCode()}>
                        <GenerateIcon icon="qr-code" />
                    </IconButton>
                )
            },
        },
        {
            type: "delete",
            icon: "delete",
            label: "delete"
        }
     ]
 }))

/**
 * Register users
 */
const usersPrefix = "users_"
registeredAdminPages.push(RegisterAdminPage({
    type: "section",
    name: "users",
    title: "Users",
    icon: "users",
    usersAllowed: {
        onlyAdmin: false,
        onlySuperUser: true,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: true,
        editPage: false,
        viewPage: false
    },
    forms: {
        defaultForm: {
            name: usersPrefix + "default_form",
            elements: [
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 4,
                    id: usersPrefix + "first_name",
                    label: "First Name",
                    fullWidth: true
                },
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 4,
                    id: usersPrefix + "middle_name",
                    label: "Middle Name",
                    fullWidth: true
                },
                {
                   type: "TextField",
                   fieldType: "text",
                   xs: 4,
                   id: usersPrefix + "last_name",
                   label: "Last Name",
                   fullWidth: true
               },
               {
                   type: "TextField",
                   fieldType: "email",
                   xs: 6,
                   id: usersPrefix + "email",
                   label: "Email",
                   fullWidth: true
               },
               {
                type: "TextField",
                fieldType: "text",
                xs: 6,
                id: usersPrefix + "phone",
                label: "Phone",
                fullWidth: true
                },
               {
                   type: "DynamicSelect",
                   xs: 6,
                   id: usersPrefix + "company_id",
                   label: "Company",
                   fullWidth: true,
                   idFieldName: "id",
                   titleFieldName: "title",
                   appClass: new CompaniesApp(),
                   addNoneItem: false,
                   formName: usersPrefix + "default_form"
               },
               {
                   type: "Select",
                   xs: 6,
                   id: usersPrefix + "type",
                   label: "Type",
                   fullWidth: true,
                   items: [
                       {
                           id: "admin",
                           title: "Admin"
                       },
                       {
                           id: "staff",
                           title: "Staff"
                       },
                   ]
               },
               {
                   type: "ImageUploader",
                   xs: 12,
                   id: usersPrefix + "thumbnail",
                   label: "Thumbnail",
                   description: "Here you can upload the user picture"
                },
               {
                    type: "Button",
                    xs: 12,
                    id: usersPrefix + "submit",
                    label: "Submit",
                    fullWidth: false,
                    onClick: () => {},
                    isSubmit: true
                }
            ]
        }
    },
    formsData: [
        {
            name: usersPrefix + "first_name",
            value: ""
        },
        {
           name: usersPrefix + "last_name",
           value: ""
       },
       {
        name: usersPrefix + "middle_name",
        value: ""
        },
       {
           name: usersPrefix + "email",
           value: ""
       },
       {
        name: usersPrefix + "phone",
        value: ""
        },
       {
           name: usersPrefix + "company_id",
           value: ""
       },
       {
           name: usersPrefix + "type",
           value: ""
       },
       {
           name: usersPrefix + "thumbnail",
           value: ""
       },
       {
           name: usersPrefix + "submit",
           value: ""
       },
    ],
    appClass: new UsersApp(),
    dataDisplayerDefaultMessages: {
       notFound: "No users have been found",
       sureToDelete: "Are you sure you want to delete this user?",
       addNewText: "Add new user"
    },
    dataDisplayerFields: [
       {
           name: "thumbnail",
           title: "Thumbnail",
           type: "thumbnail"
       },
       {
           name: "first_name",
           title: "Name",
           type: "text",
           minWidth: 200,
           maxWidth: 200,
           customContent: (details) => {
               return details.first_name && details.last_name ? <h3>{details.first_name} {details.last_name}</h3>: ""
           }
       },
       {
           name: "last_name",
           title: "Type",
           type: "text",
           minWidth: 200,
           maxWidth: 200,
           customContent: (details) => {
               return details.type ? <h3>{new User().returnUserTypeTitle(details.type)}</h3> : <h3>Guest</h3>
           }
       },
       {
           name: "company_id",
           title: "Company",
           type: "text",
           minWidth: 200,
           maxWidth: 200,
           customContent: (details) => {
               return details.company_name ? <h3>{details.company_name}</h3>: "-"
           }
       },
       {
           name: "email",
           title: "Email",
           type: "text"
       },
    ],
    enableSearch: true,
    customDataDisplayerButtons: [
       {
           type: "delete",
           label: "Delete",
           icon: ""
       }
   ]
}))

/**
 * Register demo requests
 */
const demoRequestsPrefix = "demorequests_"
registeredAdminPages.push(RegisterAdminPage({
    type: "section",
    name: "demorequests",
    title: "Demo Requests",
    icon: "contacts",
    usersAllowed: {
        onlyAdmin: false,
        onlySuperUser: true,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: true,
        editPage: false,
        viewPage: false
    },
    forms: {
        defaultForm: {
            name: usersPrefix + "default_form",
            elements: [
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 6,
                    id: demoRequestsPrefix + "name",
                    label: "Name",
                    fullWidth: true
                },
                {
                    type: "TextField",
                    fieldType: "phone",
                    xs: 6,
                    id: demoRequestsPrefix + "phone",
                    label: "Phone",
                    fullWidth: true
                },
                {
                    type: "TextField",
                    fieldType: "email",
                    xs: 12,
                    id: demoRequestsPrefix + "email",
                    label: "Email",
                    fullWidth: true
                },
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 12,
                    id: demoRequestsPrefix + "company",
                    label: "Company",
                    fullWidth: true
                },
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 12,
                    id: demoRequestsPrefix + "message",
                    label: "Message",
                    fullWidth: true,
                    multiline: true,
                    maxRows: 4,
                    minRows: 4
                },
                {
                    type: "Button",
                    xs: 12,
                    id: demoRequestsPrefix + "submit",
                    label: "Submit",
                    fullWidth: false,
                    onClick: () => {},
                    isSubmit: true
                },
            ]
        }
    },
    formsData: [
        {
            name: demoRequestsPrefix + "email",
            value: ""
        },
        {
            name: demoRequestsPrefix + "name",
            value: ""
        },
        {
            name: demoRequestsPrefix + "phone",
            value: ""
        },
        {
            name: demoRequestsPrefix + "company",
            value: ""
        },
        {
            name: demoRequestsPrefix + "message",
            value: ""
        },
        {
            name: demoRequestsPrefix + "submit",
            value: ""
        }
    ],
    appClass: new DemoRequestsApp(),
    dataDisplayerDefaultMessages: {
       notFound: "No requests have been found",
       sureToDelete: "Are you sure you want to delete this request?",
       addNewText: "Add new request"
    },
    dataDisplayerFields: [
       {
           name: "name",
           title: "Name",
           type: "text",
           maxWidth: 300,
           minWidth: 300
       },
       {
            name: "email",
            title: "Email",
            type: "text",
            maxWidth: 300,
           minWidth: 300
        },
        {
            name: "phone",
            title: "Phone",
            type: "text",
            maxWidth: 200,
            minWidth: 200
        },
        {
            name: "company",
            title: "Company",
            type: "text",
            maxWidth: 200,
            minWidth: 200
        },
        {
            name: "date_created",
            title: "Date",
            type: "text",
            maxWidth: 300,
            minWidth: 300,
            customContent: (data) => {
                return <h3>{data.date_created ? new DateAndTime().formatDateAndTime(data.date_created) : "-"}</h3>
            }
        },
    ],
    enableSearch: true,
    customDataDisplayerButtons: [
        {
            type: "edit",
            label: "Edit",
            icon: ""
        },
       {
           type: "delete",
           label: "Delete",
           icon: ""
       }
   ]
}))

/**
 * Register help sections
 */
const helpSectionsPrefix = "sections_"
registeredAdminPages.push(RegisterAdminPage({
    type: "section",
    name: "sections",
    title: "Help Sections",
    icon: "quiz",
    usersAllowed: {
        onlyAdmin: false,
        onlySuperUser: true,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: true,
        editPage: false,
        viewPage: false
    },
    forms: {
        defaultForm: {
            name: helpSectionsPrefix + "default_form",
            elements: [
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 12,
                    id: helpSectionsPrefix + "title",
                    label: "Name",
                    fullWidth: true
                },
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 12,
                    id: helpSectionsPrefix + "description",
                    label: "Description",
                    fullWidth: true,
                    multiline: true,
                    maxRows: 4,
                    minRows: 4
                },
                 {
                     type: "ImageUploader",
                     xs: 12,
                     id: helpSectionsPrefix + "thumbnail",
                     label: "Thumbnail",
                     description: "Here you can upload the section image"
                  },
                {
                    type: "Button",
                    xs: 12,
                    id: helpSectionsPrefix + "submit",
                    label: "Submit",
                    fullWidth: false,
                    onClick: () => {},
                    isSubmit: true
                },
            ]
        }
    },
    formsData: [
        {
            name: helpSectionsPrefix + "title",
            value: ""
        },
        {
            name: helpSectionsPrefix + "description",
            value: ""
        },
        {
            name: helpSectionsPrefix + "thumbnail",
            value: ""
        },
        {
            name: helpSectionsPrefix + "submit",
            value: ""
        }
    ],
    appClass: new HelpSectionsApp(),
    dataDisplayerDefaultMessages: {
       notFound: "No sections have been found",
       sureToDelete: "Are you sure you want to delete this section?",
       addNewText: "Add new section"
    },
    dataDisplayerFields: [
       {
           name: "title",
           title: "Title",
           type: "text",
           maxWidth: 300,
           minWidth: 300
       },
        {
            name: "date_created",
            title: "Date",
            type: "text",
            maxWidth: 300,
            minWidth: 300,
            customContent: (data) => {
                return <h3>{data.date_created ? new DateAndTime().formatDateAndTime(data.date_created) : "-"}</h3>
            }
        },
    ],
    enableSearch: true,
    customDataDisplayerButtons: [
        {
            type: "edit",
            label: "Edit",
            icon: ""
        },
       {
           type: "delete",
           label: "Delete",
           icon: ""
       }
   ]
}))

/**
 * Register articles
 */
const articlesPrefix = "articles_"
registeredAdminPages.push(RegisterAdminPage({
    type: "section",
    name: "articles",
    title: "Articles",
    icon: "posts",
    usersAllowed: {
        onlyAdmin: false,
        onlySuperUser: true,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: true,
        editPage: false,
        viewPage: false
    },
    forms: {
        defaultForm: {
            name: articlesPrefix + "default_form",
            elements: [
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 6,
                    id: articlesPrefix + "title",
                    label: "Name",
                    fullWidth: true
                },
                {
                    type: "DynamicSelect",
                    xs: 6,
                    id: articlesPrefix + "section_id",
                    label: "Section",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new HelpSectionsApp(),
                    addNoneItem: false,
                    formName: articlesPrefix + "default_form"
                },
                {
                    type: "Editor",
                    fieldType: "text",
                    xs: 12,
                    id: articlesPrefix + "body",
                    label: "Content",
                    fullWidth: true,
                },
                 {
                     type: "ImageUploader",
                     xs: 12,
                     id: articlesPrefix + "thumbnail",
                     label: "Thumbnail",
                     description: "Here you can upload the section image"
                  },
                {
                    type: "Button",
                    xs: 12,
                    id: articlesPrefix + "submit",
                    label: "Submit",
                    fullWidth: false,
                    onClick: () => {},
                    isSubmit: true
                },
            ]
        }
    },
    formsData: [
        {
            name: articlesPrefix + "title",
            value: ""
        },
        {
            name: articlesPrefix + "body",
            value: ""
        },
        {
            name: articlesPrefix + "section_id",
            value: ""
        },
        {
            name: articlesPrefix + "thumbnail",
            value: ""
        },
        {
            name: articlesPrefix + "submit",
            value: ""
        }
    ],
    appClass: new HelpArticleApp(),
    dataDisplayerDefaultMessages: {
       notFound: "No articles have been found",
       sureToDelete: "Are you sure you want to delete this article?",
       addNewText: "Add new article"
    },
    dataDisplayerFields: [
       {
           name: "title",
           title: "Title",
           type: "text",
           maxWidth: 300,
           minWidth: 300
       },
       {
            name: "section_name",
            title: "Section",
            type: "text",
            maxWidth: 300,
            minWidth: 300
        },
        {
            name: "date_created",
            title: "Date",
            type: "text",
            maxWidth: 300,
            minWidth: 300,
            customContent: (data) => {
                return <h3>{data.date_created ? new DateAndTime().formatDateAndTime(data.date_created) : "-"}</h3>
            }
        },
    ],
    enableSearch: true,
    customDataDisplayerButtons: [
        {
            type: "edit",
            label: "Edit",
            icon: ""
        },
       {
           type: "delete",
           label: "Delete",
           icon: ""
       }
   ]
}))


/**
 * Export admin pages
 */
export const adminPages = registeredAdminPages