import React from 'react'

import ContactlessIcon from "../../../assets/svg/contactless.svg"
import UserFriendlyIcon from "../../../assets/svg/user-friendly.svg"
import Sustainability from "../../../assets/svg/sustainability.svg"
import IdCardIcon from "../../../assets/svg/id-card.svg"
import { ItemInLineInterface, SingleItemInLine } from './ItemsAfterIntro'

const itemsInLineList: Array<ItemInLineInterface> = [
    {
        icon: ContactlessIcon,
        title: "Contactless and Hygienic",
        sub_title: "Information is shared contactlessly by tapping the hitap Digital Business Card to Smart phone devices."
    },
    {
        icon: UserFriendlyIcon,
        title: "User Friendly",
        sub_title: "Register, create your profile and start using it in minutes. Online support is always available for assistance."
    },
    {
        icon: Sustainability,
        title: "Cost Saving and Environmentally Friendly",
        sub_title: "Paperless solution, saving you thousands of dollars on printed business cards, profiles and brochures."
    },
    {
        icon: IdCardIcon,
        title: "Always on You",
        sub_title: "No more forgetting business cards."
    }
]

export default function MoreFeatures() {
    return(
        <div className="items-after-intro-section more-features-blue-section">
            <div className="content-of-items-after-intro-section content-section-wrapper">

                <div className="items-in-line-container">
                    {itemsInLineList.map((item: ItemInLineInterface, index: number) => <SingleItemInLine {...item} key={index} />)}
                </div>

            </div>
        </div>
    )
}