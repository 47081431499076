import { IconButton, Skeleton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import AdminPanel from '../AdminPanel/AdminPanel'
import { BaseAppInterface } from '../apps/BaseApp'
import GenerateIcon from '../FormElements/GenerateIcon'
import GenerateModal from '../FormElements/GenerateModal'
import { FormElementModal, FormElementModalInterface, FormElementModalSingleButton } from '../FormElements/Interfaces'
import { deleteSingleItem, updateSingleItemLoadingStatus } from './dataDisplayerItemsSlice'
import { ApiRequestDataInterface, DataDisplayerItemButton } from './Interfaces'

/**
 * Single item button
 */
export const singleItemButton = (button: DataDisplayerItemButton, index: number, itemId: number, navigate: NavigateFunction, loading: boolean = false) => {
    return(
        <div className="single-data-displayer-item-buttom" key={index}>
            <div className="content-of-single-data-displayer-item-buttom">
                {loading ? (
                    <Skeleton variant="circular" width={40} height={40} />
                ): (
                    <Tooltip title={button.label}>
                        <IconButton onClick={() => {
                            if (button.isLink && button.link){
                                // return navigate(button.link)
                                return window.location.replace(button.link)
                            }else{
                                if (button.onClick){
                                    button.onClick(itemId)
                                }else{
                                    console.log("clicked")
                                }
                            }
                        }}>
                            <GenerateIcon icon={button.icon ? button.icon : "home"} />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

interface ComponentProps {
    buttons: Array<DataDisplayerItemButton>,
    itemId: number,
    sectionName: string,
    loading?: boolean,
    appClass: BaseAppInterface,
    details: ApiRequestDataInterface
}

export default function ItemButtons(props: ComponentProps) {

    const { buttons, itemId, sectionName, loading, appClass, details } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const adminPanel = new AdminPanel()
    const currentSection = adminPanel.returnSingleAdminSectionDetails(sectionName)

    const [ modalDetails, setModalDetails ] = useState<FormElementModalInterface>({
        open: false,
        title: "No title",
        message: "No message",
        buttons: []
    })

    /**
     * Update modal details
     */
    const updateModalDetails = (open: boolean, title: string, message: string, buttons?: Array<FormElementModalSingleButton>) => setModalDetails({
        open,
        title,
        message,
        buttons: buttons ? buttons : []
    })

    /**
     * Update item loading status
     */
    const updateItemsLoadingStatus = (itemId: number, loading: boolean) => {
        dispatch(updateSingleItemLoadingStatus({
            sectionName,
            itemId,
            loading
        }))
    }

    /**
     * Delete item
     */
    const deleteItem = (itemId: number) => {
        dispatch(deleteSingleItem({
            sectionName,
            itemId
        }))
    }

    /**
     * Handle delete item
     */
    const handleDeleteItem = (itemId: number) => {

        const deleteMessage = currentSection.dataDisplayerDefaultMessages?.sureToDelete ? currentSection.dataDisplayerDefaultMessages?.sureToDelete : "Are you sure that you want to delete it?"
        const deleteTitle = "Are you sure?"

        updateModalDetails(true, deleteTitle, deleteMessage, [
            {
                label: "Delete",
                onClick: () => {
                    updateItemsLoadingStatus(itemId, true)
                    updateModalDetails(false, deleteTitle, deleteMessage)

                    appClass.deleteInstance(itemId)
                        .then(response => {
                            deleteItem(itemId)
                            updateModalDetails(false, deleteTitle, deleteMessage)
                        })
                        .catch(error => {
                            const { data, status, statusText } = error.response
                            const message = data.message ? data.message : statusText

                            updateItemsLoadingStatus(itemId, false)
                            updateModalDetails(true, "Error", message)
                        })
                }
            },
            {
                label: "Cancel",
                onClick: () => updateModalDetails(false, deleteTitle, deleteMessage)
            }
        ])
    }

    /**
     * Render button
     */
    const renderButton = (button: DataDisplayerItemButton, index: number) => {

        const { type } = button

        // let buttonData: DataDisplayerItemButton = button
        let buttonData: DataDisplayerItemButton = {
            type: button.type ? button.type : "custom",
            label: button.label ? button.label : "",
            icon: button.icon ? button.icon : "",
            isLink: button.isLink ? button.isLink : false,
            link: button.link ? button.link : ""
        }

        switch(type){

            case "edit":
                buttonData.icon = "edit"
                buttonData.isLink = true
                buttonData.link = `/panel/${sectionName}/edit/${itemId}`
                break

            case "delete":
                buttonData.isLink = false
                buttonData.icon = "delete"
                buttonData.onClick = handleDeleteItem
                break

            case "view":
                buttonData.icon = "view"
                buttonData.isLink = true
                buttonData.link = `/panel/${sectionName}/view/${itemId}`
                break


        }

        if (button.type === "custom" && button.customContent){
            return <>{button.customContent(details)}</>
        }

        return singleItemButton(buttonData, index, itemId, navigate, loading)

    }

    return(
        <div className="buttons-container-of-data-displayer-single-item">
            <div className="content-of-buttons-container-of-data-displayer-single-item">
                <GenerateModal {...modalDetails} updateModalDetails={updateModalDetails} />
                {buttons && buttons.length > 0 && buttons.map((button: DataDisplayerItemButton, index: number) => renderButton(button, index))}
            </div>
        </div>
    )
}