import { Avatar, Skeleton } from '@mui/material'
import React from 'react'
import { returnImageUrl } from '../FormElements/UploadImage'
import { ApiRequestDataInterface, DataDisplayerItemField } from './Interfaces'

interface ComponentProps {
    fields: Array<DataDisplayerItemField>,
    loading?: boolean,
    details?: ApiRequestDataInterface
}

/**
 * Data Displayer Thumbnail Field
 */
export const dataDisplayerThumbnailField = (field: DataDisplayerItemField, index: number, loading: boolean = false, details: ApiRequestDataInterface | undefined) => {
    return(
        <div className="single-field-of-data-displayer-item" key={index}>
            <div className="content-of-single-field-of-data-displayer-item">
                {loading ? (
                    <Skeleton variant="circular" width={50} height={50} />
                ): field.customContent ? <>{details ? field.customContent(details) : ""}</> : (
                    <Avatar src={field.value ? returnImageUrl(field.value) : ""}>
                        {field.label ? field.label.substring(0, 1) : "N"}
                    </Avatar>
                )}
            </div>
        </div>
    )
}

/**
 * Data Dispkayer Text Field
 */
export const dataDisplayerTextField = (field: DataDisplayerItemField, index: number, loading: boolean = false, details?: ApiRequestDataInterface) => {

    const returnValue = () =>{
        if (details && field.customContent){
            return <>{field.customContent(details)}</>
        }

        return <h3>{field.value}</h3>
    }


    return(
        <div className="single-field-of-data-displayer-item" key={index} style={{
            maxWidth: field.maxWidth,
            minWidth: field.minWidth
        }}>
            <div className="content-of-single-field-of-data-displayer-item">
                {loading ? <Skeleton variant="text" width={50} /> : <h4>{field.label}</h4>}
                {loading ? <Skeleton variant="text" width={100} /> : returnValue()}
            </div>
        </div>
    )
}

/**
 * Render single data displayer field
 */
export const renderSingleDataDisplayerField = (field: DataDisplayerItemField, index: number, loading: boolean = false, details?: ApiRequestDataInterface) => {
    const type = field.type

    switch(type){

        case "thumbnail":
            return dataDisplayerThumbnailField(field, index, loading, details)
            break

        case "text":
            return dataDisplayerTextField(field, index, loading, details)
            break

        default:
            return(<div key={index}></div>)
    }

}

export default function ItemFields(props: ComponentProps) {

    const { fields, loading } = props


    return(
        <div className="single-data-displayer-item-fields-container">
            <div className="content-of-single-data-displayer-item-fields-container">
                {fields && fields.length > 0 && fields.map((field: DataDisplayerItemField, index: number) => renderSingleDataDisplayerField(field, index, loading, props.details))}
            </div>
        </div>
    )
}