import React from 'react'
import { useMatch } from 'react-router-dom'
import GenerateMessageBox from '../../FormElements/GenerateMessageBox'
import AdminPanel from '../AdminPanel'
import AddNewAdminPage from './AddNewAdminPage'
import BasePageContent from './BasePageContent'
import EditAdminPage from './EditAdminPage'
import SectionHeadSection from './SectionHeadSection'
import { useReturnCurrentUser } from '../../Api/User/userSlice'

export default function RenderAdminPageContent() {

    const adminPanel = new AdminPanel()
    const page = adminPanel.returnCurrentPageType()
    const section = adminPanel.returnSingleAdminSectionDetails(adminPanel.returnCurrentSectionName())
    const currentUser = useReturnCurrentUser()

    /**
     * Render Content
     */
    const renderContent = () => {

        switch(page){

            case "base":
                if (section.type === "page"){
                    if (section.pageComponent!){
                        return <section.pageComponent />
                    }else{
                        return <GenerateMessageBox type="error" title="Not Found" message={`[${section.title}].pageComponent - is not set in the section config. Please, check the RegisterAdminPages.tsx`} />
                    }
                }else{

                    if (section.appClass?.customBasePageContent){
                        return <>{section.appClass.customBasePageContent()}</>
                    }

                    return <BasePageContent />
                }
                break

            case "edit":
                if (section.appClass?.editAdminContentPage){
                    return <>{section.appClass.editAdminContentPage()}</>
                }else{
                    return <EditAdminPage />
                }
                break 

            case "view":
                if (section.appClass?.viewAdminContentPage){
                    return <>{section.appClass.viewAdminContentPage()}</>
                }else{
                    return <GenerateMessageBox type="info" title="Not Found" message="viewAdminContentPage method was not found in the app class." />
                }
                break

            case "new":
                if (section.appClass?.addNewAdminContentPage){
                    return <>{section.appClass.addNewAdminContentPage()}</>
                }else{
                    return <AddNewAdminPage />
                }
                break

            default:
                return(
                    <div>
                        Unknown Type
                    </div>
                )
        }

    }

    let hidePage: boolean = adminPanel.hideMenuItem(section.usersAllowed, currentUser)

    let disableSectionTitle = false

    if (section.disableSectionTitleBox){
        disableSectionTitle = true
    }

    if (page === "view"){
        if (section.disableSectionTitleBoxInViewPage){
            disableSectionTitle = true
        }
    }

    return(
        <div className="admin-section-main-wrapper">
            <div className="content-of-admin-section-main-wrapper">

                {!disableSectionTitle &&<SectionHeadSection />}
                {!hidePage ? renderContent() : <GenerateMessageBox type="error" title="Unauthorized" message="You don't have the access to view this page" />}

            </div>
        </div>
    )
}