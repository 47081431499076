import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'

export default function RedirectToProfile() {

    useEffect(() => window.location.replace("/profile/details"))

    return(
        <div>
            
        </div>
    )
}