import React from 'react'
import { useParams } from 'react-router-dom'
import SectionDefaultForm from './SectionDefaultForm'

export default function EditAdminPage() {

    const { id } = useParams()

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">
                <SectionDefaultForm update={true} itemId={id} />
            </div>
        </div>
    )
}