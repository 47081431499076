import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { returnCurrentUser, useReturnCurrentUser } from '../../Api/User/userSlice'
import "../css/styles.css"
import RenderAdminPageContent from './RenderAdminPageContent'
import Sidebar from './Sidebar'
import AdminHeader from "./AdminHeader"
import { returnErrorPageDetails, updateErrorPageDetails } from "../../Pages/Error/errorPageSlice"
import ErrorPage from '../../Pages/Error/ErrorPage'
import { retunrPageLoadingStatus } from '../../PagePreloader/pagePreloaderSlice'
import { User } from '../../Api/Interfaces'

export default function AdminBasePage() {

    let pageContainerClassName: string = "admin-panel-base-page-container"

    if (window.localStorage.getItem("admin_sidebar") === "hidden"){
        pageContainerClassName += " hide-sidebar"
    }

    return(
        <div className={pageContainerClassName} id="admin-panel-main-page-container">

            <AdminHeader />
            <Sidebar />

            <main className="admin-panel-main-content">
                <div className="content-of-admin-panel-main-content">
                    <RenderAdminPageContent />
                </div>
            </main>

        </div>
    )

}