import React, { useEffect, useState } from 'react'
import { FormElementData, FormElementImageUploaderInterface } from '../Interfaces'
import NoThumb from "../../../assets/svg/no-thumb.svg"
import { Alert, Button, IconButton } from '@mui/material'
import GenerateIcon from '../GenerateIcon'
import UploadImage, { UploadImageInterface } from '../UploadImage'
import GenerateMessageBox, { MessageBoxPropsInterface, MessageBoxTypes } from '../GenerateMessageBox'
import { LoadingButton } from '@mui/lab'
import Cropper from 'react-easy-crop'
import { Area } from "react-easy-crop"

interface Props extends FormElementImageUploaderInterface{
    data: FormElementData,
    handleOnChange: (name: string, value: any) => void,
}

export default function FormElementImageUploader(props: Props) {
    const { label, description, data, handleOnChange, id } = props
    const uploadedImg = data.value

    interface CropInterface {
        x: number,
        y: number
    }

    const [crop, setCrop] = useState<CropInterface>({ x: 0, y: 0 })
    const [zoom, setZoom] = useState<number>(1)
    const [justUploadedImg, setJustUploadedImg] = useState<string>()
    const [imgAfterCrop, setImgAfterCrop] = useState<string>()
    const [croppedArea, setCroppedArea] = useState<Area | null>(null)
    const [isFileReadyToUpload, setIsFileReadyToUpload] = useState<boolean>(false)
    const [currentAspectRatio, setCurrentAspectRatio] = useState<number>(1 / 1)

    interface MessageBoxDetailsInterfaceState {
        display: boolean,
        type: MessageBoxTypes,
        title: string
    }

    const [messageBoxDetails, setMessageBoxDetails] = useState<MessageBoxDetailsInterfaceState>({
        display: false,
        type: "info",
        title: "No title",
    })

    /**
     * loading
     */
    const [loading, setLoading] = useState<boolean>(false)

    /**
     * Upload image
     */
    // const uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     event.preventDefault()

    //     if (event.target.files && event.target.files[0] !== undefined){
    //         const imageUploader = new UploadImage()
    //         let uploadImage = imageUploader.uploadImage(event)

    //         if (props.idCardUploader){
    //             uploadImage = imageUploader.uploadIdCard(event)
    //         }

    //         if (uploadImage !== undefined){
    //             setLoading(true)
    //             uploadImage
    //                 .then(response => {
    //                     imageUploader.logResults(response)

    //                     const { status, statusText, data } = response

    //                     switch(status){
                            
    //                         case 200:
    //                             if (data.url){
    //                                 let uplaodedImage = data.url
    
    //                                 handleOnChange(id, uplaodedImage)
    //                             }else{
    //                                 setMessageBoxDetails({
    //                                     display: true,
    //                                     type: "info",
    //                                     title: "Image was not provided from the API"
    //                                 })
    //                             }
    //                             break;

    //                         default:
    //                             setMessageBoxDetails({
    //                                 display: true,
    //                                 type: "info",
    //                                 title: data.message ? data.message : statusText
    //                             })
    //                     }

    //                     setLoading(false)

    //                 })
    //                 .catch(error => {
    //                     imageUploader.logResults(error)

    //                     const { status, statusText, data } = error.response

    //                     setMessageBoxDetails({
    //                         display: true,
    //                         type: "error",
    //                         title: data.message ? data.message : statusText
    //                     })

    //                     setLoading(false)

    //                 })
    //         }

    //     }

    // }

    /**
     * Upload image
     */
    const uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        if (event.target.files && event.target.files[0] !== undefined){
            const imageUploader = new UploadImage()
            const image = event.target.files[0]

            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = function (e) {
                const imgData = reader.result

                if (imgData && typeof imgData === "string"){
                    setJustUploadedImg(imgData)
                }

            }

        }

    }

    /**
     * Open image uploader
     */
    const openImageUploader = () =>  {
        if (document.getElementById("file_uploader_of_" + id) !== null) {
            document.getElementById("file_uploader_of_" + id)!.click()
        }
    }
    const deleteImage = () => {
        handleOnChange(id, "")
    }

    const onCropComplete = (croppedAreaPercentage: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels);
    }

    const onCropDone = (imgCroppedArea: Area) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;
    
        const context = canvasEle.getContext("2d");
    
        if (justUploadedImg && context){
            let imageObj1 = new Image();
            imageObj1.src = justUploadedImg;
            imageObj1.onload = function () {
              context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
              );
        
              const dataURL = canvasEle.toDataURL("image/jpeg");
        
              setImgAfterCrop(dataURL)
              setIsFileReadyToUpload(true)
            }
        }

    };

    /**
     * Upload file to the server
     */
    const uploadFileToServer = () => {

        const image = imgAfterCrop

        if (image){
            const imageUploader = new UploadImage()

            const formData = new FormData();
            formData.append('image', imageUploader.dataURIToBlob(image)) 
            
            let uploadImage = imageUploader.uploadImage(formData)

            if (uploadImage !== undefined){
                setLoading(true)
                uploadImage
                    .then(response => {
                        imageUploader.logResults(response)

                        const { status, statusText, data } = response

                        switch(status){
                            
                            case 200:
                                if (data.url){
                                    let uplaodedImage = data.url

                                    handleOnChange(id, uplaodedImage)
                                }else{
                                    setMessageBoxDetails({
                                        display: true,
                                        type: "info",
                                        title: "Image was not provided from the API"
                                    })
                                }
                                break;

                            default:
                                setMessageBoxDetails({
                                    display: true,
                                    type: "info",
                                    title: data.message ? data.message : statusText
                                })
                        }

                        setLoading(false)
                        setJustUploadedImg("")
                        setIsFileReadyToUpload(false)

                    })
                    .catch(error => {
                        imageUploader.logResults(error)

                        const { status, statusText, data } = error.response

                        setMessageBoxDetails({
                            display: true,
                            type: "error",
                            title: data.message ? data.message : statusText
                        })

                        setLoading(false)
                        setJustUploadedImg("")
                        setIsFileReadyToUpload(false)

                    })
            }
        }

    }

    const cancelCrop = () => {
        setImgAfterCrop(justUploadedImg)
        setIsFileReadyToUpload(true)
    }

    /**
     * Upload file
     */
    useEffect(() => {
        uploadFileToServer()
    }, [isFileReadyToUpload])


    return(
        <div className="form-element-image-uploader-container">
            <div className="content-of-form-element-image-uploader-container">

                <form style={{ 
                    display: "none"
                 }}>
                    <input type="file" id={"file_uploader_of_" + id} onChange={uploadImage} accept=".jpg, .png, .jpeg" />
                    <input name={id} type="hidden" value={data.value} />
                </form>

                <div className="text-box-of-form-element-image-uploader-container">
                    <div className="content-of-text-box-of-form-element-image-uploader-container">
                        <h3>
                            <IconButton style={{
                                margin: "-2px 5px 0 0"
                            }} onClick={() => openImageUploader()}>
                                <GenerateIcon icon="upload" />
                            </IconButton>
                            {label}
                        </h3>
                        <p>{description ? description : ""}</p>
                    </div>
                </div>

                <div className="image-box-of-form-element-image-uploader-container">
                    <div className="content-of-image-box-of-form-element-image-uploader-container">

                        {justUploadedImg && !isFileReadyToUpload && (
                            <div className="image-cropper-dilog-box">
                                <div className="content-of-image-cropper-dilog-box">

                                    <div className="image-uploader-cropper-container">
                                        <div className="content-of-image-uploader-cropper-container">
                                            <Cropper
                                                image={justUploadedImg}
                                                aspect={currentAspectRatio}
                                                crop={crop}
                                                zoom={zoom}
                                                onCropChange={setCrop}
                                                onZoomChange={setZoom}
                                                onCropComplete={onCropComplete}
                                                style={{
                                                    containerStyle: {
                                                    width: "100%",
                                                    height: "80%",
                                                    backgroundColor: "#fff",
                                                    },
                                                }}
                                                />
                                        </div>
                                    </div>

                                    <div className="buttons-box-of-image-cropper-dilog-box">
                                        <div className="content-of-buttons-box-of-image-cropper-dilog-box">
                                            <Button onClick={() => cancelCrop()}>Don't Crop</Button>
                                            <Button variant='outlined' onClick={() => croppedArea && onCropDone(croppedArea)}>Done</Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}

                        <img src={uploadedImg ? uploadedImg : NoThumb} />
                    </div>
                </div>

                <div className="buttons-container-of-form-element-image-uploader-container">
                    <div className="content-of-buttons-container-of-form-element-image-uploader-container">

                        <LoadingButton loading={loading} startIcon={<GenerateIcon icon="add" />} onClick={() => openImageUploader()} variant="outlined">
                            Upload
                        </LoadingButton>

                        <LoadingButton disabled={loading} startIcon={<GenerateIcon icon="delete" />} onClick={() => deleteImage()}>
                            Delete
                        </LoadingButton>

                    </div>
                </div>  
            
                {messageBoxDetails.display === true && (
                    <div className="message-box-of-form-element-image-uploader-container">
                        <div className="content-of-message-box-of-form-element-image-uploader-container">
                            <Alert severity={messageBoxDetails.type}>
                                {messageBoxDetails.title}
                            </Alert>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}