import { Skeleton } from '@mui/material'
import React from 'react'

interface Props {
    label: string
}

export default function LoadingFieldElement(props: Props) {
    
    const { label } = props

    return(
        <div className="loading-form-element-skeleon-box">
            <div className="content-of-loading-form-element-skeleon-box">
                <span className="fake-label">{label}</span>
                <span className="fake-placeholder">Loading...</span>
                <Skeleton variant="rectangular" />
            </div>
        </div>
    )
}