import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "../../../app/store";
import { ErrorPageDetails, ErrorPageState } from "./Interfaces";

const initialState: ErrorPageState = {
    details: {
        show: false,
        title: "Ooops...",
        message: "Something went wrong",
        status: 500,
        buttons: []
    }
}
const errorPageSlice = createSlice({
    name: "errorPageSlice",
    initialState,
    reducers: {
        updateErrorPageDetails: (state: ErrorPageState, action: PayloadAction<ErrorPageDetails>) => {
            state.details = action.payload
        }
    }
})

/**
 * Return details
 */
 export const returnErrorPageDetails = () => {
    const state = store.getState()
    return state.errorPage.details
}


export const { updateErrorPageDetails } = errorPageSlice.actions
export default errorPageSlice.reducer