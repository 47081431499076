import moment from "moment"

/**
 * Date and time
 */
export default class DateAndTime{

    timezone: string = "Asia/Bahrain"
    locale: string = "en-US"
    dateFormat: string = "MMMM Do, YYYY"
    timeFormat: string = "h:mm a"
    serverDateAndTimeFormat: string = "YYYY-MM-DD HH:mm:ss"
    serverDateAndFormatOnly: string = "YYYY-MM-DD"
    dateAndTimeFormat: string = this.dateFormat + ", " + this.timeFormat

    /**
     * Get Current date
     */
    getCurrentDate = () => {
        return new Date().toLocaleString(this.locale, {
            timeZone: this.timezone,
        })
    }

    /**
     * Format date 
     */
    formatDate = (date: string) => moment(date).format(this.dateFormat)

    /**
     * Format date and time
     */
    formatDateAndTime = (date: string) => moment(date).format(this.dateAndTimeFormat)

    /**
     * Format time
     */
    formatTime = (date: string) => moment(date).format(this.timeFormat)

    /**
     * Format to server standard
     */
    formatToServerStandart = (date: string) => moment(date).format(this.serverDateAndTimeFormat)


    /**
     * Format to server standard
     */
    formatToServerStandartDateOnly = (date: string) => moment(date).format(this.serverDateAndFormatOnly)

}