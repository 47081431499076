import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, store } from "../../../app/store"
import { FolderInterface } from "./ChipsApp"
import { useSelector } from "react-redux"

interface FoldersSliceInitialStateInterface {
    folders: Array<FolderInterface>,
    loading: boolean
}

const initialState: FoldersSliceInitialStateInterface = {
    folders: [],
    loading: true
}

const folderSlice = createSlice({
    name: "folders",
    initialState,
    reducers: {
        addFolders: (state: FoldersSliceInitialStateInterface, action: PayloadAction<Array<FolderInterface>>) => {
            const payload = action.payload
            state.folders = payload
        },
        addNewFolder: (state: FoldersSliceInitialStateInterface, action: PayloadAction<FolderInterface>) => {
            const payload = action.payload
            let folders: Array<FolderInterface> = []

            folders.push(payload)
            
            state.folders.map(folder => folders.push(folder))
            state.folders = folders
        },
        updateFolder: (state: FoldersSliceInitialStateInterface, action: PayloadAction<FolderInterface>) => {
            const payload = action.payload

            let folders: Array<FolderInterface> = [
                ...state.folders
            ]
            const index = folders.map(f => f.id).indexOf(payload.id)

            if (folders[index]){
                folders[index] = payload
            }

            state.folders = folders
            
        },
        updateFolderLoadingStatus: (state: FoldersSliceInitialStateInterface, action: PayloadAction<boolean>) => {
            const payload = action.payload
            state.loading = payload
        },
        deleteFolderById: (state: FoldersSliceInitialStateInterface, action: PayloadAction<number>) => {

            let folders = state.folders
            folders = folders.filter(item => item.id !== action.payload)

            state.folders = folders
        },
    }
})


/**
 * Return folders
 */
export const useReturnFolders = () => useSelector((state: RootState) => state.folders.folders)

/**
 * Return folders loading
 */
export const useReturnFoldersLoading = () => useSelector((state: RootState) => state.folders.loading)

export const { addFolders, updateFolderLoadingStatus, addNewFolder, updateFolder, deleteFolderById } = folderSlice.actions
export default folderSlice.reducer