import React from 'react'
import SetPasswordForm from "./components/SetPasswordForm"

export default function SetPasswordPage() {

    const pathname: string = window.location.pathname

    return(
        <div className="sign-in-page-container">
            <div className="content-of-sign-in-page-container">
                <SetPasswordForm resetPasswordForm={pathname.includes("reset-password") ? true : false} />
            </div>
        </div>
    )
}