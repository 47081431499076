import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SingleArticleInterface } from '../../apps/help_center/HelpArticleApp';
import BaseStructuredPage from "../components/BaseStructuredPage"
import HelpArticleApp from '../../apps/help_center/HelpArticleApp';

import Breadcrumbs from "../components/Breadcrumbs"
import { Skeleton } from '@mui/material';
import NotFoundPage from '../NotFound/NotFoundPage';
import { Helmet } from 'react-helmet';

export default function Article() {

    const articleApp = new HelpArticleApp()

    const params = useParams()
    const articleSlug = params.article_slug ? params.article_slug : ""

    const [loading, setLoading] = useState<boolean>(true)
    const [article, setArticle] = useState<SingleArticleInterface>({
        id: 0,
        title: "",
        slug: "",
        body: "",
        section_id: 0,
        section_name: "",
        section_slug: "",
        thumbnail: "",
        is_active: false,
        date_created: ""
    })

    const [notFound, setNotFound] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    /**
     * Load artcile details
     */
    const loadArticleDetails = () => {
        setLoading(true)
        articleApp.getSingleArticleDetailsBySlug(articleSlug)
            .then(response => {
                setArticle(response.data)
                setLoading(false)
            })
            .catch(error => {

                const { status, statusText, data } = error.response
                const message = data.message ? data.message : statusText

                switch(status){

                    case 404:
                        setNotFound(true)
                        break

                    default:
                        setErrorMessage(message)
                }

            })
    }

    useEffect(() => {
        loadArticleDetails()
    }, [])

    if (notFound){
        return <NotFoundPage page={{
            name: "",
            title: "",
            path: "",
            component: Article,
            isUserAuthorizedContent: false
        }} />
    }

    return(
        <BaseStructuredPage>
            <Helmet>
                {article.title && <title>{article.title} - Help Center</title>}
            </Helmet>
            <div className="single-article-page-container">
                <div className="content-of-single-article-page-container content-section-wrapper">

                    <div className="intro-section-home-page">
                        <div className="content-of-intro-section-home-page content-section-wrapper">

                            <div className="text-box-of-intro-section-home-page" style={{ width: "100%" }}>
                                    <div className="content-of-text-box-of-intro-section-home-page" style={{ width: "100%" }}>

                                        <h1>
                                            {article.title ? article.title : <Skeleton variant="text" width={100} />}
                                        </h1>

                                        {article.title &&   <Breadcrumbs breadcrumbs={[
                                            {
                                                title: "Help",
                                                link: "/help",
                                                index: 0,
                                                total: 0
                                            },
                                            {
                                                title: article.section_name,
                                                link: "/help/sections/" + article.section_slug,
                                                index: 0,
                                                total: 0
                                            },
                                            {
                                                title: article.title,
                                                index: 0,
                                                total: 0
                                            }
                                        ]} />}

                                    </div>
                                </div>

                        </div>
                    </div>

                    <div className="body-of-single-article-page-container">
                        <div className="content-of-body-of-single-article-page-container content-section-wrapper">
                            {loading ? <Skeleton variant="rectangular" width="100%" height={500} /> : <div dangerouslySetInnerHTML={{ __html: article.body }} />}
                        </div>
                    </div>

                </div>
            </div>
        </BaseStructuredPage>
    )
}