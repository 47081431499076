import React from "react"
import ProfileMenuItem from "./ProfileMenuItem"
import { ProfilePageSectionInterface, profilePageSections } from "./ProfilePage"

export default function ProfileMenu() {
    return(
        <div className="admin-panel-menu-navigation">
            <div className="content-of-admin-panel-menu-navigation">
                {profilePageSections.map((item: ProfilePageSectionInterface, index: number) => <ProfileMenuItem icon={item.icon} name={item.name} title={item.title} key={index} />)}
            </div>
        </div>
    )
}