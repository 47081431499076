import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"

export interface AuthState {
    accessTokenRefreshAt: string
}

const initialState: AuthState = {
    accessTokenRefreshAt: ""
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        
    }
})

export default authSlice.reducer