import React from 'react'
import SectionDefaultForm from './SectionDefaultForm'

export default function AddNewAdminPage() {
    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">
                <SectionDefaultForm />
            </div>
        </div>
    )
}