import { env } from "process"

/**
 * Update Page Title
 */
const updatePageTitle = (title: string) => {
    const appName = process.env.REACT_APP_NAME
    document.title = `${title} - ${appName}`
}

export default updatePageTitle