import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { User } from '../../Api/Interfaces'
import ProfileSidebar from "./ProfileSidebar"
import ProfileContent from "./ProfileContent"

/**
 * Sections
 */
import ProfileDetailsSection from "./Sections/ProfileDetailsSection"
import { Grid, IconButton } from '@mui/material'
import BaseStructuredPage from '../components/BaseStructuredPage'
import GenerateIcon from '../../FormElements/GenerateIcon'
import ProfileUpdatePasswordSection from "./Sections/ProfileUpdatePasswordSection"

import ProfileLoginSessions from "./Sections/ProfileLoginSessions"

/**
 * Profile sections
 */
export interface ProfilePageSectionInterface {
    name: string,
    title: string,
    description: string,
    icon: string,
    component: React.FC<{ user: User }>,
    disableHeadTitle?: boolean
}

export const profilePageSections: Array<ProfilePageSectionInterface> = [
    {
        name: "details",
        title: "Details",
        description: "",
        icon: "user",
        component: ProfileDetailsSection
    },
    {
        name: "login-sessions",
        title: "Login Sessions",
        description: "",
        icon: "info",
        component: ProfileLoginSessions
    },
    {
        name: "change-password",
        title: "Change Password",
        description: "",
        icon: "password",
        component: ProfileUpdatePasswordSection
    }
]

/**
 * Back to section page
 */
export const backToProfileSectonPage = (title: string, link: string) => {
    return(
        <div className="back-to-profile-section-page-box">
            <div className="content-of-back-to-profile-section-page-box">
                <a href={link}>
                    <h3>
                        <IconButton><GenerateIcon icon="back" /></IconButton>
                        {title}
                    </h3>
                </a>
            </div>
        </div>
    )
}

/**
 * ProfilePage
 */
export default function ProfilePage() {

    const user = useSelector((state: RootState) => state.user).currentUser

    return(
        <BaseStructuredPage>
            <div className="user-profile-page-container">
                <div className="content-of-user-profile-page-container content-section-wrapper">

                    <Grid container spacing={2}>

                        <Grid item xs={3}>
                            <ProfileSidebar user={user} />
                        </Grid>

                        <Grid item xs={9}>
                            <ProfileContent user={user} />
                        </Grid>

                    </Grid>

                </div>
            </div>
        </BaseStructuredPage>
    )
}