import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementLoadingStatus, useReturnSingleFormElementData, updateElementDisabledStatus } from '../../../FormElements/formsDataSlice'
import { addNewForm } from '../../../FormElements/formsSlice'
import GenerateForm from '../../../FormElements/GenerateForm'
import AppLogo from "../../../../assets/svg/hitap-logo.svg"
import Authentication from '../../../Api/Authentication/Authentication'
import Api from '../../../Api/Api'
import FormValidator from '../../../FormElements/FormValidator'
import { useLocation } from 'react-router-dom'
import TwoFactorAuthLogin from "./TwoFactorAuthLogin"
import { resetReCaptcha } from '../../../FormElements/Elements/Recaptcha'

export default function LoginForm() {

    const dispatch = useDispatch()

    const { search } = useLocation()
    const parameters = new URLSearchParams(search)

    const backLink = parameters.get("back") 

    const formDetails = {
        name: "sign_in_form",
        elemPrefix: "sign_in_form_elem_"
    }

    const elemPrefix = formDetails.elemPrefix

    const [showTwoFactorAuthForm, setShowTwoFactorAuthForm] = useState<boolean>(false)

    const [twoAuthAlertType, setTwoAuthAlertType] = useState<"info" | "error" | "success">("info")
    const [twoAuthAlertMessage, setTwoAuthAlertMessage] = useState<string>("")

    const [twoFactorAuthButtonLoading, setTwoFactorAuthButtonLogin] = useState<boolean>(false)

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value

    const getSingleFormElementLoading = (name: string) => returnSingleFormElementData(elemPrefix + name).loading
    
    /**
     * Submit form
     */
    const HandleSubmitForm = (opt?: number) => {
        updateButtonLoadingStatus(true)
        setTwoFactorAuthButtonLogin(true)
        // setTimeout(() => updateButtonLoadingStatus(false), 1000)

        const email: string = getSingleFormElementValue("email")
        const password: string = getSingleFormElementValue("password")

        const recaptcha = getSingleFormElementValue("recaptcha")

        /**
         * Authenticate user
         */
        const auth = new Authentication(email, password, opt, recaptcha)

        /**
         * Form Validator
         */
        const formValidator = new FormValidator([elemPrefix + "email", elemPrefix + "password"])

        if (formValidator.isValid().valid){
            /**
             * Send request to API
             */
            auth.authenticateUser()
                .then(response => {
                    
                    const { status, statusText, data } = response

                    switch(status){

                        case 200:
                            const message = data.message! ? data.message : statusText
                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: message,
                                    severity: "success"
                                })
                            )

                            setTwoAuthAlertType("success")
                            setTwoAuthAlertMessage(message)
                            setTwoFactorAuthButtonLogin(false)

                            let redirectTo = "/profile"

                            if (data.is_admin){
                                redirectTo = "/panel/digitalcards"
                            }

                            if (backLink){
                                redirectTo = backLink
                            }

                            setTimeout(() => window.location.replace(redirectTo), 1500)
                            break

                    }

                    updateButtonLoadingStatus(false)
                    updateButtonDisabledStatus(true)

                })
                .catch(error => {
                    updateButtonLoadingStatus(false)
                    
                    const response = error.response
                    const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                    Api.log(response)

                    resetReCaptcha(dispatch, elemPrefix + "recaptcha")

                    if (showTwoFactorAuthForm){
                        setTwoAuthAlertType("error")
                        setTwoAuthAlertMessage(message)
                    }
                    
                    switch(response.status){

                        case 401:
                            setShowTwoFactorAuthForm(true)
                            break

                        default:
                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: message,
                                    severity: "error"
                                })
                            )
                    }

                    setTwoFactorAuthButtonLogin(false)
                
                })
        }else{
            updateButtonLoadingStatus(false)
            setTwoFactorAuthButtonLogin(false)
            formValidator.showRequiredFields(dispatch)
        }



    }

    /**
     * Use Effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
        dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "email",
                        label: "Email",
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "password",
                        xs: 12,
                        id: elemPrefix + "password",
                        label: "Password",
                        submitFormOnPressingEnter: true,
                        fullWidth: true
                    },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "ReCaptcha",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "recaptcha"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: "Sign In",
                        fullWidth: true,
                        onClick: () => {},
                        isSubmit: true
                    },
                    {
                        type: "Link",
                        xs: 6,
                        label: "Forgot password?",
                        link: "/reset-password",
                        id: elemPrefix + "forgot-password"
                    },
                    {
                        type: "Link",
                        xs: 6,
                        label: "Set password",
                        link: "/set-password",
                        id: elemPrefix + "set-password"
                    },
                ]
            })
        )

        /**
         * Add new form elements data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "email",
                    value: ""
                },
                {
                    name: elemPrefix + "password",
                    value: ""
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
                {
                    name: elemPrefix + "submit",
                    value: ""
                }
            ])
        )
    }, [])

    /**
     * Submit two auth form
     */
    const handleSubmitTwoAuthForm = (opt: number) => {
        HandleSubmitForm(opt)
    }

    return(
        <>
            <TwoFactorAuthLogin display={showTwoFactorAuthForm} alertType={twoAuthAlertType} alertMessage={twoAuthAlertMessage} submitButtonLoading={twoFactorAuthButtonLoading} handleSubmit={handleSubmitTwoAuthForm} />
            <div className="login-form-container" style={{ display: showTwoFactorAuthForm ? "none": "block" }}>
                <div className="content-of-login-form-container">

                    <div className="app-logo-of-login-form-container">
                        <div className="content-of-app-logo-of-login-form-container">
                            <a href="/">
                                <img src={AppLogo} />
                            </a>
                        </div>
                    </div>

                    <div className="actual-form-of-login-form-container">
                        <GenerateForm name={formDetails.name} handleSubmitForm={HandleSubmitForm} />
                    </div>

                </div>
            </div>
        </>
    )
}