import { Button, Grid, IconButton, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CardsApp, { SingleCardInterface, SingleCardLinkInterface } from './CardsApp'
import { returnCardBuilderDetails, updateCardDetailsInBuilder, useReturnCardDetailsFromBuilder, useReturnCardLinksFromBuilder, updateMissingFieldsInBuilder, useReturnMissingFieldsFromBuilder, addManyLinks, updateHideCompanyName, updateHidePosition } from './cardBuilderSlice'
import SingleCard from './SingleCard'
import { NGHCStyles } from './SingleCardPage'
import { returnCurrentUser } from '../../Api/User/userSlice'
import { useDispatch } from 'react-redux'
import BasicInformationForm from "./BasicInformationForm"
import LinksCardBuilder from "./LinksCardBuilder"
import { AxiosDefaultDataObject } from '../../Api/Interfaces'
import { LoadingButton } from '@mui/lab'
import GenerateMessageBox, { MessageBoxTypes } from '../../FormElements/GenerateMessageBox'
import { useParams } from 'react-router-dom'
import { updateLoadingStatus } from '../../PagePreloader/pagePreloaderSlice'
import SelectTemplate from './SelectTemplate'
import GenerateIcon from '../../FormElements/GenerateIcon'

import { Helmet } from "react-helmet";

interface Props {
    update?: boolean,
    cardId?: string
}

export default function CardBuilderPage(props: Props) {

    const windowWidth = window.innerWidth

    const cardDetails: SingleCardInterface = useReturnCardDetailsFromBuilder()
    const cardLinks: Array<SingleCardLinkInterface> = useReturnCardLinksFromBuilder()
    const dispatch = useDispatch()
    const params = useParams()
    const currentUser = returnCurrentUser()
    const cardsApp = new CardsApp()
    const missingFieldsFromBuilder = useReturnMissingFieldsFromBuilder()

    const [submitButtonLoading, setSubmitButtonLoading] = useState<boolean>(false)
    const [submitButtonDisabledStatus, setSubmitButtonDisabledStatus] = useState<boolean>(false)
    const [loadingCardDetails, setLoadingCardDetails] = useState<boolean>(false)

    const cardUniqueName: string = props.cardId ? props.cardId : params.id ? params.id : ""

    const [isFormDone, setIsFormDone] = useState<boolean>(false)

    const [hideEditor, setHideEditor] = useState<boolean>(false)

    /**
     * Submit Request Response Text
     */
    interface ISubmitRequestResponse {
        type: MessageBoxTypes,
        title: string,
        message: string,
        display: boolean
    }
    const [submitRequestResponse, setSubmitRequestResponse] = useState<ISubmitRequestResponse>({
        type: "info",
        title: "No title",
        message: "No message",
        display: false
    })

    /**
     * Update card details with user details
     */
    const updateCardDetailsWithCurrentUserDetails = () => dispatch(updateCardDetailsInBuilder({
        id: 0,
        title: "",
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        middle_name: "",
        position: "",
        headline: "",
        user_id: currentUser.id ? currentUser.id : 0,
        company_id: currentUser.company_id,
        design_id: 1,
        is_public: true,
        is_active: true,
        unique_name: "",
        thumbnail: currentUser.thumbnail,
        date_created: "",
        primary_color: "#4c78f7",
        company_name: currentUser.company_name,
        company_logo: "",
        background_color: "#f2f2f2",
        background_image: "",
        card_color: "#FFFFFF",
        text_color: "#000000",
        subtext_color: "#919191",
        custom_css: "",
        hide_position: false,
        hide_company_name: false
    }))


    /**
     * Load card details
     */
    const loadCardDetails = () => {
        setLoadingCardDetails(true)

        cardsApp.getSingleCardDetails(cardUniqueName, true)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:
                        
                        setSubmitRequestResponse({
                            display: false,
                            type: "info",
                            message: "",
                            title: ""
                        })

                        if (data.links){
                            if (data.links instanceof Array){
                                if (data.links.length > 0){
                                    console.log("data.links", data.links)
                                    dispatch(addManyLinks(data.links))
                                }
                            }
                        }

                        const cardDetails: SingleCardInterface = data

                        dispatch(updateCardDetailsInBuilder(cardDetails))
                        setLoadingCardDetails(false)

                        break

                }

            })
            .catch(error => {
                cardsApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setSubmitRequestResponse({
                    display: true,
                    type: "error",
                    message: message,
                    title: "Error"
                })
            })

    }

    /**
     * Effect
     */
    useEffect(() => {

        /**
         * Update Card Details
         */
        updateCardDetailsWithCurrentUserDetails()

        /**
         * If page is update
         */
        if (props.update){
            loadCardDetails()
        }
        

    }, [])

    // useEffect(() => {
    //     if (!isFormDone){
    //         window.addEventListener("beforeunload", (ev) => 
    //         {  
    //             ev.preventDefault();
    //             return ev.returnValue = 'Are you sure you want to close the page?';
    //         })
    //     }else{
    //         window.removeEventListener('beforeunload', (e) => {})
    //     }
    // }, [isFormDone])

    /**
     * Update missing fields
     */
    const updateMissingFields = (fields: Array<string>) => dispatch(updateMissingFieldsInBuilder(fields))

    /**
     * Check required fields
     */
    const checkRequiredFields = () => {
        let missingFields: Array<string> = []
        const requiredFields: Array<string> = cardsApp.requiredFields

        if (requiredFields.length > 0){
            requiredFields.map(field => {
                Object.entries(cardDetails).map(([k, v]) => {
                    if (k === field){
                        if (v === ""){
                            missingFields.push(k)
                        }
                    }
                })
            })
        }

        updateMissingFields(missingFields)

        if (missingFields.length > 0){
            window.scrollTo(0, 0)
            return true
        }

        return false

    }

    /**
     * Handle submit
     */
    const handleSubmit = () => {

        let newLinks: Array<SingleCardLinkInterface> = []

        if (cardLinks){
            cardLinks.map((singleLink: SingleCardLinkInterface) => {
                newLinks.push({
                    ...singleLink,
                    // url: singleLink.type === "editor" ? cardsApp.encodeEditor(singleLink.url) : singleLink.url
                })
            })
        }

        let data: AxiosDefaultDataObject = {
            title: cardDetails.title,
            first_name: cardDetails.first_name,
            last_name: cardDetails.last_name,
            middle_name: cardDetails.middle_name,
            position: cardDetails.position,
            headline: cardDetails.headline,
            design_id: cardDetails.design_id,
            primary_color: cardDetails.primary_color,
            is_public: cardDetails.is_public,
            user_id: cardDetails.user_id,
            company_id: cardDetails.company_id,
            thumbnail: cardDetails.thumbnail,
            background_color: cardDetails.background_color,
            company_logo: cardDetails.company_logo,
            background_image: cardDetails.background_image,
            links: newLinks,
            update: props.update ? true : false,
            card_id: props.update ? cardDetails.id : false,
            card_color: cardDetails.card_color,
            text_color: cardDetails.text_color,
            subtext_color: cardDetails.subtext_color,
            custom_css: cardDetails.custom_css,
            hide_position: cardDetails.hide_position,
            hide_company_name: cardDetails.hide_company_name
        }



        setSubmitButtonLoading(true)

        cardsApp.createNewInstance(data)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:

                        setIsFormDone(true)
                        
                        setSubmitRequestResponse({
                            display: true,
                            type: "success",
                            message: `The card has been successfully ${props.update ? "updated" : "created"}!`,
                            title: "Success!"
                        })

                        setTimeout(() => {
                            window.location.replace(props.update ? "/panel/digitalcards" : "/panel/digitalcards" )
                        }, 1500)

                        break

                }

                setSubmitButtonLoading(false)
                setSubmitButtonDisabledStatus(true)

            })
            .catch(error => {
                cardsApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setSubmitRequestResponse({
                    display: true,
                    type: "error",
                    message: message,
                    title: "Error"
                })
                setSubmitButtonLoading(false)
            })

    }

    /**
     * Handle click on submit
     */
    const handleClickOnSubmit = () => {
        if (!checkRequiredFields()){
            handleSubmit()
        }
    }

    /**
     * Create or update button container
     */
    const renderCreateOrUpdateButtonContainer = () => {

        return (
            <div className="create-or-update-container-of-card-builder-page-container">
                <div className="content-of-create-or-update-container-of-card-builder-page-container">
                    <LoadingButton disabled={submitButtonDisabledStatus} loading={submitButtonLoading} variant="contained" onClick={handleClickOnSubmit}>{props.update ? "Save" : "Create"}</LoadingButton>
                    <Button style={{ marginLeft: 5 }} href="/panel/digitalcards">Cancel</Button>
                    {windowWidth <= 1024 && <Button style={{ marginLeft: 5 }} onClick={() => setHideEditor(true)}>Show Card</Button>}
                </div>
            </div>
        )
    }

    /**
     * Generate Response Message
     */
    const generateResponseMessage = () => {
        if (!submitRequestResponse.display){
            return <></>
        }

        return (
            <div className="request-response-message-container">
                <div className="content-of-request-response-message-container">
                    <GenerateMessageBox type={submitRequestResponse.type} title={submitRequestResponse.title} message={submitRequestResponse.message} />
                </div>
            </div>
        )
    }

    const [customCss, setCustomCss] = useState<string>("")

    useEffect(() => {
        setCustomCss(cardDetails.custom_css ? cardDetails.custom_css : "")
    }, [cardDetails.custom_css])

    /**
     * Render loading information skeleton
     */
    const renderLoadingInformationSkeleton = () => {

        if (submitRequestResponse.display){
            return generateResponseMessage()
        }

        return (
            <div>
                <Skeleton variant="text" width="100%" height={80} />
                <Skeleton variant="text" width="100%" height={80} />
                <Skeleton variant="text" width="100%" height={80} />
                <Skeleton variant="text" width="100%" height={80} />
                <Skeleton variant="text" width="100%" height={80} />
                <Skeleton variant="text" width="100%" height={80} />
                <br />
                <Skeleton variant="text" width={50} height={20} />
                <Skeleton variant="text" width={150} height={20} />
                <br />
                <Skeleton variant="rectangular" width="100%" height={450} />
                <br />
                <Skeleton variant="text" width={100} height={80} />
            </div>
        )
    }

    return(
        <>
            <Helmet>
                {cardDetails.company_name === "Neom Green Hydrogen Company" && NGHCStyles}
            </Helmet>

            <div className="card-builder-page-container">
                <div className="content-of-card-builder-page-container">

                    <div className={hideEditor ? "left-side-of-card-builder-page-container hide-card-builder" : "left-side-of-card-builder-page-container"}>
                        <div className="content-of-left-side-of-card-builder-page-container">

                            <div className="head-box-of-card-builder-page-container">
                                <div className="content-of-head-box-of-card-builder-page-container">

                                        <IconButton href="/panel/digitalcards">
                                            <GenerateIcon icon="back" />
                                        </IconButton>

                                        <h3>{props.update ? `Update: ${cardDetails.title}` : "Create a new card"}</h3>

                                </div>
                            </div>
                            
                            <div className="card-builder-form-container">
                                <div className="content-of-card-builder-form-container">
                                    {loadingCardDetails ? renderLoadingInformationSkeleton() : (
                                        <>
                                            <BasicInformationForm checkRequiredFields={checkRequiredFields} />
                                            <LinksCardBuilder update={props.update} />
                                            {generateResponseMessage()}
                                            {renderCreateOrUpdateButtonContainer()}
                                        </>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="right-side-of-card-builder-page-container" style={{ background: cardDetails.background_color, backgroundImage: `url(${cardDetails.background_image})` }}>
                        <div className="content-of-right-side-of-card-builder-page-container">
                            <div className="actual-card-of-card-builder-page-container">
                                <div className="content-of-actual-card-of-card-builder-page-container">
                                    <SingleCard {...cardDetails} loading={loadingCardDetails} customLinks={cardLinks} showCustomLinks={true} />
                                    {windowWidth <= 1024 && hideEditor && <div className="show-editor-container-card-builder">
                                        <div className="content-of-show-editor-container-card-builder">
                                            <div className="add-to-contacts-card-button">
                                                <div className="content-of-add-to-contacts-card-button">
                                                    <LoadingButton onClick={() => setHideEditor(false)} style={{ backgroundColor: cardDetails.primary_color }}>
                                                        Show Editor
                                                    </LoadingButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}