export default class Api{

    static isInProduction: boolean = true

    /**
     * Return base url
     */
    static returnBaseUrl = () => "https://hitapdigital.com/api/"

    /**
     * Log response
     */
    static log = (...args: any) => {
        if (!Api.isInProduction){
            console.log(...args)
        }
    }

}