import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, IconButton, Skeleton, TextField } from '@mui/material'
import ChipsByFolder from "./ChipsByFolder"
import { FolderInterface } from './ChipsApp';
import GenerateIcon from '../../FormElements/GenerateIcon';
import { useDispatch } from 'react-redux';
import { deleteFolderById, updateFolder } from './foldersSlice';
import FoldersApp from './FoldersApp';
import { LoadingButton } from '@mui/lab';
import { deleteLinkById } from '../cards/cardBuilderSlice';

interface Props extends FolderInterface {

}

export default function SingleFolder(props: Props) {

    const { title, id } = props

    const foldersApp = new FoldersApp()

    const [expanded, setExpanded] = React.useState<boolean>(false);

    // const handleChange =
    //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    //     setExpanded(isExpanded ? panel : false);
    // }

    const [folderTitle, setFolderTitle] = useState<string>(title)
    const [enableEditing, setEnableEditing] = useState<boolean>(false)
    const [updatingTitle, setUpdatingTitle] = useState<boolean>(false)
    const [helperText, setHelperText] = useState<string>("")

    const [sureToDelete, setSureToDelete] = useState<boolean>(false)
    const [loadingDelete, setLoadingDelete] = useState<boolean>(false)

    const dispatch = useDispatch()

    /**
     * Update title
     */
    const updateTitle = (title: string) => {
        let folder: FolderInterface = {
            ...props
        }
        folder.title = title

        dispatch(updateFolder(folder))
    }

    /**
     * Handle update folder
     */
    const handleUpdateFolder = () => {
        setUpdatingTitle(true)

        let data = {
            title: folderTitle,
            update: true,
            folder_id: id
        }

        foldersApp.createNewInstance(data)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:
                        updateTitle(folderTitle)
                        setEnableEditing(false)
                        setUpdatingTitle(false)
                        break

                }

            })
            .catch(error => {
                foldersApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setHelperText(message)
                setUpdatingTitle(false)
            })

    }

    /**
     * Handle Delete
     */
    const handleDeleteFolder = () => {
        setLoadingDelete(true)

        foldersApp.deleteInstance(id)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:
                        dispatch(deleteFolderById(id))
                        setLoadingDelete(false)
                        setSureToDelete(false)
                        break

                }

            })
            .catch(error => {
                foldersApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                alert(message)
            })
    }

    /**
     * Handle key up
     */
    const handleOnKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (folderTitle){
            if (event.key === "Enter"){
                handleUpdateFolder()
            }
        }

        if (event.key === "Escape"){
            setEnableEditing(false)
        }
    }

    return(
        <Accordion expanded={expanded}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
            <div className="folder-header-box">
                    <div className="content-of-folder-header-box">
                        <IconButton style={{ marginRight: 5, marginTop: -5 }} ><GenerateIcon icon={expanded ? "folder-open" : "folder"} /></IconButton>

                        <Typography style={{ minWidth: 300 }} onClick={() => setExpanded(expanded ? false : true)}>
                            {title}
                            <IconButton className="edit-folder-name-icon-button" onClick={() => setEnableEditing(true)}>
                                <GenerateIcon icon="edit" />
                            </IconButton>
                        </Typography>

                      

                        <Button href={"/panel/chips/new?folder_id=" + id} style={{ marginLeft: 10, marginTop: -2 }}>new NFC chip</Button>
                        
                        {/* <LoadingButton loading={loadingDelete} style={{ marginTop: -2 }} color='error' onClick={() => {
                            
                            if (!sureToDelete){
                                setSureToDelete(true)
                            }else{
                                handleDeleteFolder()
                            }

                        }}>{sureToDelete ? "Confirm?" : "Delete"}</LoadingButton>
                        {sureToDelete && <Button disabled={loadingDelete} color='success' onClick={() => setSureToDelete(false)}>No, keep it</Button>} */}

                        {enableEditing && (
                             <div className="edit-folder-input-box">
                                <div className="content-of-edit-folder-input-box">
                                    <TextField disabled={updatingTitle} onKeyUp={handleOnKeyUp} id="outlined-basic" label="Title" variant="outlined" value={folderTitle} onChange={(e) => setFolderTitle(e.target.value)} fullWidth error={helperText ? true : false} helperText={helperText} />
                                </div>
                            </div>
                        )}
                    </div>
            </div>
            
            </AccordionSummary>

            <AccordionDetails>
                {expanded && <ChipsByFolder folderId={id} />}
            </AccordionDetails>

        </Accordion>
    )
}