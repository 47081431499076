import React from 'react'
import { updateDesignTemplateId, useReturnCardDetailsFromBuilder } from './cardBuilderSlice'
import { useDispatch } from 'react-redux'
import CardsApp, { CardTemplateInterface } from './CardsApp'

export default function SelectTemplate() {

    const cardDetails = useReturnCardDetailsFromBuilder()
    const dispatch = useDispatch()
    const cardsApp = new CardsApp()

    /**
     * Handle update design template id
     */
    const handleUpdateDesignTemplate = (id: number) => dispatch(updateDesignTemplateId(id))

    /**
     * Render single card template
     */
    const renderSingleCardTemplate = (template: CardTemplateInterface, index: number) => {

        let className: string = "single-card-template-box"

        if (cardDetails.design_id === template.id){
            className = className + " active"
        }

        return(
            <div className={className} key={index} onClick={() => handleUpdateDesignTemplate(template.id)}>
                <div className="content-of-single-card-template-box">
                    <h3>{template.title}</h3>
                </div>
            </div>
        )
    }

    return(
        <div className="select-template-container">
            <div className="content-of-select-template-container">

                <div className="card-builder-title-box">
                    <div className="content-of-card-builder-title-box">
                        <h3>Select template</h3>
                    </div>
                </div>

                <div className="all-card-templates-boxes">
                    <div className="content-of-all-card-templates-boxes">
                        {cardsApp.templates.map((template: CardTemplateInterface, index: number) => renderSingleCardTemplate(template, index))}
                    </div>
                </div>

            </div>
        </div>
    )
}