import React, { useEffect, useState } from 'react'
import Api from '../Api/Api'
import User from '../Api/User/User'
import { IsUserLoggedInResponse } from "../Api/Interfaces"
import { useDispatch } from 'react-redux'
import { updateLoadingStatus } from "../PagePreloader/pagePreloaderSlice"
import { returnCurrentUser, updateUser } from '../Api/User/userSlice'
import { User as UserInterface } from "../Api/Interfaces"
import { ErrorPageButton } from './Error/Interfaces'
import { updateErrorPageDetails } from './Error/errorPageSlice'
import PagePreloader from '../PagePreloader/PagePreloader'
import SetUpTwoFactorAuth from "../Api/Authentication/SetUpTwoFactorAuth"

interface Props {
    onlyAdmin?: boolean,
    accessDeniedMessage?: string
}

export default function UserAuthorizedPage(props: React.PropsWithChildren<Props>) {
    const dispatch = useDispatch()
    const user = new User()

    const [userLoaded, setUserLoader] = useState<boolean>(false)

    /**
     * Update user details
     */
    const updateUserDetails = (user: UserInterface) => {
        dispatch(
            updateUser({
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                is_active: user.is_active,
                is_admin: user.is_admin,
                is_superuser: user.is_superuser,
                is_password_set: user.is_password_set,
                events_access: user.events_access,
                thumbnail: user.thumbnail,
                date_joined: user.date_joined,
                isDetailsLoaded: true,
                id: user.id,
                phone: user.phone,
                company_id: user.company_id,
                company_name: user.company_name
            })
        )
    }

    /**
     * Show two factor auth page
     */
    const [showTwoFactorAuthPage, setShowTwoFactorAuthPage] = useState<boolean>(false)
    
    /**
     * Effect
     */
    useEffect(() => {

        updatePagePreloaderStatus(true)
        
        checkIfAccessTokenValid()

        user.isLoggedIn()
            .then((response: IsUserLoggedInResponse) => {
                
                if (response.isLoggedIn){
                    user.getCurrentUserDetails()
                        .then(response => {

                            const { status, statusText, data } = response

                            switch(status){

                                case 200:
                                    const userData: UserInterface = data
                                    updateUserDetails(userData)
                                    setUserLoader(true)

                                    if (props.onlyAdmin){
                                        if (!userData.is_admin){
                                            dispatch(
                                                updateErrorPageDetails({
                                                    show: true,
                                                    title: "Access Denied",
                                                    message: props.accessDeniedMessage ? props.accessDeniedMessage : "You don't have the access to view this page. Only admins can view it",
                                                    status: 403,
                                                    buttons: [
                                                        {
                                                            label: "Home",
                                                            link: "/",
                                                            variant: "outlined"
                                                        },
                                                        {
                                                            label: "Sign out",
                                                            link: "/logout",
                                                            variant: "text"
                                                        }
                                                    ]
                                                })
                                            )
                                        }
                                    }

                                    break;

                            }

                            updatePagePreloaderStatus(false)

                        })
                        .catch(error => {
                            const { response } = error
                            let message = response.statusText
    
                            if (response.data!){
                                if (response.data.message!){
                                    message = response.data.message
                                }
                            }

                            switch(response.status){

                                case 403:
                                    setShowTwoFactorAuthPage(true)
                                    break

                                case 409:
                                    window.location.replace("/logout")
                                    break

                                default:
                                    showErrorPage("Ooops...", message, response.status)
                                    setTimeout(() => updatePagePreloaderStatus(false), 1000)
                            }
    
                            user.logResults("error:", error)
                        })
                }else{
                    window.location.replace("/sign-in?back=" + window.location.pathname + window.location.search)
                }

            })
            .catch((error: IsUserLoggedInResponse) => {
                Api.log(error)
                window.location.replace("/sign-in")
                // updatePagePreloaderStatus(false)
            })
    }, [])

    /**
     * Update page preloader status
     */
    const updatePagePreloaderStatus = (status: boolean) => {
        dispatch(
            updateLoadingStatus({
                loading: status
            })
        )
    }

    /**
     * Show error page
     */
    const showErrorPage = (title: string, message: string, status: number, buttons: Array<ErrorPageButton> = []) => {
        dispatch(
            updateErrorPageDetails({
                show: true,
                title,
                message,
                status,
                buttons
            })
        )
    }

    /**
     * Check if token valid every one minute
     */
    const checkIfAccessTokenValid = () => {
        setInterval(() => {
            
            if (!user.validateTokenRefresh()){
                user.refreshAccessToken()
                    .then(response => {
                        // const { status, statusText, data } = response
                        user.logResults("token refreshed")
                        user.updateTokenRefeshedDate(true)
                    })
                    .catch(error => {
                        const { response } = error
                        let message = response.statusText

                        if (response.data!){
                            if (response.data.message!){
                                message = response.data.message
                            }
                        }

                        showErrorPage("Ooops...", message, response.status)
                    })
            }

        }, 5000)
    }

    /**
     * Load two factor auth page
     */
    if (showTwoFactorAuthPage){
        return <SetUpTwoFactorAuth />
    }

    /**
     * Load page
     */

    if (userLoaded){
        return(
            <React.Fragment>
                <PagePreloader />
                {props.children}
            </React.Fragment>
        )
    }else{
        return <><PagePreloader /></>
    }

}