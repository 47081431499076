import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, store } from "../../../app/store"
import { SingleCardInterface, SingleCardLinkInterface } from "./CardsApp"
import { useSelector } from "react-redux"
import { FormElementData, FormElementsData } from "../../FormElements/Interfaces"

interface CardBuilderInitialStateInterface {
    card: SingleCardInterface,
    links: Array<SingleCardLinkInterface>,
    missingFields: Array<string>
}
const initialState: CardBuilderInitialStateInterface = {
    card: {
        id: 0,
        title: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        position: "",
        headline: "",
        user_id: 0,
        company_id: 0,
        design_id: 1,
        is_public: true,
        is_active: true,
        unique_name: "",
        thumbnail: "",
        date_created: "",
        primary_color: "#4f7bf7",
        company_name: "",
        company_logo: "",
        background_color: "#f2f2f2",
        background_image: "",
        card_color: "#FFFFFF",
        text_color: "#000000",
        subtext_color: "#919191"
    },
    links: [],
    missingFields: []
}

const cardBuilderSlice = createSlice({
    name: "cardBuilder",
    initialState,
    reducers: {
        updateCardDetailsInBuilder: (state: CardBuilderInitialStateInterface, action: PayloadAction<SingleCardInterface>) => {
            const payload = action.payload

            state.card = payload
        },
        updateMissingFieldsInBuilder: (state: CardBuilderInitialStateInterface, action: PayloadAction<Array<string>>) => {
            const payload = action.payload

            state.missingFields = payload
        },
        updateTitle: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.title = action.payload
        },
        updateCustomCss: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.custom_css = action.payload
        },
        updateHidePosition: (state: CardBuilderInitialStateInterface, action: PayloadAction<boolean>) => {
            state.card.hide_position = action.payload
        },
        updateHideCompanyName: (state: CardBuilderInitialStateInterface, action: PayloadAction<boolean>) => {
            state.card.hide_company_name = action.payload
        },
        updateFirstName: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.first_name = action.payload
        },
        updateLastName: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.last_name = action.payload
        },
        updateMiddleName: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.middle_name = action.payload
        },
        updatePosition: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.position = action.payload
        },
        updateHeadline: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.headline = action.payload
        },
        updatePrimaryColor: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.primary_color = action.payload
        },
        updateBackgroundColor: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.background_color = action.payload
        },
        updateCardColor: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.card_color = action.payload
        },
        updateTextColor: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.text_color = action.payload
        },
        updateSubTextColor: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.subtext_color = action.payload
        },
        updateBackgroundImage: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.background_image = action.payload
        },
        updateDesignTemplateId: (state: CardBuilderInitialStateInterface, action: PayloadAction<number>) => {
            state.card.design_id = action.payload
        },
        updateThumbnail: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.thumbnail = action.payload
        },
        updateCompanyLogo: (state: CardBuilderInitialStateInterface, action: PayloadAction<string>) => {
            state.card.company_logo = action.payload
        },
        updateIsPublic: (state: CardBuilderInitialStateInterface, action: PayloadAction<boolean>) => {
            state.card.is_public = action.payload
        },
        updateCompanyId: (state: CardBuilderInitialStateInterface, action: PayloadAction<number>) => {
            state.card.company_id = action.payload
        },
        updateUserId: (state: CardBuilderInitialStateInterface, action: PayloadAction<number>) => {
            state.card.user_id = action.payload
        },
        addNewLink: (state: CardBuilderInitialStateInterface, action: PayloadAction<SingleCardLinkInterface>) => {

            let links = state.links
            links.push(action.payload)

            state.links = links
        },
        addManyLinks: (state: CardBuilderInitialStateInterface, action: PayloadAction<Array<SingleCardLinkInterface>>) => {
            state.links = action.payload
        },
        deleteLinkById: (state: CardBuilderInitialStateInterface, action: PayloadAction<number>) => {

            let links = state.links
            links = links.filter(item => item.id !== action.payload)

            state.links = links
        },
        updateLink: (state: CardBuilderInitialStateInterface, action: PayloadAction<SingleCardLinkInterface>) => {

            let links = state.links
            const paylaod = action.payload

            links.map(link => {
                if (link.id === paylaod.id) {
                    
                    let findLink = links.find(singleLink => singleLink.id === paylaod.id)
                    
                    if (findLink){
                        findLink.url = paylaod.url
                        findLink.title = paylaod.title
                        findLink.is_embeded_video = paylaod.is_embeded_video
                    }

                }
            })

            state.links = links
        },
        updateLinksOrder: (state: CardBuilderInitialStateInterface, action: PayloadAction<{ itemId: number, up: boolean, oldOrderNum: number }>) => {
            let links = state.links
            const paylaod = action.payload

            links.map(link => {
                link.order_num = link.order_num - 1
                
                if (link.id === paylaod.itemId){
                    let findLink = links.find(singleLink => singleLink.id === paylaod.itemId)
                    
                    if (findLink){
                        findLink.order_num = paylaod.up ? paylaod.oldOrderNum - 1 : paylaod.oldOrderNum + 1
                    }

                }
            })

            state.links = links
        }
    }
})

/**
 * Return single card details
 */
export const returnCardBuilderDetails = () => {
    const state = store.getState()
    return state.cardBuilder.card
}

export const useReturnCardDetailsFromBuilder = () => useSelector((state: RootState) => {
    return state.cardBuilder.card
})

export const useReturnCardLinksFromBuilder = () => useSelector((state: RootState) => {
    return state.cardBuilder.links
})

export const useReturnMissingFieldsFromBuilder = () => useSelector((state: RootState) => {
    return state.cardBuilder.missingFields
})

export const useReturnCardThumbnailAsFormElementData = () => useSelector((state: RootState) => {
    const object: FormElementData = {
        name: "thumbnail",
        value: state.cardBuilder.card.thumbnail
    }

    return object
})

export const useReturnCardCompanyLogoAsFormElementData = () => useSelector((state: RootState) => {
    const object: FormElementData = {
        name: "thumbnail",
        value: state.cardBuilder.card.company_logo
    }

    return object
})

export const useReturnCardBackgroundImageAsFormElementData = () => useSelector((state: RootState) => {
    const object: FormElementData = {
        name: "thumbnail",
        value: state.cardBuilder.card.background_image
    }

    return object
})

export const { updateCardDetailsInBuilder, updateTitle, updateFirstName, updateLastName, updateMiddleName, updatePosition, updatePrimaryColor, updateHeadline, updateThumbnail, addNewLink, deleteLinkById, updateLink, updateLinksOrder, updateMissingFieldsInBuilder, addManyLinks, updateDesignTemplateId, updateBackgroundColor, updateCompanyLogo, updateBackgroundImage, updateIsPublic, updateCardColor, updateTextColor, updateSubTextColor, updateCompanyId, updateUserId, updateCustomCss, updateHideCompanyName, updateHidePosition } = cardBuilderSlice.actions
export default cardBuilderSlice.reducer