import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { RootState, store } from "../../app/store"
import { FormElementData, FormElementsData, FormSelectSingleItem, UpdateHelperTextFormElementData } from "./Interfaces"

const initialState: FormElementsData = {}

interface ResponseDataObjectInterface {
    [key: string | number]: any
}

const formsDataSlice = createSlice({
    name: "formsData",
    initialState,
    reducers: {
        createOrUpdateElementData: (state: FormElementsData, action: PayloadAction<FormElementData>) => {
            const payload = action.payload
            state[payload.name] = payload
        },
        addManyElementsData: (state: FormElementsData, action: PayloadAction<Array<FormElementData>>) => {
            const payload = action.payload

            if (payload.length > 0){
                payload.map(elementData => state[elementData.name] = elementData)
            }

        },
        updateElementLoadingStatus: (state: FormElementsData, action: PayloadAction<{name: string, loading: boolean}>) => {
            const payload = action.payload

            if (state[payload.name]){
                state[payload.name].loading = payload.loading
            }

        },
        updateElementHiddenStatus: (state: FormElementsData, action: PayloadAction<{name: string, hidden: boolean}>) => {
            const payload = action.payload

            if (state[payload.name]){
                state[payload.name].hidden = payload.hidden
            }

        },
        updateElementDisabledStatus: (state: FormElementsData, action: PayloadAction<{name: string, disabled: boolean}>) => {
            const payload = action.payload

            if (state[payload.name]){
                state[payload.name].disabled = payload.disabled
            }

        },
        updateHelperText: (state: FormElementsData, action: PayloadAction<UpdateHelperTextFormElementData>) => {
            const payload = action.payload

            if (state[payload.name]){
                const data = state[payload.name]
                data.displayHelperText = payload.displayHelperText
                data.helperText = payload.helperText
                data.helperTextType = payload.helperTextType
            }

        },
        updateElementsDataFromInstanceDetailsRequest: (state: FormElementsData, action: PayloadAction<{
            elemPrefix: string,
            instances: Array<ResponseDataObjectInterface>
        }>) => {
            const paylaod = action.payload
            const { elemPrefix } = paylaod

            const instances = Object.entries(paylaod.instances)

            if (instances && instances.length > 0){
                instances.map(([key, value]) => {
                    const elementName = elemPrefix + "_" + key
                    if (state[elementName]){
                        state[elementName].value = value
                    }
                })
            }

        }
    }
})

/**
 * Return single element data
 */
export const useReturnSingleFormElementData = (name: string) => useSelector((state: RootState) => {
    const formsData: FormElementsData = state.formsData
    let elementData: FormElementData = {
        name: "",
        value: ""
    }

    if (formsData){
        if (formsData[name]!){
            elementData = formsData[name]
        }
    }

    return elementData
})

/**
 * Return single element data
 */
export const useReturnFormElementData = () => useSelector((state: RootState) => {
    return state.formsData
})

export const returnSingleFormElementData = (name: string) => {
    const formsData = store.getState().formsData
    let elementData: FormElementData = {
        name: "",
        value: ""
    }

    if (formsData){
        if (formsData[name]!){
            elementData = formsData[name]
        }
    }

    return elementData
}

export const { createOrUpdateElementData, addManyElementsData, updateElementLoadingStatus, updateElementDisabledStatus, updateElementHiddenStatus, updateHelperText, updateElementsDataFromInstanceDetailsRequest } = formsDataSlice.actions
export default formsDataSlice.reducer