import React, { useEffect, useState } from "react"
import { useReturnCurrentUser } from "../../Api/User/userSlice"
import User from "../../Api/User/User"
import { IsUserLoggedInResponse } from "../../Api/Interfaces"

export interface MirajMenuItemInterface {
    name: string,
    title: string,
    link: string,
    only_auth?: boolean
}

export const menuItems = [
    {
        name: "home-page",
        title: "Home",
        link: "/"
    },
    {
        name: "features-page",
        title: "Features",
        link: "/#features-section"
    },
    {
        name: "help-page",
        title: "Help",
        link: "/help"
    },
    // {
    //     name: "admin-page",
    //     title: "My Digital Businiess Cards",
    //     link: "/panel/digitalcards",
    //     only_auth: true
    // },
    // {
    //     name: "item-requests",
    //     title: "My NFC Cards",
    //     link: "/panel/chips",
    //     only_auth: true
    // },
    {
        name: "my-profile",
        title: "My Profile",
        link: "/profile/details",
        only_auth: true
    },
    {
        name: "logout",
        title: "Logout",
        link: "/logout",
        only_auth: true
    }
]

export default function Menu(){

    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

    const user = new User()

    useEffect(() => {
       user.isLoggedIn()
        .then((response: IsUserLoggedInResponse) => {
            if (response.isLoggedIn){
                setIsLoggedIn(true)
            }
        })
    }, [])

    return(
        <div className="miraj-warehouse-menu-header">
            <div className="content-of-miraj-warehouse-menu-header">
                {menuItems.map((item: MirajMenuItemInterface, index: number) => {

                    if (item.only_auth){
                        if (!isLoggedIn){
                            return <></>
                        }
                    }

                    return (
                        <div className="single-warehouse-menu-item" key={index}>
                            <div className="content-of-single-warehouse-menu-item">
                                <a href={item.link}>{item.title}</a>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}