import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { User } from '../../Api/Interfaces'
import { profilePageSections, ProfilePageSectionInterface } from "./ProfilePage"

interface Props {
    user: User
}

export default function ProfileContent(props: Props) {

    const params = useParams()
    const sectionName = params.section_name

    const { search } = useLocation()
    const parameters = new URLSearchParams(search)

    const singleItemId = parameters.get("id") 

    if (profilePageSections.filter(section => section.name === sectionName).length > 0){

        let currentSection: ProfilePageSectionInterface = {
            name: "",
            icon: "",
            title: "",
            description: "",
            component: ProfileContent
        }

        profilePageSections.map(section => {
            if (section.name === sectionName){
                currentSection = section
            }
        })
        
        return(
            <div className="profile-main-content-container">
                <div className="content-of-of-profile-main-content-container">

                    {!singleItemId && (
                        <div className="head-box-of-profile-main-content-container">
                            <div className="content-of-head-box-of-profile-main-content-container">
                                <h2>{currentSection.title}</h2>
                                <p>{currentSection.description}</p>
                            </div>
                        </div>
                    )}

                    <div className="profile-page-content-component-container">
                        <currentSection.component user={props.user} />
                    </div>
                    
                </div>
            </div>
        )
    }else{
        return(
            <div>
                Not found
            </div>
        )
    }

}