import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { store } from "../../../app/store"
import { SingleCardInterface } from "./CardsApp"

interface SingleCardInitialStateInterface {
    loadingStatus: boolean,
    singleCard: SingleCardInterface
}

const initialState: SingleCardInitialStateInterface = {
    loadingStatus: true,
    singleCard: {
        id: 0,
        title: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        position: "",
        headline: "",
        user_id: 0,
        company_id: 0,
        design_id: 1,
        is_public: true,
        is_active: true,
        unique_name: "",
        thumbnail: "",
        date_created: "",
        primary_color: "#4f7bf7",
        company_name: "",
        company_logo: "",
        background_color: "#f2f2f2",
        background_image: "",
        card_color: "#FFFFFF",
        text_color: "#000000",
        subtext_color: "#919191"
    }
}

const cardSlice = createSlice({
    name: "card",
    initialState,
    reducers: {
        updateCard: (state: SingleCardInitialStateInterface, action: PayloadAction<SingleCardInterface>) => {
            const payload = action.payload

            state.loadingStatus = false
            state.singleCard = payload
        },
    }
})

/**
 * Return single card details
 */
export const returnSingleCardDetails = () => {
    const state = store.getState()
    return state.card.singleCard
}

/**
 * Return single card details
 */
export const returnSingleCardLoadingStatus = () => {
    const state = store.getState()
    return state.card.loadingStatus
}

export const { updateCard } = cardSlice.actions
export default cardSlice.reducer