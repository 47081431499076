import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CardsApp from './CardsApp'
import { Grid, IconButton, Skeleton } from '@mui/material'
import DateField from "../../FormElements/Elements/DateField"

import { Pie, Line } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';
import { ChartData, ChartDataset } from 'chart.js';
import DateAndTime from '../../DateAndTime/DateAndTime';
import GenerateMessageBox from '../../FormElements/GenerateMessageBox';
import GenerateIcon from '../../FormElements/GenerateIcon';

ChartJS.register(
    ArcElement, 
    Tooltip, 
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
);

export default function CardStatisticsPage() {

    const params = useParams()
    const cardUniqueName: string = params.id ? params.id : ""

    const cardsApp = new CardsApp()
    const formatDate = new DateAndTime()

    const [cardTitle, setCardTitle] = useState<string>("")

    interface ObejctInterface {
        [key: string]: number
    }

    interface StatisticsInterface {
        countries: ObejctInterface,
        visits: ObejctInterface,
        total_visits: number
    }

    const [statistics, setStatistics] = useState<StatisticsInterface>({
        countries: {},
        visits: {},
        total_visits: 0
    })

    const dateObj = new Date()
    const [dateFrom, setDateFrom] = useState<Date>(new Date(dateObj.getFullYear(), dateObj.getMonth(), 1))
    const [dateTo, setDateTo] = useState<Date>(new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0))

    const [errorMessage, setErrorMessage] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(true)

    interface DatasetInterface {
        label: string,
        data: Array<number>,
        backgroundColor: Array<string>,
        borderColor: Array<string>,
        borderWidth: number
    }
    interface PieChartInterface {
        labels: Array<string>,
        datasets: DatasetInterface
    }

    /**
     * Load statistics
     */
    const loadStatistics = () => {
        setLoading(true)

        cardsApp.getSingleCardStatistics(cardUniqueName, formatDate.formatToServerStandartDateOnly(new Date(dateFrom).toDateString()), formatDate.formatToServerStandartDateOnly(new Date(dateTo).toDateString()))
            .then(response => {

                const { data } = response
                setStatistics(data)
                setErrorMessage("")
                setLoading(false)

            })
            .catch(error => {

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setErrorMessage(message)
                setLoading(false)

            })
    }

    /**
     * Render section title box
     */
    const renderSectionTitleBox = () => {
        return(
            <div className="admin-panel-head-section">
                <div className="content-of-admin-panel-head-section">
                    <h1 style={{ display: "flex" }}>
                        <a href="/panel/digitalcards">
                            <IconButton style={{ margin: "-4px 5px 0" }}>
                                <GenerateIcon icon="back" />
                            </IconButton>
                        </a>

                        {cardTitle ? cardTitle : <Skeleton variant="text" width={100} />}
                    </h1>
                </div>
            </div>
        )
    }

    /**
     * Effect
     */
    useEffect(() => {
        loadStatistics()
    }, [])

    useEffect(() => {
        cardsApp.getSingleCardDetails(cardUniqueName, true)
            .then(response => {

                const { data } = response

                if (data.title){
                    setCardTitle(data.title)
                }

            })
    }, [])

    useEffect(() => {
        loadStatistics()
    }, [dateFrom, dateTo])

    if (!loading){

        const renderCharts = () => {
            /**
             * Pie data
             */
            const countries = statistics.countries

            const randomColor = () => {
                const r = Math.floor(Math.random() * 255)
                const g = Math.floor(Math.random() * 255)
                const b = Math.floor(Math.random() * 255)

                return r + "," + g + "," + b
            }

            let labels: Array<string> = []
            let countiesVisits: Array<number> = []

            let pieBgColors: Array<string> = []
            let pieBorderColors: Array<string> = []

            Object.entries(countries).map(([k, v]) => {
                labels.push(k)
                countiesVisits.push(Number(v))

                const color = randomColor()
                pieBgColors.push(`rgba(${color}, 0.2)`)
                pieBorderColors.push(`rgba(${color}, 0.5)`)
            })

            let data = {
                labels: labels,
                datasets: [
                {
                    label: 'Number of Visits',
                    data: countiesVisits,
                    backgroundColor: pieBgColors,
                    borderColor: pieBorderColors,
                    borderWidth: 1,
                },
                ],
            };

            const options = {
                responsive: true,
                plugins: {
                legend: {
                    position: 'top' as const,
                },
                //   title: {
                //     display: true,
                //     text: 'Number of:',
                //   },
                },
            };

            /**
             * Dates chart
             */
            const datesChart = () => {
                const options = {
                    responsive: true,
                    plugins: {
                    legend: {
                        position: 'top' as const,
                    },
                    title: {
                        display: true,
                        text: 'Visits by dates',
                    },
                    },
                };
                
                let labels: Array<string> = [];
                let dates: Array<number> = []

                Object.entries(statistics.visits).map(([k, v]) => {
                    labels.push(formatDate.formatDate(k))
                    dates.push(v)
                })
                
                const data = {
                    labels,
                    datasets: [
                    {
                        label: 'Visits',
                        data: dates,
                        borderColor: 'rgb(36, 6, 249)',
                        backgroundColor: 'rgba(36, 6, 249, 0.5)',
                    },
                    ],
                };

                return <Line options={options} data={data} />
            }

            return(
                <>

                        <Grid item xs={3}>
                            <div className="single-card-statistics-box">
                                <div className="content-of-single-card-statistics-box">

                                    <div className="chart-box-of-single-card-statistics-box">
                                        <div className="content-of-chart-box-of-single-card-statistics-box">
                                            <Pie data={data} options={options} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={9}>
                            <div className="single-card-statistics-box">
                                <div className="content-of-single-card-statistics-box">

                                    <div className="chart-box-of-single-card-statistics-box">
                                        <div className="content-of-chart-box-of-single-card-statistics-box">
                                            {datesChart()}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Grid>
                </>
            )
        }

        return(
            <>
                {renderSectionTitleBox()}
                <div className="single-card-statistics-page-container">
                    <div className="content-of-single-card-statistics-page-container">

                        <Grid container spacing={2}>

                            <Grid item xs={3}>
                                <div className="single-card-statistics-box">
                                    <div className="content-of-single-card-statistics-box">
                                        
                                        <div className="text-box-grid-of-single-card-statistics-box">
                                            <div className="content-of-text-box-grid-of-single-card-statistics-box">

                                                <div className="icon-box-of-text-box-grid-of-single-card-statistics-box">
                                                    <div className="content-of-icon-box-of-text-box-grid-of-single-card-statistics-box">

                                                        <div className="small-statistics-icon-box">
                                                            <div className="content-of-small-statistics-icon-box">
                                                                <GenerateIcon icon="chart" />
                                                            </div>
                                                        </div>
                                                        <h3>Total Visits:</h3>

                                                    </div>
                                                </div>

                                                <h4>{statistics.total_visits}</h4>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={9}>
                                <div className="single-card-statistics-box">
                                    <div className="content-of-single-card-statistics-box">

                                        <div className="form-elements-of-single-card-statistics-box">
                                            <div className="content-of-form-elements-of-single-card-statistics-box">

                                                <div className="single-form-elem-of-form-elements-of-single-card-statistics-box">
                                                    <div className="content-of-single-form-elem-of-form-elements-of-single-card-statistics-box">
                                                        <DateField data={{ name: "date_from", value: dateFrom }} label="From" id="date_from" handleOnChange={(name, value) => setDateFrom(value)} fullWidth/>
                                                    </div>
                                                </div>

                                                <div className="single-form-elem-of-form-elements-of-single-card-statistics-box">
                                                    <div className="content-of-single-form-elem-of-form-elements-of-single-card-statistics-box">
                                                        <DateField data={{ name: "date_to", value: dateTo }} label="To" id="date_to" handleOnChange={(name, value) => setDateTo(value)} fullWidth/>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Grid>

                            {!statistics.countries || !statistics.visits ? <Grid item xs={12}><GenerateMessageBox type="info" title="Nothing yet..." message="There is no data on the selected time period, or no one has viewed your card yet" /></Grid> : renderCharts()}

                        </Grid>

                    </div>
                </div>
            </> 
        )
    }else{
        return(
            <>
                {renderSectionTitleBox()}
                <div className="single-card-statistics-page-container">
                    <div className="content-of-single-card-statistics-page-container">

                        <Grid container spacing={2}>

                            <Grid item xs={3}>
                                <div className="single-card-statistics-box">
                                    <div className="content-of-single-card-statistics-box">

                                        <div className="chart-box-of-single-card-statistics-box">
                                            <div className="content-of-chart-box-of-single-card-statistics-box">
                                                <Skeleton variant='circular' width={400} height={400} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={9}>
                                <div className="single-card-statistics-box">
                                    <div className="content-of-single-card-statistics-box">

                                        <div className="chart-box-of-single-card-statistics-box">
                                            <div className="content-of-chart-box-of-single-card-statistics-box">
                                                <Skeleton variant="rectangular" width="100%" height={500} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </Grid>

                        </Grid>

                    </div>
                </div>
            </>
        )
    }
}