import MirajWarehouseBaseApp from "../MirajWarehouseBaseApp";

/**
 * Companies App
 */
export default class UsersApp extends MirajWarehouseBaseApp {

    /**
     * App Name
     */
    appName: string = "users"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "users"

    /**
     * Required fields
     */
    requiredFields = [
        "first_name",
        "last_name",
        "email",
        // "user_group_id"
    ]

}