import React from 'react'
import { useReturnSingleForm } from './formsSlice'
import { FormElementsForm } from './Interfaces'
import RenderFormElements from './RenderFormElements'

interface Props{
    name: string,
    handleSubmitForm?: () => void,
    isUpdateForm?: boolean
}

export default function GenerateForm(props: Props) {

    const form = useReturnSingleForm(props.name)

    if (form){
        return(
            <div className="form-elements-form-container">
                <div className="content-of-form-elements-form-container">

                    <div className="elements-of-form-elements-form-container">
                        <div className="content-of-elements-of-form-elements-form-container">
                            <RenderFormElements isUpdateForm={props.isUpdateForm} elements={form.elements ? form.elements : []} handleSubmitForm={props.handleSubmitForm} />
                        </div>
                    </div>

                </div>
            </div>
        )
    }else{
        return(<React.Fragment></React.Fragment>)
    }

}