import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import GenerateIcon from '../../FormElements/GenerateIcon'
import AdminPanel from '../AdminPanel'

export default function SectionHeadSection() {

    const [itemTitle, setItemTitle] = useState("")

    const params = useParams()
    const dispatch = useDispatch()
    const sectionName = params.section_name!

    const { search } = useLocation()
    const parameters = new URLSearchParams(search)

    if (sectionName){

        const adminPanel = new AdminPanel()
        const section = adminPanel.returnSingleAdminSectionDetails(sectionName)
        const currentPageType = adminPanel.returnCurrentPageType()
        const pluralize = require("pluralize")

        if (section.appClass){
            const appClass = section.appClass

            if (params.id){
                appClass.getSingleInstanceDetails(Number(params.id))
                    .then(response => {
                        appClass.logResults(response)

                        const { status, statusText, data } = response

                        switch(status){

                            case 200:
                                if (data.title){
                                    setItemTitle(data.title)
                                }

                                if (data.name){
                                    setItemTitle(data.name)
                                }
                                break

                        }


                    })
                    .catch(error => {
                        appClass.logResults(error)

                        // const { status, statusText, data } = error.response 
                        // const message = data.message ? data.message : statusText

                        // setMessageBoxDetails({
                        //     display: true,
                        //     type: "error",
                        //     message: message,
                        //     title: "Error"
                        // })

                        // updatePageLoadingStatus(false)

                    })
            }
        }

        const sectionTitle = section.title
        let pageTitle = sectionTitle

        const singularSectionTitle = pluralize.singular(sectionTitle)

        let basePageTitle = itemTitle ? itemTitle : singularSectionTitle

        if (currentPageType === "edit"){
            pageTitle = `Edit ${basePageTitle}`
        }

        if (currentPageType === "view"){
            pageTitle = `${basePageTitle}`
        }

        if (currentPageType === "new"){
            pageTitle = `Add a new ${singularSectionTitle.toLowerCase()}`
        }

        /**
         * Back icon
         */
        const backButton = () => {

            let backLink: string = `/panel/${sectionName}`
            const customBackLink = parameters.get("back") 

            if (customBackLink){
                backLink = customBackLink
            }

            return(
                <a href={backLink}>
                     <IconButton style={{ margin: "-4px 5px 0" }}>
                        <GenerateIcon icon="back" />
                    </IconButton>
                </a>
            )
        }

        return(
            <div className="admin-panel-head-section">
                <div className="content-of-admin-panel-head-section">
                    <h1>
                        {currentPageType === "new" && backButton()}
                        {currentPageType === "edit" && backButton()}
                        {currentPageType === "view" && backButton()}

                        {pageTitle}
                        
                        {currentPageType === "base" && (
                            section.optionalPages.addNewPage! === true && (
                               <a href={`/panel/${sectionName}/new`}>
                                    <IconButton style={{ margin: "-4px 5px 0" }}>
                                        <GenerateIcon icon="add" />
                                    </IconButton>
                               </a>
                            )
                        )}
                    </h1>
                </div>
            </div>
        )
    }else{
        return <React.Fragment></React.Fragment>
    }

}