import React from 'react'
import { BaseAppInterface } from '../apps/BaseApp'
import { DataDisplayerItem } from './Interfaces'
import ItemButtons from './ItemButtons'
import ItemFields from './ItemFields'

interface ComponentProps extends DataDisplayerItem {
    sectionName: string,
    appClass: BaseAppInterface
}

export default function DataSingleDisplayerItem(props: ComponentProps) {

    const { id, fields, buttons, sectionName, loading, subItems, details } = props

    return(
        <div className="data-displayer-single-item-wrapper">

            <div className="data-displayer-single-item">
                <div className="content-of-data-displayer-single-item">

                    <ItemFields fields={fields} loading={loading} details={details} />
                    <ItemButtons buttons={buttons} itemId={id} sectionName={sectionName} loading={loading} details={details ? details : {}} appClass={props.appClass} />

                </div>
            </div>

            {subItems && subItems.length > 0 && (
                <div className="data-displayer-single-item-sub-items-container">
                    <div className="content-of-data-displayer-single-item-sub-items-container">
                        {subItems.map((subItem: DataDisplayerItem) => <DataSingleDisplayerItem sectionName={props.sectionName} appClass={props.appClass} {...subItem} />)}
                    </div>
                </div>
            )}

        </div>
    )
}