import React from 'react'
import { useParams } from 'react-router-dom'
import UpdateChipsDetailsForm from './UpdateChipsDetailsForm'

export default function EditChipPage() {

    const params = useParams()
    const uniqueName = params.id ? params.id : ""

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">
                <UpdateChipsDetailsForm uniqueName={uniqueName} />
            </div>
        </div>
    )
}