import { Pages } from "../Router/Types"
import AdminBasePage from "./components/AdminBasePage"

const accessDeniedMessage = "You don't have rights to view the Admin panel"

export const adminPanelRouts: Pages = [
    {
        name: "admin-panel-section",
        title: "Admin Panel",
        path: "/panel/:section_name",
        component: AdminBasePage,
        isUserAuthorizedContent: true,
        disableBaseStructure: true,
        onlyAdmin: false,
        accessDeniedMessage
    },
    {
        name: "admin-panel-edit-page",
        title: "Admin Panel",
        path: "/panel/:section_name/edit/:id",
        component: AdminBasePage,
        isUserAuthorizedContent: true,
        disableBaseStructure: true,
        onlyAdmin: false,
        accessDeniedMessage
    },
    {
        name: "admin-panel-view-page",
        title: "Admin Panel",
        path: "/panel/:section_name/view/:id",
        component: AdminBasePage,
        isUserAuthorizedContent: true,
        disableBaseStructure: true,
        onlyAdmin: false,
        accessDeniedMessage
    },
    {
        name: "admin-panel-new-page",
        title: "Admin Panel",
        path: "/panel/:section_name/new",
        component: AdminBasePage,
        isUserAuthorizedContent: true,
        disableBaseStructure: true,
        onlyAdmin: false,
        accessDeniedMessage
    }
]