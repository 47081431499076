import React from 'react';
import './App.css';
import './components/DataDisplayer/DataDisplayerStyles.css';
import './components/event-badge-generator/styles.css';
import Router from './components/Router/Router';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#2406F9',
      main: '#2406F9',
      dark: '#2406F9',
      contrastText: '#fff',
    },
    secondary: {
      light: '#828387',
      main: '#454649',
      dark: '#636569',
      // contrastText: '#000',
    },
  },
});

function App() {
  return(
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  )
}

export default App;
