import { FormControl, FormHelperText, ListSubheader, Skeleton } from '@mui/material'
import React, { useEffect } from 'react'
import { FormSelectElement, FormElementData, FormSelectSingleItem } from '../Interfaces'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LoadingFieldElement from './LoadingFieldElement';

interface Props extends FormSelectElement {
    data: FormElementData,
    handleOnChange: (name: string, value: any) => void
}

export default function FormElementSelect(props: Props) {

    const { id, label, data } = props

    const handleOnChange = (event: SelectChangeEvent) => {
        const target = event.target
        console.log("event", event)
        props.handleOnChange(id, target.value)
    }

    let items: Array<FormSelectSingleItem> = props.items ? props.items : []

    if (data.items) {
        items = data.items
    }

    const labelId = "label_of_" + id

    /**
     * Render single item
     */
    const renderSingleItem = (id: number | string, name: any, index: number) => <MenuItem value={id} key={index} >{name}</MenuItem>

    /**
     * Render sub items
     */
    const renderSubItems = (subItems: Array<FormSelectSingleItem>) => subItems.map((item, index) => renderSingleItem(item.id, item.title, index))

    /**
     * Render items
     */
    const renderItems = () => {
        if (items && items.length > 0){
            if (props.subItems){
                return items.map((item, index) => ([
                    <ListSubheader>{item.title}</ListSubheader>,
                    renderSubItems(item.subItems)
                ]))
            }else{
                return items.map((item, index) => renderSingleItem(item.id, item.title, index))
            }
        }else{
            return <React.Fragment></React.Fragment>
        }
    }

    if (data.loading){
        return <LoadingFieldElement label={label} />
    }else{
        return(
            <FormControl fullWidth={props.fullWidth}>
                <InputLabel id={labelId}>{label}</InputLabel>
                <Select
                labelId={labelId}
                id={id}
                value={data.value}
                label={label}
                name={id}
                onChange={handleOnChange}
                error={data.helperTextType === "error" ? true : false}
                disabled={data.disabled}
                >
                    {renderItems()}
                </Select>
                {data.helperText && <FormHelperText error={data.helperTextType === "error" ? true : false}>{data.helperText}</FormHelperText>}
            </FormControl>
        )
    }
}