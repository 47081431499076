import React from 'react'
import { SlideInterface, SliderInterface } from './SlidersApp'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import NoThum from "../../../assets/svg/no-thumb.svg"

interface Props extends SliderInterface {

}

export default function SingleSlider(props: Props) {

    const { id, title, slides, display_title } = props

    if (slides.length <= 1){
        return <></>
    }

    return(
        <div className="single-slider-container">
            <div className="content-of-single-slider-container">

                {display_title && (
                    <div className="mask-of-single-slider-container">
                        <div className="content-of-mask-of-single-slider-container">
                            <h3>{title}</h3>
                        </div>
                    </div>
                )}

                <Slide>
                    {slides.length > 0 && slides.map((slide: SlideInterface, index: number) => {
                        return (
                            <div className="each-slide-effect" key={index}>
                                <div style={{ 'backgroundImage': `url(${slide.img ? slide.img : NoThum})` }}>
                                    
                                </div>
                            </div>
                        )
                    })}
                </Slide>
            </div>
        </div>
    )
}