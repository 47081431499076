import React from "react"
import { useParams } from "react-router-dom"
import GenerateIcon from "../../FormElements/GenerateIcon"

interface Props {
    icon: string,
    name: string,
    title: string
}

export default function ProfileMenuItem(props: Props) {

    const { name, title, icon } = props

    const currentSectionName = useParams().section_name!
    let containerName = "single-admin-panel-menu-item"

    if (currentSectionName){
        if (currentSectionName === name){
            containerName += " active"
        }
    }

    return(
        <div className={containerName}>
            <a href={"/profile/" + name}>
                <div className="content-of-single-admin-panel-menu-item">

                    <div className="icon-box-of-single-admin-panel-menu-item">
                        <div className="content-of-icon-box-of-single-admin-panel-menu-item">
                            <GenerateIcon icon={icon} />
                        </div>  
                    </div>  

                    <div className="text-box-of-single-admin-panel-menu-item">
                        <div className="content-of-text-box-of-single-admin-panel-menu-item">
                            <h3>{title}</h3>
                        </div>
                    </div>

                </div>
            </a>
        </div>
    )
}