import BaseApp from "../BaseApp";

/**
 * Companies App
 */
export default class DemoRequestsApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "demorequests"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "demo-requests"

    /**
     * Required fields
     */
    requiredFields = [
        "name",
        "email",
        "phone",
        "message",
        "company"
    ]

    /**
     * Create a new request
     */
    createNewRequest = (name: string, email: string, phone: string, company: string, message: string, recaptcha: string) => this.sendRequest({
        method: "POST",
        url: this.appBaseEndPointPath + "/create-new-request",
        data: {
            name,
            email,
            phone,
            company,
            message,
            recaptcha
        },
        withCredentials: false
    })

}