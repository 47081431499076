import React from 'react'
import { useReturnCurrentUser } from '../../Api/User/userSlice'
import SectionDefaultForm from '../../AdminPanel/components/SectionDefaultForm'
import CreateNewChipPage from "./CreateNewChipPage"
import RegisterNewChipPage from "./RegisterNewChipPage"

export default function AddNewChipPage() {

    const currentUser = useReturnCurrentUser()
    let userType: string = "staff"

    if (currentUser.is_admin){
        userType = "admin"
    }

    if (currentUser.is_superuser){
        userType = "superuser"
    }

    console.log(currentUser)

    switch(userType){

        case "admin":
            return <RegisterNewChipPage />

        case "superuser":
            return <CreateNewChipPage />

        default:
            return <></>
    }
    
}