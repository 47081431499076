import MirajWarehouseBaseApp from "../../../Api/MirajWarehouseApiEndpoint";

/**
 * PasswordSetApp
 */
export default class PasswordSetApp extends MirajWarehouseBaseApp {

    /**
     * App Name
     */
    appName: string = "set-password"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "users/set-password"

    /**
     * Reset password path
     */
    appResetPasswordPath: string = "users/reset-password"

    /**
     * Required fields
     */
    requiredFields = [
        "email",
        "password"
    ]

}