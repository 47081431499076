import React from 'react'
import { useParams } from 'react-router'
import SliderEditor from './SliderEditor'

export default function EditSliderPage() {

    const params = useParams()
    const sliderId = params.id ? Number(params.id) : 0

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">
                <SliderEditor update={true} sliderId={sliderId} />
            </div>
        </div>
    )
}