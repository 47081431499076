import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Page } from "./Interfaces"
import { Pages } from "./Types"
import { routerPages } from "./RegisterPages"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"

const initialState: Pages = [
    ...routerPages
]

const pagesSlice = createSlice({
    name: "pages",
    initialState,
    reducers: {
        addPage: (state: Pages, action: PayloadAction<Page>) => {
            state.push(action.payload)
        }
    }
})

export const { addPage } = pagesSlice.actions
export const useReturnPages = () => useSelector((state: RootState) => state.pages)

export default pagesSlice.reducer