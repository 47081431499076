import { Dialog, DialogTitle, DialogContent, Button, DialogActions, IconButton } from '@mui/material'
import React from 'react'
import CardsApp, { LinkTypeInterface, SingleCardInterface, SingleCardLinkInterface } from './CardsApp'
import GenerateIcon from '../../FormElements/GenerateIcon'
import { useDispatch } from 'react-redux'
import { addNewLink, useReturnCardDetailsFromBuilder, useReturnCardLinksFromBuilder } from './cardBuilderSlice'

interface Props {
    open: boolean,
    handleClose: () => void
}

export default function AddLinksModal(props: Props) {

    const cardDetails: SingleCardInterface = useReturnCardDetailsFromBuilder()
    const cardLinks: Array<SingleCardLinkInterface> = useReturnCardLinksFromBuilder()

    const { open, handleClose } = props
    const dispatch = useDispatch()
    const cardsApp = new CardsApp()

    /**
     * Add New Link
     */
    const handleAddNewLink = (link: LinkTypeInterface) => {
        dispatch(addNewLink({
            id: cardLinks.length + 1,
            card_id: cardDetails.id,
            url: "",
            title: "",
            type: link.name,
            is_embeded_video: false,
            order_num: cardLinks.length + 1,
            date_created: ""
        }))
        handleClose()
    }

    /**
     * Render single link type box
     */
    const renderSingleLinkTypeBox = (link: LinkTypeInterface, index: number) => {
        return(
            <div className="link-type-additional-box" key={index}>
                <div className="content-of-link-type-additional-box">

                    <div className="icon-of-link-type-additional-box">
                        <div className="content-of-icon-of-link-type-additional-box">
                            <IconButton onClick={() => handleAddNewLink(link)}>
                                <GenerateIcon icon={link.icon} />
                            </IconButton>
                        </div>
                    </div>

                    <h3>{link.title}</h3>

                </div>
            </div>
        )
    }

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">
                Additional Information
            </DialogTitle>

            <DialogContent>
                <div className="additional-links-modal-boxes">
                    <div className="content-of-additional-links-modal-boxes">
                        {cardsApp.linkTypes.map((link: LinkTypeInterface, index: number) => renderSingleLinkTypeBox(link, index))}
                    </div>
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
      </Dialog>
    )
}