import React, { useEffect } from 'react'
import { SingleCardInterface } from './CardsApp'
import { updateFirstName, updateLastName, updateMiddleName, updatePosition, updateTitle, useReturnCardDetailsFromBuilder, updateHeadline, useReturnCardThumbnailAsFormElementData, updateThumbnail, useReturnMissingFieldsFromBuilder, updatePrimaryColor, updateBackgroundColor, updateCompanyLogo, useReturnCardCompanyLogoAsFormElementData, useReturnCardBackgroundImageAsFormElementData, updateBackgroundImage, updateIsPublic, updateCardColor, updateTextColor, updateSubTextColor, updateCompanyId, updateUserId, updateCustomCss, updateHidePosition, updateHideCompanyName } from './cardBuilderSlice'
import { useDispatch } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import FormElementImageUploader from '../../FormElements/Elements/FormElementImageUploader'
import { Grid, TextField, Switch, FormControlLabel } from '@mui/material'
import { FormElementData } from "../../FormElements/Interfaces"
import { HexColorPicker } from "react-colorful";
import SelectTemplate from './SelectTemplate'
import { useReturnCurrentUser } from '../../Api/User/userSlice'
import FormElementDynamicSelect from '../../FormElements/Elements/FormElementDynamicSelect'
import CompaniesApp from '../companies/CompaniesApp'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData } from '../../FormElements/formsDataSlice'
import { addNewForm } from '../../FormElements/formsSlice'
import UsersApp from '../users/UsersApp'
import GenerateForm from '../../FormElements/GenerateForm'

interface Props {
    checkRequiredFields: () => void
}

export default function BasicInformationForm(props: Props) {

    const cardDetails: SingleCardInterface = useReturnCardDetailsFromBuilder()
    const cardThunbnail: FormElementData = useReturnCardThumbnailAsFormElementData()
    const cardCompanyLogo: FormElementData = useReturnCardCompanyLogoAsFormElementData()
    const cardBackgroundImage: FormElementData = useReturnCardBackgroundImageAsFormElementData()
    const missingFields: Array<string> = useReturnMissingFieldsFromBuilder()
    const dispatch = useDispatch()

    const currentUser = useReturnCurrentUser()

    const companyIdData = returnSingleFormElementData("company_id")
    const userIdData = returnSingleFormElementData("user_id")

    /**
     * Create data
     */
    useEffect(() => {

        dispatch(addManyElementsData([
            {
                name: "company_id",
                value: cardDetails.company_id
            },
            {
                name: "user_id",
                value: cardDetails.user_id
            }
        ]))

        dispatch(addNewForm({
            name: "card_basic_info",
            elements: [
                {
                    type: "DynamicSelect",
                    xs: 6,
                    id: "company_id",
                    label: "Company",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new CompaniesApp(),
                    addNoneItem: false,
                    formName: "card_basic_info"
                },
                {
                    type: "DynamicSelect",
                    xs: 6,
                    id: "user_id",
                    label: "User",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "first_name",
                    appClass: new UsersApp(),
                    addNoneItem: false,
                    formName: "card_basic_info"
                },
            ]
        }))

    }, [])

    useEffect(() => {

        dispatch(updateCompanyId(companyIdData.value))

    }, [companyIdData])

    useEffect(() => {

        dispatch(updateUserId(userIdData.value))

    }, [userIdData])

    /**
     * Updare basic information
     */
    const updateBasicInfo = (value: string, func: (value: string) => AnyAction) => {
        props.checkRequiredFields()
        dispatch(func(value))
    }

    /**
     * Update thumbnail
     */
    const updateCardThumbnail = (name: string, value: string) => {
        updateBasicInfo(value, updateThumbnail)
    }


    /**
     * Update thumbnail
     */
    const updateCardBackgroundImage = (name: string, value: string) => {
        updateBasicInfo(value, updateBackgroundImage)
    }

    /**
     * Update thumbnail
     */
    const updateCardCompanyLogo = (name: string, value: string) => {
        updateBasicInfo(value, updateCompanyLogo)
    }

    /**
     * Check if item filled in
     */
    const checkIfFieldFilledIn = (name: string) => {

        let status: boolean = true

        if (missingFields.length > 0){
            if (missingFields.includes(name)){
                status = false
            }
        }

        return status
    }

    /**
     * Update primary color
     */
    const updateCardPrimaryColor = (color: string) => dispatch(updatePrimaryColor(color))

     /**
     * Update card color
     */
    const updateColorsCardColor = (color: string) => dispatch(updateCardColor(color))


     /**
     * Update card text color
     */
    const updateCardTextColor = (color: string) => dispatch(updateTextColor(color))

     /**
     * Update card text color
     */
    const updateCardSubTextColor = (color: string) => dispatch(updateSubTextColor(color))

    /**
     * Update primary color
     */
    const updateCardBackgroudColor = (color: string) => dispatch(updateBackgroundColor(color))

    return(
        <div className="basic-card-information-container">
            <div className="content-of-basic-card-information-container">

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <div className="card-builder-title-box">
                            <div className="content-of-card-builder-title-box">
                                <h3>Basic Information</h3>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField id="title" label="Title" variant="outlined" name="title" value={cardDetails.title} fullWidth onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateBasicInfo(event.target.value, updateTitle)} helperText={!checkIfFieldFilledIn("title") && "Title is required"} error={!checkIfFieldFilledIn("title")} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField id="first_name" label="First Name" variant="outlined" name="first_name" value={cardDetails.first_name} fullWidth onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateBasicInfo(event.target.value, updateFirstName)} helperText={!checkIfFieldFilledIn("first_name") && "First Name is required"} error={!checkIfFieldFilledIn("first_name")} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField id="last_name" label="Last Name" variant="outlined" name="last_name" value={cardDetails.last_name} fullWidth onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateBasicInfo(event.target.value, updateLastName)} helperText={!checkIfFieldFilledIn("last_name") && "Last Name is required"} error={!checkIfFieldFilledIn("last_name")} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField id="middle_name" label="Middle Name" variant="outlined" name="middle_name" value={cardDetails.middle_name} fullWidth onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateBasicInfo(event.target.value, updateMiddleName)} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField id="position" label="Position" variant="outlined" name="position" value={cardDetails.position} fullWidth onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateBasicInfo(event.target.value, updatePosition)} helperText={!checkIfFieldFilledIn("position") && "Position is required"} error={!checkIfFieldFilledIn("position")} />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel control={<Switch checked={cardDetails.hide_position} onChange={(e) => dispatch(updateHidePosition(cardDetails.hide_position ? false : true))} />} label="Hide Position" />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel control={<Switch checked={cardDetails.hide_company_name} onChange={(e) => dispatch(updateHideCompanyName(cardDetails.hide_company_name ? false : true))} />} label="Hide Company Name" />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField rows={4} multiline id="headline" label="Headline" variant="outlined" name="headline" value={cardDetails.headline} fullWidth onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateBasicInfo(event.target.value, updateHeadline)} />
                    </Grid>
{/* 
                    <Grid item xs={12}>
                        <TextField rows={4} multiline id="custom_css" label="Custom CSS" variant="outlined" name="headline" value={cardDetails.custom_css} fullWidth onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateBasicInfo(event.target.value, updateCustomCss)} />
                    </Grid> */}

                    {currentUser.is_superuser && (
                        <Grid item xs={12}>
                            <GenerateForm name="card_basic_info" />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <FormControlLabel control={<Switch checked={cardDetails.is_public} onChange={(e) => dispatch(updateIsPublic(cardDetails.is_public ? false : true))} />} label="Active" />
                    </Grid>

                    <Grid item xs={12}>
                        <FormElementImageUploader id="thumbnail" label="Your Photo" data={cardThunbnail} handleOnChange={updateCardThumbnail} />
                    </Grid>

                    <Grid item xs={12}>
                        <FormElementImageUploader id="company_logo" label="Company logo" data={cardCompanyLogo} handleOnChange={updateCardCompanyLogo} />
                    </Grid>

                    <Grid item xs={12}>
                        <FormElementImageUploader id="background_image" label="Background image" data={cardBackgroundImage} handleOnChange={updateCardBackgroundImage} />
                    </Grid>

                    <Grid item xs={12}>
                        <SelectTemplate />
                    </Grid>

                    <Grid item xs={12}>
                        <h3>Primary Color</h3>
                        <HexColorPicker color={cardDetails.primary_color} onChange={updateCardPrimaryColor} />
                        <TextField style={{ marginTop: 5 }} variant='outlined' value={cardDetails.primary_color} onChange={(e) => updateCardPrimaryColor(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <h3>Background Color</h3>
                        <HexColorPicker color={cardDetails.background_color} onChange={updateCardBackgroudColor} />
                        <TextField style={{ marginTop: 5 }} variant='outlined' value={cardDetails.background_color} onChange={(e) => updateCardBackgroudColor(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <h3>Card Color</h3>
                        <HexColorPicker color={cardDetails.card_color ? cardDetails.card_color : "#FFFFFF"} onChange={updateColorsCardColor} />
                        <TextField style={{ marginTop: 5 }} variant='outlined' value={cardDetails.card_color ? cardDetails.card_color : "#FFFFFF"} onChange={(e) => updateColorsCardColor(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <h3>Text Color</h3>
                        <HexColorPicker color={cardDetails.text_color ? cardDetails.text_color : "#000000"} onChange={updateCardTextColor} />
                        <TextField style={{ marginTop: 5 }} variant='outlined' value={cardDetails.text_color ? cardDetails.text_color : "#000000"} onChange={(e) => updateCardTextColor(e.target.value)} />
                    </Grid>

                    <Grid item xs={12}>
                        <h3>Subtitle Color</h3>
                        <HexColorPicker color={cardDetails.text_color ? cardDetails.subtext_color : "#919191"} onChange={updateCardSubTextColor} />
                        <TextField style={{ marginTop: 5 }} variant='outlined' value={cardDetails.text_color ? cardDetails.subtext_color : "#919191"} onChange={(e) => updateCardSubTextColor(e.target.value)} />
                    </Grid>

                </Grid>

            </div>
        </div>
    )
}