import React, { useEffect } from 'react'
import DataDisplayer from '../../DataDisplayer/DataDisplayer'
import { DataDisplayerDefaultMessages, DataDisplayerProps } from '../../DataDisplayer/Interfaces'
import GenerateMessageBox from '../../FormElements/GenerateMessageBox'
import AdminPanel from '../AdminPanel'

export default function BasePageContent() {

    const adminPanel = new AdminPanel()
    const currentSectionName = adminPanel.returnCurrentSectionName()

    const currentSection = adminPanel.returnSingleAdminSectionDetails(currentSectionName)

    if (!currentSection.appClass){
        return <GenerateMessageBox type="error" title="Fatal Error" message="appClass - was not provided for the Data Displayer. Check the section config" />
    }

    /**
     * Data Displayer
     */
    const defaultMessagesDataDisplayer: DataDisplayerDefaultMessages = {
        notFound: "Nothing found",
        addNewText: "Add new",
        sureToDelete: "Are you sure you want to delete this item?"
    }

    const dataDisplayerConfig: DataDisplayerProps = {
        sectionName: currentSectionName,
        defaultMessages: currentSection.dataDisplayerDefaultMessages ? currentSection.dataDisplayerDefaultMessages : defaultMessagesDataDisplayer,
        appClass: currentSection.appClass,
        enableSearch: currentSection.enableSearch ? true : false,
        customSearchForm: currentSection.customSearchForm,
        searchParams: currentSection.searchParams
    }

    return(
        <div>
            <DataDisplayer {...dataDisplayerConfig} />
        </div>
    )
}