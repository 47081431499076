import React from 'react'
import CardsApp, { SingleCardInterface, SingleCardLinkInterface } from './CardsApp'
import CardLinks from "./CardLinks"
import NoThumb from "../../../assets/svg/no-user.svg"
import { Skeleton } from '@mui/material'
import CardTemplate01 from "./DesignTemplates/CardTemplate01"
import CardTemplate02 from "./DesignTemplates/CardTemplate02"

export interface ISingleCardProps extends SingleCardInterface {
    loading?: boolean,
    customLinks?: Array<SingleCardLinkInterface>,
    showCustomLinks?: boolean,
    noVisit?: boolean
}

export default function SingleCard(props: ISingleCardProps) {

    const { design_id } = props

    switch(design_id){

        case 1:
            return <CardTemplate01 {...props} />
            break

        case 2:
            return <CardTemplate02 {...props} />
            break

        default:
            return <CardTemplate01 {...props} />
    }

}