import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import UploadImage from '../UploadImage';

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'code',
  'color',
  'background',
  'code-block',
  'align',
];

interface OnChangeHandler {
  (e: any): void;
}

type Props = {
  value: string;
  placeholder: string;
  onChange: OnChangeHandler;
};

const TextEditor: React.FC<Props> = ({ value, onChange, placeholder }) => {
  const reactQuillRef: any = useRef(null);
  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('multiple', 'multiple');
    input.click();
    input.onchange = async () => {
        if (input.files){
            Array.from(input.files).forEach((item) => {
                const formData = new FormData();
                formData.append('image', item);
                formData.append('subjectId', '123');
                
                const imageUploader = new UploadImage()
                imageUploader.uploadImageSendRequest(formData)
                    .then(response => {
                        const quill = reactQuillRef?.current?.getEditor(); 
                        const cursorPosition = quill.getSelection().index; 
                        const link = response.data.url;
                        console.log(quill.insertEmbed);
                        quill.insertEmbed(cursorPosition, 'image', link); 
                        quill.setSelection(cursorPosition + 1); 
                    })
                    .catch(error => {
                        const { status, statusText, data } = error.response 
                        const message = data.message ? data.message : statusText

                        alert(message)
                    })

              });
        }
    };
  };

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }], 
          ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link', 'image'], 
          [{ color: [] }, { background: [] }, { align: [] }],
          ['clean'],
        ],
        handlers: {
          image: imageHandler, 
        },
      },
    }),
    [],
  );

  return (
    <>
      <ReactQuill
        ref={reactQuillRef}
        theme="snow"
        value={value || ''}
        modules={modules}
        formats={formats}
        onChange={onChange}
        placeholder={placeholder}
      />
    </>
  );
};

export default TextEditor;