import React from 'react'
import GenerateIcon from './GenerateIcon'

export type MessageBoxTypes = "info" | "error" | "warning" | "success"
export interface MessageBoxPropsInterface {
    type: MessageBoxTypes,
    title: string,
    message: string,
    customMessage?: () => void
}

export const generateMessageBoxIcon = (type: MessageBoxTypes) => {

    let icon = "info"

    switch(type){

        case "error":
            icon = "error"
            break;

        case "warning":
            icon = "warning"
            break; 

        case "success":
            icon = "success"
            break;

    }

    return <GenerateIcon icon={icon} />

}

export default function GenerateMessageBox(props: MessageBoxPropsInterface) {

    let containerClassName = "message-box-container"
    const { type, title, message } = props

    containerClassName += " " + type

    return(
        <div className={containerClassName}>
            <div className="content-of-message-box-container">

                <div className="icon-box-of-message-box-container">
                    <div className="content-of-icon-box-of-message-box-container">
                        {generateMessageBoxIcon(type)}
                    </div>
                </div>

                <div className="text-box-of-message-box-container">
                    <div className="content-of-text-box-of-message-box-container">
                        <h3>{title}</h3>
                        {props.customMessage ? <>{props.customMessage()}</> : <p>{message}</p>}
                    </div>
                </div>

            </div>
        </div>
    )
}