import React, { useEffect, useState } from 'react'

interface Props {
    video: string
}

export default function Video(props: Props) {

    const baseClassName: string = "video-auto-play-container-section"
    const [containerClassName, setContainerClassName] = useState<string>(baseClassName)

    const [isActive, setIsActive] = useState<boolean>(false)

    const handleOnClick = () => setIsActive(isActive ? false : true)

    useEffect(() => {
        setContainerClassName(isActive ? baseClassName + " active" : baseClassName)
    }, [isActive])

    return(
        <div className={containerClassName} onClick={() => handleOnClick()}>
            <div className="content-of-video-auto-play-container-section">
                <video autoPlay loop muted playsInline>
                    <source src={props.video} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}