import React from 'react'
import AdminPanel from '../AdminPanel'
import { AdminPanelMenuItem } from '../Interfaces'
import AdminMenuItem from './AdminMenuItem'

export default function AdminMenu() {

    const adminPanel = new AdminPanel()
    const items: Array<AdminPanelMenuItem> = adminPanel.returnAdminPages()

    return(
        <div className="admin-panel-menu-navigation">
            <div className="content-of-admin-panel-menu-navigation">
                {items.map((item: AdminPanelMenuItem, index: number) => <AdminMenuItem icon={item.icon} name={item.name} title={item.title} key={index} />)}
            </div>
        </div>
    )
}