import { useMatch, useParams } from "react-router-dom";
import { adminPages } from "./AdminPages";
import { AdminPanelSection, UsersAllowed } from "./Interfaces";
import { User } from "../Api/Interfaces";

/**
 * Admin Panel
 */
export default class AdminPanel{
    
    /**
     * Return admin pages
     */
    returnAdminPages = () => adminPages

    /**
     * Return current section name
     */
    returnCurrentSectionName = () => {
        let name = "unknown"
        const params = useParams()

        if (params.section_name!){
            name = params.section_name
        }

        return name
    }

    /**
     * Single Admin Section Details
     */
    returnSingleAdminSectionDetails = (name: string) => {

        let details: AdminPanelSection = {
            type: "section",
            name: "unknown",
            title: "Unknown",
            icon: "home",
            usersAllowed: {
                onlyAdmin: true,
                onlySuperUser: false,
                anyUser: false
            },
            displayInMenu: true,
            optionalPages: {
                addNewPage: false,
                editPage: false,
                viewPage: false
            }
        }
        const pages = this.returnAdminPages()

        if (pages && pages.length > 0){
            pages.map((page: AdminPanelSection) => {
                if (page.name === name){
                    details = page
                }
            })
        }

        return details

    }

    /**
     * Return page type
     */
    returnCurrentPageType = () => {
        let page = "base"

        const isBasePage = useMatch("/panel/:section_name")
        const isEditPage = useMatch("/panel/:section_name/edit/:id")
        const isViewPage = useMatch("/panel/:section_name/view/:id")
        const isNewPage  = useMatch("/panel/:section_name/new")

        if (isEditPage !== null){
            page = "edit"
        }

        if (isViewPage !== null){
            page = "view"
        }

        if (isBasePage !== null){
            page = "base"
        }

        if (isNewPage !== null){
            page = "new"
        }

        return page
    }

    /**
     * Hide menu item
     */
    hideMenuItem = (usersAllowed: UsersAllowed, currentUser: User) => {
        let hideItem: boolean = true

        if (usersAllowed.anyUser){
            hideItem = false
        }else{
            if (usersAllowed.onlyAdmin){
                if (currentUser.is_admin){
                    hideItem = false
                }
            }
            if (usersAllowed.onlySuperUser){
                if (currentUser.is_superuser){
                    hideItem = false
                }
            }
        }

        return hideItem
    }

}