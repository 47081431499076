import React, { useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import GenerateIcon from "../../FormElements/GenerateIcon"
import { useDispatch } from 'react-redux';

import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementLoadingStatus, useReturnSingleFormElementData, updateElementDisabledStatus } from '../../FormElements/formsDataSlice'
import { addNewForm } from '../../FormElements/formsSlice';
import GenerateForm from '../../FormElements/GenerateForm';
import DemoRequestsApp from '../../apps/demo_requests/DemoRequestsApp';
import FormValidator from '../../FormElements/FormValidator';

export const showDemoForm = () => {
    const element = document.getElementById("demo-form-modal-container-element")
    if (element){
        element.classList.add("active")
    }
}

export const hideDemoForm = () => {
    const element = document.getElementById("demo-form-modal-container-element")
    if (element){
        element.classList.remove("active")
    }
}

export default function DemoForm() {

    const dispatch = useDispatch()

    const demoRequestsApp = new DemoRequestsApp()

    const formDetails = {
        name: "try_demo_form",
        elemPrefix: "try_demo_form_elem_"
    }

    const elemPrefix = formDetails.elemPrefix

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value

    /**
     * Handle submit
     */
    const handleSubmit = () => {
        updateButtonLoadingStatus(true)

        const requiredFields = [
            elemPrefix + "name",
            elemPrefix + "email",
            elemPrefix + "phone",
            elemPrefix + "company",
            elemPrefix + "message"
        ]
        const formValidator = new FormValidator(requiredFields)

        const name = getSingleFormElementValue("name")
        const email = getSingleFormElementValue("email")
        const phone = getSingleFormElementValue("phone")
        const company = getSingleFormElementValue("company")
        const message = getSingleFormElementValue("message")

        const recaptcha = getSingleFormElementValue("recaptcha")

        if (formValidator.isValid().valid){

            demoRequestsApp.createNewRequest(name, email, phone, company, message, recaptcha)
            .then(response => {
                    
                const { status, statusText, data } = response

                switch(status){

                    case 200:
                        const message = data.message! ? data.message : statusText
                        dispatch(
                            createOrUpdateElementData({
                                name: elemPrefix + "alert",
                                value: message,
                                severity: "success"
                            })
                        )

                        updateButtonLoadingStatus(false)
                        updateButtonDisabledStatus(true)
                        setTimeout(() => {
                            hideDemoForm()
                        }, 2000)
                        break

                }

                updateButtonLoadingStatus(false)
                updateButtonDisabledStatus(true)

            })
            .catch(error => {
                updateButtonLoadingStatus(false)
                
                const response = error.response
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                demoRequestsApp.logResults(response)

                updateButtonLoadingStatus(false)
                updateButtonDisabledStatus(false)
                
                switch(response.status){

                    default:
                        dispatch(
                            createOrUpdateElementData({
                                name: elemPrefix + "alert",
                                value: message,
                                severity: "error"
                            })
                        )
                }

            
            })

        }else{
            updateButtonLoadingStatus(false)
            formValidator.showRequiredFields(dispatch)
        }

    }

    /**
     * Register form
     */
    useEffect(() => {

        /**
         * Add a new form
         */
        dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "name",
                        label: "Name",
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "phone",
                        xs: 12,
                        id: elemPrefix + "phone",
                        label: "Phone",
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "email",
                        xs: 12,
                        id: elemPrefix + "email",
                        label: "Email",
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "company",
                        label: "Company",
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "message",
                        label: "Message",
                        fullWidth: true,
                        multiline: true,
                        maxRows: 4,
                        minRows: 4
                    },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "ReCaptcha",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "recaptcha"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: "Submit",
                        fullWidth: false,
                        onClick: () => {},
                        isSubmit: true
                    },
                ]
            })
        )

        /**
         * Add many form element data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "email",
                    value: ""
                },
                {
                    name: elemPrefix + "name",
                    value: ""
                },
                {
                    name: elemPrefix + "phone",
                    value: ""
                },
                {
                    name: elemPrefix + "company",
                    value: ""
                },
                {
                    name: elemPrefix + "message",
                    value: ""
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
                {
                    name: elemPrefix + "submit",
                    value: ""
                }
            ])
        )


    }, [])

    return(
        <div className="demo-form-container-wrapper" id="demo-form-modal-container-element">
            <div className="content-of-demo-form-container-wrapper">

                <div className="demo-form-modal-box">
                    <div className="content-of-demo-form-modal-box">

                        <div className="header-box-of-demo-form-modal-box">
                            <div className="content-of-header-box-of-demo-form-modal-box">

                                <h3>Get a free demo</h3>
                                <p>
                                    Fill out the form down below and we will contact you shortly
                                </p>

                                <div className="close-icon-box-of-header-box-of-demo-form-modal-box">
                                    <div className="content-of-close-icon-box-of-header-box-of-demo-form-modal-box">
                                        <IconButton onClick={() => hideDemoForm()}>
                                            <GenerateIcon icon="close" />
                                        </IconButton>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="form-container-of-demo-form-modal-box">
                            <div className="content-of-form-container-of-demo-form-modal-box">
                                <GenerateForm name={formDetails.name} handleSubmitForm={handleSubmit} />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className="mask-of-demo-form-container-wrapper" onClick={() => hideDemoForm()}></div>
        </div>
    )
}