import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import ChipsApp, { NFCChipInterface } from './ChipsApp';
import UpdateChipsDetailsForm from "./UpdateChipsDetailsForm"
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';

export default function RegisterNewChipPage() {

    interface StepInterface {
        label: string,
        content: () => void
    }

    const chipsApp = new ChipsApp()

    const searchParameters = new URLSearchParams(useLocation().search)

    const [activeStep, setActiveStep] = useState<number>(0)
    const [chipUniqueName, setChipUniqueName] = useState<string>("")

    const [loadingChipDetails, setLoadingChipDetails] = useState<boolean>(true)

    const [stepButtonLoadingStatus, setStepButtonLoadingStatus] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [chipDetails, setChipDetails] = useState<NFCChipInterface>({
        id: 0,
        title: "",
        unique_name: "",
        type: "card",
        chip_type: "",
        redirect_url: "",
        card_id: 0,
        company_id: 0,
        user_id: 0,
        is_registered: false,
        is_public: true,
        is_active: true,
        date_created: "",
        folder_id: 0,
        company_name: "",
        user_name: ""
    })

    /**
     * Handle next step
     */
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
    
    /**
     * Handle back
     */
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
    
    /**
     * Handle reset
     */
    const handleReset = () => {
        setActiveStep(0)
    }

    /**
     * Get chip details
     */
    const loadChipDetails = (customUniqueName: string | boolean = false) => {

        setStepButtonLoadingStatus(true)

        chipsApp.getSingleChipDetails(customUniqueName ? String(customUniqueName) : chipUniqueName, true)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:
                        setChipDetails(data)
                        setErrorMessage("")
                        handleNext()
                        setStepButtonLoadingStatus(false)
                        setLoadingChipDetails(false)
                        break

                }

            })
            .catch(error => {
                chipsApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setStepButtonLoadingStatus(false)
                setErrorMessage(message)
                setLoadingChipDetails(false)
            })

    }

    /**
     * Handle change unique name
     */
    const handleChangeUniqueName = (e: React.ChangeEvent<HTMLInputElement>) => setChipUniqueName(e.target.value)

    /**
     * Find chip step content
     */
    const findChipContent = () => {
        return(
            <div className="chip-unqiue-name-field-register-box">
                <div className="content-of-chip-unqiue-name-field-register-box">
                    <TextField id="unique_name" name="unique_name" label="Chip unique name" variant="outlined" fullWidth value={chipUniqueName} onChange={handleChangeUniqueName} placeholder="5A22DEC5-0AEC-40A7-A" error={errorMessage ? true : false} helperText={errorMessage} />

                    <Box sx={{ mb: 2 }}>
                        <LoadingButton
                            variant="contained"
                            onClick={() => loadChipDetails()}
                            sx={{ mt: 1, mr: 1 }}
                            loading={stepButtonLoadingStatus}
                            disabled={chipUniqueName ? false : true}
                        >
                            Continue
                        </LoadingButton>
                    </Box>

                </div>
            </div>
        )
    }

    /**
     * Chip Details
     */
    const chipDetailsSteps = () => {
        return (
            <div className="chip-details-box">
                <div className="content-of-chip-details-box">
                    {!loadingChipDetails && <UpdateChipsDetailsForm uniqueName={chipUniqueName} customChipDetails={chipDetails} />}
                </div>
            </div>
        )
    }

    /**
     * Update unique name by search
     */
    const updateUniqueNameBySearch = () => {
        const uniqueName = searchParameters.get("unique_name")

        if (uniqueName){
            setChipUniqueName(uniqueName)
            loadChipDetails(uniqueName)
        }

    }

    /**
     * Use Effect
     */
    useEffect(() => {
        
        updateUniqueNameBySearch()

    }, [])

    return(
        <div className="admin-panel-single-section-container">
            <div className="content-of-admin-panel-single-section-container">
                
                <div className="register-chip-step-box-container">
                    <div className="content-of-register-chip-step-box-container">
                        <Stepper activeStep={activeStep} orientation="vertical">
                          
                            <Step key={0}>
                                <StepLabel>Register NFC Chip</StepLabel>
                                <StepContent style={{ paddingTop: 10 }}>
                                    {findChipContent()}
                                </StepContent>
                            </Step>

                            <Step key={1}>
                                <StepLabel>Details</StepLabel>
                                <StepContent style={{ paddingTop: 10 }}>
                                    {chipDetailsSteps()}
                                </StepContent>
                            </Step>

                        </Stepper>
                        
                        {activeStep === 2 && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>All steps completed - you&apos;re finished</Typography>
                            </Paper>
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
}