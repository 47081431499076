import React from 'react'
import { Link, useParams } from 'react-router-dom'
import GenerateIcon from '../../FormElements/GenerateIcon'
import AdminPanel from '../AdminPanel'
import { AdminPanelMenuItem } from '../Interfaces'
import { useReturnCurrentUser } from '../../Api/User/userSlice'

export default function AdminMenuItem(props: AdminPanelMenuItem) {

    const { icon, name, title } = props
    const adminPanel = new AdminPanel()
    const currentSectionName = useParams().section_name!
    let containerName = "single-admin-panel-menu-item"

    if (currentSectionName){
        if (currentSectionName === name){
            containerName += " active"
        }
    }

    const currentSection = adminPanel.returnSingleAdminSectionDetails(name)
    const usersAllowed = currentSection.usersAllowed

    const currentUser = useReturnCurrentUser()

    let displayInMenu: boolean = currentSection.displayInMenu
    let hideItem: boolean = adminPanel.hideMenuItem(currentSection.usersAllowed, currentUser)

    if (hideItem) {
        displayInMenu = false
    }

    if (!displayInMenu){
        return <></>
    }else{
        return(
            <div className={containerName}>
                <a href={"/panel/" + name}>
                    <div className="content-of-single-admin-panel-menu-item">
    
                        <div className="icon-box-of-single-admin-panel-menu-item">
                            <div className="content-of-icon-box-of-single-admin-panel-menu-item">
                                <GenerateIcon icon={icon} />
                            </div>  
                        </div>  
    
                        <div className="text-box-of-single-admin-panel-menu-item">
                            <div className="content-of-text-box-of-single-admin-panel-menu-item">
                                <h3>{title}</h3>
                            </div>
                        </div>
    
                    </div>
                </a>
            </div>
        )
    }

   
}