import React, { useEffect, useState } from 'react'
import CardsApp, { LinkTypeInterface, SingleCardLinkInterface } from './CardsApp'
import { Grid, Skeleton } from '@mui/material'
import GenerateIcon from "../../FormElements/GenerateIcon"
import LoadSingleSlider from '../sliders/LoadSingleSlider'
import { title } from 'process'
import NoThumb from "../../../assets/svg/no-thumb.svg"

interface Props extends SingleCardLinkInterface {
    loading?: boolean,
    primaryColor?: string,
    personName?: string,
    textColor?: string,
    subTitleColor?: string
}

export default function CardLink(props: Props) {

    const cardsApp = new CardsApp()

    const [disableTitle, setDisableTitle] = useState<boolean>(false)

    useEffect(() => {

        switch(props.type){

            case "googlemaps":
                setDisableTitle(true)
                break

        }

    }, [])

    const baseCardLinkContainer = (link: SingleCardLinkInterface, loading?: boolean) => {

        const linkType: LinkTypeInterface = cardsApp.findLinkDetailsByName(link.type)
        let formatUrl = cardsApp.formatLink(link.url, linkType.isSocial, link.type === "link" ? true : false)

        let is_embeded_video: boolean = link.is_embeded_video

        if (link.type === "googlemaps"){
            is_embeded_video = true
        }

        if (link.type === "iframe"){
            is_embeded_video = true
        }

        if (link.type === "address" || link.type === "title"){
            formatUrl = link.url
        }


        /**
         * Render iframe
         */
        const renderIframe = () => {

            switch(link.type) {

                case "vimeo":
                    return cardsApp.returnVimeoEmbededVideo(link.url)
                    break

                case "googlemaps":
                    return(
                        <div className="google-maps-link-box">
                            <div className="content-of-google-maps-link-box">
                                <iframe src={link.url}></iframe>
                                {props.title && (
                                    <div className="title-box-of-slider-card-container" style={{ backgroundColor: props.primaryColor }}>
                                        <div className="content-of-title-box-of-slider-card-container">
                                            <h3>{props.title}</h3>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                    break

                case "iframe":
                    // return <div dangerouslySetInnerHTML={{ __html: link.url }} />
                    return <iframe src={link.url}></iframe>
                    break

                default:
                    return
            }
        }

        return(
            <a href={cardsApp.checkLink(link.url, link.type, props.personName)}>
                <div className="single-card-link-box">
                    <div className="content-of-single-card-link-box">
                        {is_embeded_video ? (
                            <div className="emdebed-video-of-single-card-link-box">
                                <div className="content-of-emdebed-video-of-single-card-link-box">
                                    {link.title && !disableTitle && <h3>{link.title}</h3>}
                                    <div className="iframe-of-emdebed-video-of-single-card-link-box">{renderIframe()}</div>
                                </div>
                            </div>
                        ) : (
                            <Grid container>

                                <Grid item xs={2}>
                                    {loading ? <Skeleton variant="circular" width={50} height={50} /> : (
                                        <div className="icon-box-of-single-card-link-box" style={{ backgroundColor: props.primaryColor ? props.primaryColor : "#4f7bf7" }}>
                                            <div className="content-of-icon-box-of-single-card-link-box">
                                                <GenerateIcon icon={link.type} />
                                            </div>
                                        </div>
                                    )}
                                </Grid>

                                <Grid item xs={10}>
                                    <div className="text-box-of-single-card-link-box">
                                        <div className="content-of-text-box-of-single-card-link-box">
                                            <h3 style={{ color: props.textColor }}>{loading ? <Skeleton variant="text" width={100} /> : link.type === "link" || link.type === "linkedin" ? link.title ? link.title : formatUrl : formatUrl}</h3>
                                            {loading ? <h4 ><Skeleton variant="text" width={50} /></h4> : link.title && <h4 style={{ display: link.type === "link" || link.type === "linkedin" ? "none" : "block", color: props.subTitleColor }}>{link.title}</h4>}
                                        </div>
                                    </div>
                                </Grid>

                            </Grid>
                        )}
                    </div>
                </div>
            </a>
        )
    }

    switch(props.type){

        case "title":
            return(
                <div className="title-box-of-single-card-link-box" style={{ backgroundColor: props.primaryColor }}>
                    <div className="content-of-title-box-of-single-card-link-box">
                        <h3>{props.url}</h3>
                    </div>
                </div>
            )
            break

        case "slider":

            let sliderId: number = typeof props.url !== "number" ? Number(props.url) : props.url

            if (!sliderId){
                return <></>;
            }

            return (
                <div className="single-card-link-box">
                    <div className="content-of-single-card-link-box">

                        <div className="single-slider-in-digital-business-card-box">
                            <div className="content-of-single-slider-in-digital-business-card-box">
                                
                                <div className="slider-card-container">
                                    <LoadSingleSlider sliderId={sliderId} />
                                    {props.title && (
                                        <div className="title-box-of-slider-card-container" style={{ backgroundColor: props.primaryColor }}>
                                            <div className="content-of-title-box-of-slider-card-container">
                                                <h3>{props.title}</h3>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            )

        case "image":
            return(
                <div className="single-card-link-box">
                    <div className="content-of-single-card-link-box">

                        <div className="single-card-thumbnail-box">
                            <div className="content-of-single-card-thumbnail-box">
                                <div className="img-box-of-single-card-thumbnail-box" style={{ backgroundImage: `url(${props.url ? props.url : NoThumb})` }}></div>
                                {props.title && (
                                    <div className="title-box-of-slider-card-container" style={{ backgroundColor: props.primaryColor }}>
                                        <div className="content-of-title-box-of-slider-card-container">
                                            <h3>{props.title}</h3>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            )


        case "pdf":
            return(
                <div className="single-card-link-box">
                    <div className="content-of-single-card-link-box">

                       <a href={props.url} target="_blank">
                            <div className="single-card-thumbnail-box">
                                <div className="content-of-single-card-thumbnail-box">
                                    <div className="img-box-of-single-card-thumbnail-box" style={{ backgroundImage: `url(${props.url ? props.url.replace(".pdf", "_preview.jpg") : NoThumb})` }}></div>
                                    {props.title && (
                                        <div className="title-box-of-slider-card-container" style={{ backgroundColor: props.primaryColor }}>
                                            <div className="content-of-title-box-of-slider-card-container">
                                                <h3>{props.title}</h3>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                       </a>

                    </div>
                </div>
            )

        case "editor":

            if (!props.url){
                return <></>
            }

            return(
                <div className="editor-for-single-card-link-box" style={{ color: props.textColor }}>
                    <div className="content-of-editor-for-single-card-link-box">
                        <div dangerouslySetInnerHTML={{ __html: cardsApp.decodeEditor(props.url) }} />
                    </div>
                </div>
            )

        case "text":
            return(
                <div className="editor-for-single-card-link-box" style={{ color: props.textColor }}>
                    <div className="content-of-editor-for-single-card-link-box">
                        <p>{props.url}</p>
                    </div>
                </div>
            )

        default:
            return baseCardLinkContainer(props, props.loading)
    }

}