import Api from "./Api";
import axios, { AxiosResponse } from "axios"
import { AxiosRequestConfig, AxiosDefaultDataObject } from "./Interfaces";
import { DataDisplayerSingleSearchParam } from "../DataDisplayer/Interfaces";

type searchParams = Array<DataDisplayerSingleSearchParam>
export interface ApiEndpointInterface {
    baseApiUrl: string,
    appName: string,
    appBaseEndPointPath: string,
    sendRequest: (config: AxiosRequestConfig) => Promise<AxiosResponse<any, any>>,
    logResults: (...args: any) => void,
    createNewInstance: (data: AxiosDefaultDataObject) => Promise<AxiosResponse<any, any>>,
    deleteInstance: (id: number) => Promise<AxiosResponse<any, any>>,
    getSingleInstanceDetails: (id: number) => Promise<AxiosResponse<any, any>>,
    listOfInstances: (page: number, search: string|null, disablePagination: boolean, searchParams?: searchParams ) => Promise<AxiosResponse<any, any>>,
}

/**
 * Api End Point Base Class
 */
export default class ApiEndpoint implements ApiEndpointInterface{

    baseApiUrl: string = Api.returnBaseUrl()
    appName: string = "noname"
    appBaseEndPointPath: string = "noname"

    /**
     * Send request
     */
    sendRequest = async(config: AxiosRequestConfig) => {
        config.baseURL = this.baseApiUrl

        const response = await axios(config)
        return response
    }

    /**
     * Log results
     */
    logResults = (...args: any) => Api.log(...args)

    /**
     * Create new instance
     */
    createNewInstance = async(data: AxiosDefaultDataObject) => this.sendRequest({
        method: "PUT",
        data,
        url: this.appBaseEndPointPath + "/",
        withCredentials: true
    })

    /**
     * Delete Instance
     */
    deleteInstance = (id: number) => this.sendRequest({
        method: "DELETE",
        url: this.appBaseEndPointPath + "/" + id,
        withCredentials: true
    })

    /**
     * Get single instance details
     */
    getSingleInstanceDetails = (id: number) => this.sendRequest({
        method: "GET",
        url: this.appBaseEndPointPath + "/" + id,
        withCredentials: true
    })


    /**
     * List of instances
     */
    listOfInstances = async(page: number, search: string | null, disablePagination: boolean, searchParams?: searchParams) => {

        let path: string = this.appBaseEndPointPath

        path += `?page=${page ? page : 1}`

        if (search !== null){
            path += "&search=" + search
        }

        if (searchParams && searchParams.length > 0){
            searchParams.map((searchParam: DataDisplayerSingleSearchParam) => {
                path += `&${searchParam.name}=${searchParam.value}`
            })
        }

        if (disablePagination){
            path += "&disable_pagination=1"
        }

        const config: AxiosRequestConfig = {
            method: "GET",
            url: path,
            withCredentials: true
        }

        return this.sendRequest(config)
    }

}