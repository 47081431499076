import React from 'react'
import RequestPasswordSetForm from "./components/RequestPasswordSetForm"

export default function RequestPasswordSetPage() {

    const pathname: string = window.location.pathname

    return(
        <div className="sign-in-page-container">
            <div className="content-of-sign-in-page-container">
                <RequestPasswordSetForm resetPasswordForm={pathname.includes("reset-password") ? true : false} />
            </div>
        </div>
    )
}