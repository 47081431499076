import { AxiosDefaultDataObject } from "../Api/Interfaces";
import MirajWarehouseApiEndpoint from "../Api/MirajWarehouseApiEndpoint";
import { BaseAppInterface } from "./BaseApp" 

export default class MirajWarehouseBaseApp extends MirajWarehouseApiEndpoint implements BaseAppInterface {
    
    /**
     * Required fields
     */
     requiredFields: Array<string> = []

     /**
      * Data Displayer Fields
      */
 
     /**
      * Return required fields
      */
    returnRequiredFields = () => {
         const requiredFields = this.requiredFields
         let formattedFields: Array<string> = []
 
         if (requiredFields && requiredFields.length > 0){
             requiredFields.map((field: string) => formattedFields.push(this.appName + "_" + field))
         }
 
         return formattedFields
 
     }

    /**
     * For the new api */ 

    /**
     * Create new instance
     */
     createNewInstance = async(data: AxiosDefaultDataObject) => {

        if (data.update){
            return this.updateInstance(data)
        }

        return this.sendRequest({
            method: "POST",
            data,
            url: this.appBaseEndPointPath + "/",
            withCredentials: true
        })
    }

    /**
     * Update Instance
     */
    updateInstance = async(data: AxiosDefaultDataObject) => {
        const pluralize = require("pluralize")
        const itemId = data[pluralize.singular(this.appName) + "_id"]


        return this.sendRequest({
            method: "PUT",
            data,
            url: this.appBaseEndPointPath + "/" + itemId,
            withCredentials: true
        })
    }
 
}