import BaseApp from "../BaseApp";
import SliderEditor from "./SliderEditor"
import EditSliderPage from "./EditSliderPage"

/**
 * Slide Interface
 */
export interface SlideInterface {
    id: number,
    img: string,
    order_num: number
}

/**
 * Slider Interface
 */
export interface SliderInterface {
    id: number,
    title: string,
    slides: Array<SlideInterface>,
    display_title?: boolean,
    user_id: number,
    date_created: string
}

/**
 * Companies App
 */
export default class SlidersApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "sliders"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "sliders"

    /**
     * Required fields
     */
    requiredFields = [
        "title",
    ]

    /**
     * Add new page
     */
    addNewAdminContentPage = () => {
        return(
            <div className="admin-panel-single-section-container">
                <div className="content-of-admin-panel-single-section-container">
                    <SliderEditor />
                </div>
            </div>
        )
    }

    /**
     * Edit page
     */
    editAdminContentPage = () => {
        return <EditSliderPage />
    }

}