import React, { useEffect } from 'react'
import LoginForm from './components/LoginForm'
import User from '../../Api/User/User'
import { useLocation } from 'react-router-dom'

export default function SignInPage() {

    const user = new User()

    const { search } = useLocation()
    const parameters = new URLSearchParams(search)

    const backLink = parameters.get("back") 

    useEffect(() => {
        user.verifyAccessToken()
            .then(response => {
                window.location.replace(backLink ? backLink : "/")
            })
    }, [])

    return(
        <div className="sign-in-page-container">
            <div className="content-of-sign-in-page-container">
                <LoginForm />
            </div>
        </div>
    )
}