import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SlidersApp, { SlideInterface, SliderInterface } from './SlidersApp'
import { addSlide, addSliders, updateSlider, useReturnSingleSlider } from './slidersSlice'
import SingleSlider from "./SingleSlider"
import { Button, FormControlLabel, IconButton, Switch, TextField } from '@mui/material'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import SingleEditableSlide from "./SingleEditableSlide"
import GenerateIcon from '../../FormElements/GenerateIcon'
import GenerateMessageBox, { MessageBoxTypes } from '../../FormElements/GenerateMessageBox'
import { AxiosDefaultDataObject } from '../../Api/Interfaces'
import { LoadingButton } from '@mui/lab'

interface Props {
    update?: boolean,
    sliderId?: number
}

export default function SliderEditor(props: Props) {

    const dispatch = useDispatch()

    const { update } = props
    const sliderId: number = update ? props.sliderId ? props.sliderId : 0 : 1

    const slidersApp = new SlidersApp()

    const currentSlider: SliderInterface = useReturnSingleSlider(sliderId)

    const [submitButtonLoading, setSubmitButtonLoading] = useState<boolean>(false)
    const [submitButtonDisabledStatus, setSubmitButtonDisabledStatus] = useState<boolean>(false)

    const [loadingSliderDetails, setLoadingSliderDetails] = useState<boolean>(false)

    /**
     * Submit Request Response Text
     */
    interface ISubmitRequestResponse {
        type: MessageBoxTypes,
        title: string,
        message: string,
        display: boolean
    }
    const [submitRequestResponse, setSubmitRequestResponse] = useState<ISubmitRequestResponse>({
        type: "info",
        title: "No title",
        message: "No message",
        display: false
    })

    /**
     * Load slider
     */
    const loadSlider = () => {
        setLoadingSliderDetails(true)

        slidersApp.getSingleInstanceDetails(sliderId)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:
                        const slider: SliderInterface = data 
                        dispatch(addSliders([slider]))
                        break

                }

                setLoadingSliderDetails(false)

            })
            .catch(error => {
                slidersApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setSubmitRequestResponse({
                    display: true,
                    type: "error",
                    message: message,
                    title: "Error"
                })
                setLoadingSliderDetails(false)
            })
    }

    /**
     * Use effect
     */
    useEffect(() => {

        if (!update){

            const sliders: Array<SliderInterface> = [
                {
                    id: 1,
                    title: "Simple Slider",
                    slides: [],
                    display_title: true,
                    date_created: "",
                    user_id: 0
                }
            ]

            dispatch(addSliders(sliders))

        }else{
            loadSlider()
        }

    }, [])

    /**
     * Update Title
     */
    const updateSliderTitle = (title: string) => {
        let slider = {
            ...currentSlider,
            title
        }
        
        dispatch(updateSlider(slider))
    }

    /**
     * Update Title
     */
    const updateSliderDisplayTitle = (display_title: boolean) => {
        let slider = {
            ...currentSlider,
            display_title
        }
        
        dispatch(updateSlider(slider))
    }


    const reorder = (list: Array<SlideInterface>, startIndex: number, endIndex: number) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        let reorderedItems: Array<SlideInterface> = []

        let orderNum: number = 1
        result.map((item: SlideInterface) => reorderedItems.push({
            id: item.id,
            img: item.img,
            order_num: orderNum++,
        }))

        return reorderedItems
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const content = reorder(currentSlider.slides, result.source.index, result.destination.index)

        let slider = {
            ...currentSlider,
            slides: content
        }

        dispatch(updateSlider(slider))
    }

    /**
     * Create new slide 
     */
    const createNewSlide = () => {

        const slideId = currentSlider.slides.length + 1

        dispatch(addSlide({
            slide: {
                id: slideId,
                img: "",
                order_num: slideId
            },
            sliderId
        }))

        const slideElem = document.getElementById("single-slide-" + slideId)


        if (slideElem !== undefined){
            slideElem?.scrollIntoView({behavior: 'smooth'})
        }

    }

    /**
     * Submit 
     */
    const handleSubmit = () => {

        let data: AxiosDefaultDataObject = {
            id: currentSlider.id,
            title: currentSlider.title,
            slides: currentSlider.slides,
            display_title: currentSlider.display_title,
            update: props.update,
            slider_id: currentSlider.id 
        }

        setSubmitButtonLoading(true)

        slidersApp.createNewInstance(data)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:

                        setSubmitRequestResponse({
                            display: true,
                            type: "success",
                            message: `${currentSlider.title} has been successfully ${props.update ? "updated" : "created"}`,
                            title: "Success!"
                        })

                        setTimeout(() => window.location.replace("/panel/sliders"), 1500)

                        break

                }

                setSubmitButtonLoading(false)
                setSubmitButtonDisabledStatus(true)

            })
            .catch(error => {
                slidersApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setSubmitRequestResponse({
                    display: true,
                    type: "error",
                    message: message,
                    title: "Error"
                })
                setSubmitButtonLoading(false)
            })

    }

    /**
     * Generate Response Message
     */
    const generateResponseMessage = () => {
        if (!submitRequestResponse.display){
            return <></>
        }

        return (
            <div className="request-response-message-container">
                <div className="content-of-request-response-message-container">
                    <GenerateMessageBox type={submitRequestResponse.type} title={submitRequestResponse.title} message={submitRequestResponse.message} />
                </div>
            </div>
        )
    }

    return(
        <div className="slider-editor-container">
            <div className="content-of-slider-editor-container">

                <SingleSlider {...currentSlider} />

                <div className="slider-options-container">
                    <div className="content-of-slider-options-container">

                        <div className="form-of-slider-options-container">
                            <div className="content-of-form-of-slider-options-container">
                                <TextField id="outlined-basic" label="Title" variant="outlined" value={currentSlider.title} onChange={(e) => updateSliderTitle(e.target.value)} fullWidth />
                                <FormControlLabel control={<Switch checked={currentSlider.display_title} onChange={() => updateSliderDisplayTitle(currentSlider.display_title ? false : true)} />} label="Display Title" />
                            </div>
                        </div>

                        <div className="slides-of-slider-options-container">
                            <div className="content-of-slides-of-slider-options-container">
                                <h3 className="slides-editor-title">
                                    Slides
                                    <IconButton onClick={() => createNewSlide()}>
                                        <GenerateIcon icon="add" />
                                    </IconButton>
                                </h3>

                                {currentSlider.slides.length === 0 && (
                                    <div>
                                        <GenerateMessageBox type="info" title="Nothing..." message="" customMessage={() => <p>You haven't added any slides yet. <a style={{ cursor: "pointer" }} onClick={() => createNewSlide()}>Create a new slide</a></p>} />
                                    </div>
                                )}

                                <DragDropContext
                                    onDragEnd={onDragEnd}
                                >
                                    <Droppable droppableId="links">
                                        {(provided) => <ul ref={provided.innerRef} {...provided.droppableProps}>{currentSlider.slides.length > 0 && currentSlider.slides.map((slide: SlideInterface, index: number) => <Draggable key={index} draggableId={index.toString()} index={index}>
                                        {(provided) => (
                                            <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                <SingleEditableSlide {...slide} sliderId={currentSlider.id} />
                                            </li>
                                        )}
                                        </Draggable>)}{provided.placeholder}</ul>}
                                    </Droppable>
                                </DragDropContext>

                                <div className="add-new-link-box" onClick={() => createNewSlide()}>
                                    <div className="content-of-add-new-link-box">
                                        <GenerateIcon icon="add" />
                                        <h3>Add a new slide</h3>
                                    </div>
                                </div>

                                <div className="action-buttons-of-slider-editor-container">
                                    <div className="content-of-action-buttons-of-slider-editor-container">
                                        <LoadingButton variant="contained" loading={submitButtonLoading} disabled={submitButtonDisabledStatus} onClick={() => handleSubmit()} >Save</LoadingButton>
                                    </div>
                                </div>

                                {generateResponseMessage()}

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}