import ApiEndpoint, { ApiEndpointInterface } from "../Api/ApiEndpoint";
import { AxiosDefaultDataObject } from "../Api/Interfaces";

export interface BaseAppInterface extends ApiEndpointInterface {
    baseApiUrl: string,
    appName: string,
    appBaseEndPointPath: string,
    requiredFields: Array<string>,
    returnRequiredFields: () => Array<string>,
    viewAdminContentPage?: () => void,
    editAdminContentPage?: () => void,
    addNewAdminContentPage?: () => void,
    customBasePageContent?: () => void,
    customBackLinkForDefaultForm?: (data: AxiosDefaultDataObject, update: boolean) => string
}

export default class BaseApp extends ApiEndpoint implements BaseAppInterface {
    
    /**
     * Required fields
     */
    requiredFields: Array<string> = []

    /**
     * Data Displayer Fields
     */

    /**
     * Return required fields
     */
    returnRequiredFields = () => {
        const requiredFields = this.requiredFields
        let formattedFields: Array<string> = []

        if (requiredFields && requiredFields.length > 0){
            requiredFields.map((field: string) => formattedFields.push(this.appName + "_" + field))
        }

        return formattedFields

    }

}