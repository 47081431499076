import React, { useEffect } from 'react'
import CompaniesApp, { CompanyInterface } from './CompaniesApp'
import { useDispatch } from 'react-redux'
import { addNewForm } from '../../FormElements/formsSlice'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementDisabledStatus, updateElementLoadingStatus } from '../../FormElements/formsDataSlice'
import GenerateForm from '../../FormElements/GenerateForm'
import { AxiosDefaultDataObject } from '../../Api/Interfaces'
import FormValidator from '../../FormElements/FormValidator'

interface Props {
    company: CompanyInterface,
    loading: boolean,
    update?: boolean
}

export default function CompanyEditForm(props: Props) {

    const dispatch = useDispatch()

    const formDetails = {
        name: "company_form",
        elemPrefix: "company_form_elem_"
    }

    const elemPrefix = formDetails.elemPrefix

    const companiesApp = new CompaniesApp()

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value

    /**
     * Update form details
     */
    const updateFormDetails = (company: CompanyInterface) => {
        /**
         * Register form
         */
        dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "title",
                        label: "Title",
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "description",
                        label: "Description",
                        maxRows: 4,
                        minRows: 4,
                        multiline: true,
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "address",
                        label: "Address",
                        maxRows: 4,
                        minRows: 4,
                        multiline: true,
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 6,
                        id: elemPrefix + "email",
                        label: "Email",
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 6,
                        id: elemPrefix + "phone",
                        label: "Phone",
                        fullWidth: true
                    },
                    {
                        type: "ImageUploader",
                        xs: 12,
                        id: elemPrefix + "thumbnail",
                        label: "Thumbnail",
                        description: "Here you can upload the company image"
                     },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: "Update",
                        onClick: () => {},
                        isSubmit: true
                    },
                ]
            })
        )

        /**
         * Update Form Elements data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "title",
                    value: company.title
                },
                {
                    name: elemPrefix + "description",
                    value: company.description
                },
                {
                    name: elemPrefix + "address",
                    value: company.address
                },
                {
                    name: elemPrefix + "email",
                    value: company.email
                },
                {
                    name: elemPrefix + "phone",
                    value: company.phone
                },
                {
                    name: elemPrefix + "thumbnail",
                    value: company.thumbnail
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
                {
                    name: elemPrefix + "submit",
                    value: ""
                }
            ])
        )
    }

    /**
     * Handle submit
     */
    const handleSubmit = () => {

        updateButtonLoadingStatus(true)

        let data: AxiosDefaultDataObject = {
            update: props.update,
            company_id: props.company.id,
            title: getSingleFormElementValue("title"),
            description: getSingleFormElementValue("description"),
            address: getSingleFormElementValue("address"),
            email: getSingleFormElementValue("email"),
            phone: getSingleFormElementValue("phone"),
            thumbnail: getSingleFormElementValue("thumbnail")
        }

        let requiredFields: Array<string> = []

        companiesApp.requiredFields.map(field => requiredFields.push(elemPrefix + field))

        /**
         * Form Validator
         */
        const formValidator = new FormValidator(requiredFields)

        if (formValidator.isValid().valid){
            companiesApp.createNewInstance(data)
                .then(response => {

                    const { status, statusText, data } = response
            
                    switch(status){

                        case 200:
                            
                            updateButtonLoadingStatus(false)
                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: `${data.title} has been updated`,
                                    severity: "success",
                                    hidden: false
                                })
                            )

                            break

                    }

                })
                .catch(error => {
                    companiesApp.logResults(error)

                    const { status, statusText, data } = error.response 
                    const message = data.message ? data.message : statusText

                    dispatch(
                        createOrUpdateElementData({
                            name: elemPrefix + "alert",
                            value: message,
                            severity: "error",
                            hidden: false
                        })
                    )
                    updateButtonLoadingStatus(false)
                })
        }else{
            updateButtonLoadingStatus(false)
            formValidator.showRequiredFields(dispatch)
        }

    }

    /**
     * Use effect
     */
    useEffect(() => {
        updateFormDetails(props.company)
    }, [props.company])

    return <GenerateForm name={formDetails.name} handleSubmitForm={handleSubmit} />
}