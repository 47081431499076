import { Button } from '@mui/material'
import React, { KeyboardEvent } from 'react'
import { FormElementData, FormButtonElement } from '../Interfaces'
import LoadingButton from '@mui/lab/LoadingButton';

interface Props extends FormButtonElement{
    data: FormElementData,
    handleSubmitForm?: () => void
}

export default function FormElementButton(props: Props) {
    const data = props.data
    return <LoadingButton loading={data.loading} disabled={data.disabled} id={props.id} variant="contained" fullWidth={props.fullWidth} onClick={() => { props.isSubmit! ? props.handleSubmitForm ? props.handleSubmitForm() : props.onClick && props.onClick() : console.log("clicked") }}>{props.label}</LoadingButton>
}