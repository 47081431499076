import React, { useEffect, useState } from 'react'
import ChipsApp, { NFCChipInterface } from "./ChipsApp"
import { CircularProgress, Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { createOrUpdateElementData, updateElementDisabledStatus, updateElementHiddenStatus, updateElementLoadingStatus, useReturnFormElementData } from '../../FormElements/formsDataSlice'
import { returnSingleFormElementData } from '../../FormElements/formsDataSlice'
import { addNewForm } from '../../FormElements/formsSlice'
import { addManyElementsData } from '../../FormElements/formsDataSlice'
import GenerateForm from '../../FormElements/GenerateForm'
import CardsApp from '../cards/CardsApp'
import CompaniesApp from "../companies/CompaniesApp"
import { useReturnCurrentUser } from '../../Api/User/userSlice'
import { AxiosDefaultDataObject } from '../../Api/Interfaces'
import FormValidator from '../../FormElements/FormValidator'
import FoldersApp from './FoldersApp'
import { useLocation } from 'react-router-dom'

interface Props {
    uniqueName: string,
    customChipDetails?: NFCChipInterface
}

export default function UpdateChipsDetailsForm(props: Props) {

    const dispatch = useDispatch()
    const searchParameters = new URLSearchParams(useLocation().search)

    const chipsApp = new ChipsApp()
    const foldersApp = new FoldersApp()
    const currentUser = useReturnCurrentUser()
    const formElementData = useReturnFormElementData()
    const [chipDetails, setChipDetails] = useState<NFCChipInterface>({
        id: 0,
        title: "",
        unique_name: "",
        type: "card",
        chip_type: "",
        redirect_url: "",
        card_id: 0,
        company_id: 0,
        user_id: 0,
        is_registered: false,
        is_public: true,
        is_active: true,
        date_created: "",
        folder_id: 0,
        company_name: "",
        user_name: ""
    })
    const [loading, setLoading] = useState<boolean>(false)

    const { uniqueName, customChipDetails } = props

    /**
     * Form
     */
    const formDetails = {
        name: "chip_details_form",
        elemPrefix: "chip_details_form_elem_"
    }

    const elemPrefix = formDetails.elemPrefix

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Update Element hidden status
     */
    const updateFormElementHiddenStatus = (name: string, hidden: boolean) => dispatch(
        updateElementHiddenStatus({
            name,
            hidden
        })
    )

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value

    /**
     * Handle Submit
     */
    const handleSubmit = () => {
        updateButtonLoadingStatus(true)

        let data: AxiosDefaultDataObject = {
            update: true,
            chip_id: chipDetails.unique_name,
            unique_name: chipDetails.unique_name,
            title: getSingleFormElementValue("title"),
            type: getSingleFormElementValue("type"),
            chip_type: getSingleFormElementValue("chip_type"),
            redirect_url: getSingleFormElementValue("redirect_url"),
            card_id: getSingleFormElementValue("card_id"),
            company_id: getSingleFormElementValue("company_id"),
            user_id: getSingleFormElementValue("user_id"),
            is_registered: true,
            is_public: getSingleFormElementValue("is_public"),
            folder_id: getSingleFormElementValue("folder_id")
        }

        /**
         * Form Validator
         */
        let requiredFields: Array<string> = [
            elemPrefix + "title",
            elemPrefix + "type"
        ]

        if (data.type === "card"){
            requiredFields.push(elemPrefix + "card_id")
        }

        if (data.type === "link"){
            requiredFields.push(elemPrefix + "redirect_url")
        }

        const formValidator = new FormValidator(requiredFields)

        if (formValidator.isValid().valid){
            /**
             * Send request to API
             */
            chipsApp.createNewInstance(data)
                .then(response => {
                    
                    const { status, statusText, data } = response

                    switch(status){

                        case 200:
                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: chipDetails.title + " has been successfully updated!",
                                    severity: "success"
                                })
                            )

                            let redirectTo = "/panel/chips"

                            setTimeout(() => window.location.replace(redirectTo), 1500)
                            break

                    }

                    updateButtonLoadingStatus(false)
                    updateButtonDisabledStatus(true)

                })
                .catch(error => {
                    updateButtonLoadingStatus(false)
                    
                    const response = error.response
                    chipsApp.logResults(response)
                    
                    switch(response.status){

                        default:
                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                                    severity: "error"
                                })
                            )
                    }
                
                })
        }else{
            updateButtonLoadingStatus(false)
            formValidator.showRequiredFields(dispatch)
        }

    }

    /**
     * Load chip details
     */
    const loadChipsDetails = () => {
        setLoading(true)

        chipsApp.getSingleChipDetails(uniqueName, false)
            .then(response => {
                        
                const { status, statusText, data } = response

                switch(status){

                    case 200:
                        setChipDetails(data)
                        updateFormElementsData(data)
                        break

                }

                setLoading(false)

            })
            .catch(error => {
                updateButtonLoadingStatus(false)
                
                const response = error.response
                chipsApp.logResults(response)

                setLoading(false)
                
                switch(response.status){

                    default:
                        dispatch(
                            createOrUpdateElementData({
                                name: elemPrefix + "alert",
                                value: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                                severity: "error"
                            })
                        )
                }
            
            })

    }

    /**
     * Update form element data
     */
    const updateFormElementsData = (customDetails?: NFCChipInterface) =>  {

        const details = customDetails ? customDetails : chipDetails

        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "title",
                    value: details.title
                },
                {
                    name: elemPrefix + "unique_name",
                    value: details.unique_name
                },
                {
                    name: elemPrefix + "type",
                    value: details.type
                },
                {
                    name: elemPrefix + "chip_type",
                    value: details.chip_type,
                    hidden: currentUser.is_superuser ? false : true,
                },
                {
                    name: elemPrefix + "redirect_url",
                    value: details.redirect_url
                },
                {
                    name: elemPrefix + "card_id",
                    value: details.card_id
                },
                {
                    name: elemPrefix + "company_id",
                    value: currentUser.company_id ? currentUser.company_id : details.company_id
                },
                {
                    name: elemPrefix + "user_id",
                    value: details.user_id ? details.user_id : currentUser.id
                },
                {
                    name: elemPrefix + "is_public",
                    value: details.is_public
                },
                {
                    name: elemPrefix + "folder_id",
                    value: details.folder_id
                },
                {
                    name: elemPrefix + "submit",
                    value: ""
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
            ])
        )
    }

    /**
     * Use effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
        dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 6,
                        id: elemPrefix + "title",
                        label: "Title",
                        fullWidth: true
                    },
                    {
                        type: "Select",
                        xs: 6,
                        id: elemPrefix + "type",
                        label: "Type",
                        fullWidth: true,
                        items: chipsApp.chipTypes
                    },
                    {
                        type: "Select",
                        xs: 12,
                        id: elemPrefix + "chip_type",
                        label: "NFC Chip Type",
                        fullWidth: true,
                        items: chipsApp.nfcChipTypes
                    },
                    {
                        type: "DynamicSelect",
                        xs: 12,
                        id: elemPrefix + "card_id",
                        label: "Business Card",
                        fullWidth: true,
                        idFieldName: "id",
                        titleFieldName: "title",
                        appClass: new CardsApp(),
                        formName: formDetails.name,
                    },
                    {
                        type: "TextField",
                        fieldType: "url",
                        xs: 12,
                        id: elemPrefix + "redirect_url",
                        label: "Redirect to external url",
                        fullWidth: true
                    },
                    {
                        type: "DynamicSelect",
                        xs: 12,
                        id: elemPrefix + "folder_id",
                        label: "Folder",
                        fullWidth: true,
                        idFieldName: "id",
                        titleFieldName: "title",
                        appClass: new FoldersApp(),
                        formName: formDetails.name,
                    },
                    {
                        type: "Switch",
                        xs: 12,
                        id: elemPrefix + "is_public",
                        label: "Public",
                        fullWidth: true,
                    },
                    // {
                    //     type: "DynamicSelect",
                    //     xs: 12,
                    //     id: elemPrefix + "company_id",
                    //     label: "Company",
                    //     fullWidth: true,
                    //     idFieldName: "id",
                    //     titleFieldName: "title",
                    //     appClass: new CompaniesApp(),
                    //     formName: formDetails.name,
                    // },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: "Save",
                        // fullWidth: true,
                        onClick: () => {},
                        isSubmit: true
                    }
                ]
            })
        )

        updateFormElementsData()

        /**
         * Add new form elements data
         */

        if (customChipDetails){
            setChipDetails(customChipDetails)
            updateFormElementsData(customChipDetails)
        }else{
            loadChipsDetails()
        }

        /**
         * Update folder_id
         */

        const folderId = searchParameters.get("folder_id")

        if (folderId){
            dispatch(createOrUpdateElementData({
                name: elemPrefix + "folder_id",
                value: folderId
            }))
        }

    }, [props])

    useEffect(() => {

        const type = getSingleFormElementValue("type")

        switch(type){

            case "card":
                updateFormElementHiddenStatus(elemPrefix + "card_id", false)
                updateFormElementHiddenStatus(elemPrefix + "redirect_url", true)
                break

            case "link":
                updateFormElementHiddenStatus(elemPrefix + "card_id", true)
                updateFormElementHiddenStatus(elemPrefix + "redirect_url", false)
                break

        }

    }, [formElementData])
    
    /**
     * Loading preloader
     */
    let loadingPreloaderClassName: string = "loading-chip-details-box"

    if (loading){
        loadingPreloaderClassName = loadingPreloaderClassName + " active"
    }

    return(
        <div className="update-chip-details-form-container">
            <div className="content-of-update-chip-details-form-container">

                <div className={loadingPreloaderClassName}>
                    <div className="content-of-loading-chip-details-box">
                        <CircularProgress />
                    </div>
                </div>

                <GenerateForm name={formDetails.name} handleSubmitForm={handleSubmit} />

            </div>
        </div>
    )
}