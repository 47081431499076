import React, { useState } from 'react'
import { FormElementData, FormElementImageUploaderInterface } from '../Interfaces'
import { Alert, Button, IconButton } from '@mui/material'
import GenerateIcon from '../GenerateIcon'
import UploadImage, { UploadImageInterface } from '../UploadImage'
import GenerateMessageBox, { MessageBoxPropsInterface, MessageBoxTypes } from '../GenerateMessageBox'
import { LoadingButton } from '@mui/lab'
import NoThumb from "../../../assets/svg/no-thumb.svg"

interface Props extends FormElementImageUploaderInterface{
    data: FormElementData,
    handleOnChange: (name: string, value: any) => void
}

export default function FormElementPDFUploader(props: Props) {
    const { label, description, data, handleOnChange, id } = props
    const uploadedImg = data.value

    interface MessageBoxDetailsInterfaceState {
        display: boolean,
        type: MessageBoxTypes,
        title: string
    }

    const [messageBoxDetails, setMessageBoxDetails] = useState<MessageBoxDetailsInterfaceState>({
        display: false,
        type: "info",
        title: "No title",
    })

    /**
     * loading
     */
    const [loading, setLoading] = useState<boolean>(false)

    /**
     * Upload image
     */
    const uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        if (event.target.files && event.target.files[0] !== undefined){
            const imageUploader = new UploadImage()
            const uploadImage = imageUploader.uploadPdf(event)

            if (uploadImage !== undefined){
                setLoading(true)
                uploadImage
                    .then(response => {
                        imageUploader.logResults(response)

                        const { status, statusText, data } = response

                        switch(status){
                            
                            case 200:
                                if (data.pdf){
                                    let baseUrl = imageUploader.baseApiUrl
    
                
                                    let uplaodedImage = data.pdf
    
                                    if (uplaodedImage[0] === "/"){
                                        uplaodedImage = uplaodedImage.substring(1)
                                    }
    
                                    uplaodedImage = baseUrl + uplaodedImage
    
                                    handleOnChange(id, uplaodedImage)
                                }else{
                                    setMessageBoxDetails({
                                        display: true,
                                        type: "info",
                                        title: "Image was not provided from the API"
                                    })
                                }
                                break;

                            default:
                                setMessageBoxDetails({
                                    display: true,
                                    type: "info",
                                    title: data.message ? data.message : statusText
                                })
                        }

                        setLoading(false)

                    })
                    .catch(error => {
                        imageUploader.logResults(error)

                        const { status, statusText, data } = error.response

                        setMessageBoxDetails({
                            display: true,
                            type: "error",
                            title: data.message ? data.message : statusText
                        })

                        setLoading(false)

                    })
            }

        }

    }

    /**
     * Open image uploader
     */
    const openImageUploader = () =>  {
        if (document.getElementById("file_uploader_of_" + id) !== null) {
            document.getElementById("file_uploader_of_" + id)!.click()
        }
    }
    const deleteImage = () => {
        handleOnChange(id, "")
    }

    return(
        <div className="form-element-image-uploader-container">
            <div className="content-of-form-element-image-uploader-container">

                <form style={{ 
                    display: "none"
                 }}>
                    <input type="file" id={"file_uploader_of_" + id} onChange={uploadImage} accept=".pdf" />
                    <input name={id} type="hidden" value={data.value} />
                </form>

                <div className="text-box-of-form-element-image-uploader-container">
                    <div className="content-of-text-box-of-form-element-image-uploader-container">
                        <h3>
                            <IconButton style={{
                                margin: "-2px 5px 0 0"
                            }} onClick={() => openImageUploader()}>
                                <GenerateIcon icon="upload" />
                            </IconButton>
                            {label}
                        </h3>
                        <p>{description ? description : ""}</p>
                    </div>
                </div>

                <div className="image-box-of-form-element-image-uploader-container">
                    <div className="content-of-image-box-of-form-element-image-uploader-container">
                        <img src={uploadedImg ? uploadedImg.replace(".pdf", "_preview.jpg") : NoThumb} />
                    </div>
                </div>

                <div className="buttons-container-of-form-element-image-uploader-container">
                    <div className="content-of-buttons-container-of-form-element-image-uploader-container">

                        <LoadingButton loading={loading} startIcon={<GenerateIcon icon="add" />} onClick={() => openImageUploader()} variant="outlined">
                            Upload
                        </LoadingButton>

                        <LoadingButton disabled={loading} startIcon={<GenerateIcon icon="delete" />} onClick={() => deleteImage()}>
                            Delete
                        </LoadingButton>

                    </div>
                </div>  
            
                {messageBoxDetails.display === true && (
                    <div className="message-box-of-form-element-image-uploader-container">
                        <div className="content-of-message-box-of-form-element-image-uploader-container">
                            <Alert severity={messageBoxDetails.type}>
                                {messageBoxDetails.title}
                            </Alert>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}