import { AxiosDefaultDataObject, AxiosRequestConfig } from "../../Api/Interfaces";
import BaseApp from "../BaseApp";

/**
 * Folder Interface
 */
export interface FolderInterface {
    id: number,
    title: string,
    company_id: number,
    is_active: boolean,
    date_created: string
}

/**
 * Companies App
 */
export default class FoldersApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "folders"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "nfc-chips/f/folders"

    /**
     * Required fields
     */
    requiredFields = [
        "title",
        "company_id"
    ]

    /**
     * List of instances
     */
    listOfChipsByFolder = async(page: number, search: string | null, disablePagination: boolean, folderId: number) => {

        let path: string = this.appBaseEndPointPath + "/" + folderId + "/chips"

        path += `?page=${page ? page : 1}`

        if (search !== null){
            path += "&search=" + search
        }


        if (disablePagination){
            path += "&disable_pagination=1"
        }

        const config: AxiosRequestConfig = {
            method: "GET",
            url: path,
            withCredentials: true
        }

        return this.sendRequest(config)
    }

    /**
     * Create new instance
     */
    createNewInstance = async(data: AxiosDefaultDataObject) => this.sendRequest({
        method: "PUT",
        data,
        url: this.appBaseEndPointPath,
        withCredentials: true
    })


}