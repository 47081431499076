import { Grid } from "@mui/material"
import React from "react"
import MirajLogo from "../../../assets/svg/hitap-logo.svg"
import Menu from "./Menu"

export default function Footer(){
    return(
        <footer className="miraj-warehouse-footer">
            <div className="content-of-miraj-warehouse-footer content-section-wrapper">
            
                <Grid container spacing={2}>

                    <Grid item xs={2}>
                        <div className="info-box-of-miraj-warehouse-footer">
                            <div className="content-of-info-box-of-miraj-warehouse-footer">

                                <div className="logo-box-of-info-box-of-miraj-warehouse-footer">
                                    <div className="content-of-logo-box-of-info-box-of-miraj-warehouse-footer">
                                        <a href="/"><img src={MirajLogo} /></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={10}>
                        <div className="menu-of-miraj-warehouse-footer">
                            <div className="content-of-menu-of-miraj-warehouse-footer">
                                <Menu />
                            </div>
                        </div>
                    </Grid>

                </Grid>

            </div>
        </footer>
    )
}