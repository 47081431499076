import { FormControl, FormControlLabel, FormHelperText, Switch, TextField } from '@mui/material'
import React from 'react'
import { FormElementData, FormTextFieldElement } from '../Interfaces'
import LoadingFieldElement from './LoadingFieldElement'

interface Props extends FormTextFieldElement{
    data: FormElementData,
    handleOnChange: (name: string, value: any) => void,
}

export default function FormElementSwitch(props: Props) {
    const data = props.data

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target
        props.handleOnChange(target.name, target.checked)
    }

    return(
        <FormControl fullWidth={props.fullWidth}>
            <FormControlLabel control={<Switch checked={data.value} id={props.id} name={props.id} onChange={handleOnChange} />} label={props.label} />
            {data.displayHelperText && (
                <FormHelperText error={data.helperTextType === "error" ? true : false}>
                    {data.helperText}
                </FormHelperText>
            )}
        </FormControl>
    )
}