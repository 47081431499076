import { CircularProgress } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState, store } from '../../app/store'

export default function PagePreloader() {

    let containerClassName: string = "page-preloader-container"
    const loadingStatus = useSelector((state: RootState) => state.pagePreloader).loading

    if (loadingStatus){
        containerClassName += " active"
    }

    return(
        <div className={containerClassName}>
            <div className="content-of-page-preloader-container">
                <CircularProgress />
            </div>
        </div>
    )
}