import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import PagePreloader from '../PagePreloader/PagePreloader'
import updatePageTitle from '../Router/components/updatePageTitle'
import { BasePageProps } from '../Router/Interfaces'
import ErrorPage from './Error/ErrorPage'
import UserAuthorizedPage from './UserAuthorizedPage'

import { Helmet } from "react-helmet";

export default function BasePage(props: BasePageProps) {

    const errorPage = useSelector((state: RootState) => state.errorPage.details)

    useEffect(() => {
        updatePageTitle(props.page.title)
    })

    let noFavicon: boolean = false

    /**
     * Check Page
     */
    switch(props.page.name) {

        case "single-card":
            noFavicon = true
            break
        
        case "single-nfc-chip":
            noFavicon = true
            break

    }

    const reactHemlet = (
        <Helmet>
            {!noFavicon && <link rel="icon" href="https://hitapdigital.com/media/favicon.ico" />}
        </Helmet>
    )

    /**
     * Render content
     */
    const renderPage = () => {

        if (props.page.disableBaseStructure === true){
            return(
                <React.Fragment>
                    {reactHemlet}
                    <PagePreloader />
                    <props.page.component page={props.page} />
                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                    {reactHemlet}
                    <PagePreloader />
                    <div className="page-wrapper">
    
                        <main className="main-page-content">
                            <div className="wrapper-of-main-page-content">
                                <props.page.component page={props.page} />
                            </div>
                        </main>
                    </div>
                </React.Fragment>
            )
        }
    }

    if (errorPage.show === true){
        return(
            <>
                {reactHemlet}
                <ErrorPage {...errorPage} />
            </>
        )
    }else{
        if (props.page.isUserAuthorizedContent){
            return(
                <>
                    {reactHemlet}
                    <UserAuthorizedPage onlyAdmin={props.page.onlyAdmin} accessDeniedMessage={props.page.accessDeniedMessage}>
                        {renderPage()}
                    </UserAuthorizedPage>
                </>
            )
        }else{
            return renderPage()
        }
    }


}