import React from 'react'
import ChipsApp, { NFCChipInterface } from './ChipsApp'
import { IconButton, Skeleton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'

interface Props extends NFCChipInterface {
    loading?: boolean
}

export default function FolderChip(props: Props) {


    const chipsApp = new ChipsApp()
    const { id, unique_name, title, type, redirect_url, card_id, company_id, user_id, is_registered, is_public, folder_id, company_name, user_name, loading } = props

    /**
     * Info Boxes
     */
    const infoBoxes: Array<{ title: string, value: string | number }> = [
        {
            title: "Title",
            value: title
        },
        {
            title: "Type",
            value: chipsApp.returnTypeTitle(type)
        },
        {
            title: "Unique name",
            value: unique_name
        },
    ]

    /**
     * Render single info box
     */
    const renderSingleInfoBox = (title: string, value: string | number, index: number) => {
        return(
            <div className="single-info-box-of-folder-chip" key={index} style={{ maxWidth: 300, minWidth: 250 }}>
                <div className="content-of-single-info-box-of-folder-chip">
                    <h4>{loading ? <Skeleton variant="text" width={50} /> : title}</h4>
                    <h3>{loading ? <Skeleton variant="text" width={100} /> : value}</h3>
                </div>
            </div>
        )
    }

    return(
        <div className="single-folder-chip-box">
            <div className="content-of-single-folder-chip-box">

                <div className="info-boxes-of-single-folder-chip-box">
                    <div className="content-of-info-boxes-of-single-folder-chip-box">
                        {infoBoxes.map((infoBox, index: number) => renderSingleInfoBox(infoBox.title, infoBox.value, index))}
                    </div>
                </div>

                <div className="buttons-container-of-data-displayer-single-item">
                    <div className="content-of-buttons-container-of-data-displayer-single-item">

                        <a href={"/chip/" + unique_name} target="_blank">
                            <IconButton>
                                <GenerateIcon icon="view" />
                            </IconButton>
                        </a>

                        <a href={"/panel/chips/edit/" + unique_name}>
                            <IconButton>
                                <GenerateIcon icon="edit" />
                            </IconButton>
                        </a>

                    </div>
                </div>

            </div>
        </div>
    )
}