import React from 'react'
import { ISingleCardProps } from "../SingleCard"
import CardsApp from '../CardsApp'
import { Skeleton } from '@mui/material'
import NoThumb from "../../../../assets/svg/no-user.svg"
import CardLinks from '../CardLinks'

export default function CardTemplate02(props: ISingleCardProps) {

    const { id, title, first_name, last_name, middle_name, position, headline, user_id, company_id, design_id, is_public, is_active, unique_name, thumbnail, date_created, primary_color, company_name, loading, company_logo, text_color, card_color, subtext_color, hide_company_name, hide_position } = props
    const cardsApp = new CardsApp()

    const fullName: string = cardsApp.returnFullName(first_name, last_name, middle_name)

    return(
        <div className="single-business-card-container card-template-02" style={{ backgroundColor: card_color }}>
            <div className="content-of-single-business-card-container">

                <header className="card-template-02-header-box">
                    <div className="content-of-card-template-02-header-box">

                        <div className="avatar-box-of-card-template-02-header-box">
                            <div className="content-of-avatar-box-of-card-template-02-header-box">
                                {loading ? <Skeleton variant="rectangular" width="100%" height="100%" /> : <div className="thumb-of-avatar-box-of-card-template-02-header-box" style={{ backgroundImage: `url(${thumbnail ? thumbnail : NoThumb})` }}></div>}
                            </div>
                        </div>

                    </div>
                </header>

                <div className="card-template-02-user-info-box">
                    <div className="content-of-card-template-02-user-info-box">
                        {company_logo && <img src={company_logo} className="single-card-company-logo-img" />}
                        <h3>{loading ? <Skeleton variant="text" width={150} style={{ margin: "0 auto" }} /> : fullName}</h3>
                        {!hide_position && <h4>{loading ? <Skeleton variant="text" width={90} style={{ margin: "0 auto" }} /> : position}</h4>}
                        {!hide_company_name && <p>{loading ? <Skeleton variant="text" width={50} style={{ margin: "0 auto" }} /> : company_name}</p>}
                    </div>
                </div>

                {headline && (
                    <div className="headline-box-of-single-business-card-container">
                        <div className="content-of-headline-box-of-single-business-card-container">
                            {loading ? (
                                <div>
                                    <Skeleton variant="text" width="100%" />
                                    <Skeleton variant="text" width="80%" />
                                    <Skeleton variant="text" width="60%" />
                                </div>
                            ): <p>{headline}</p>}
                        </div>
                    </div>
                )}

                {!loading && <CardLinks cardId={id} customLinks={props.customLinks} showCustomLinks={props.showCustomLinks} cardLoading={loading} primaryColor={primary_color} personName={first_name} textColor={text_color} subTitleColor={subtext_color} />}

            </div>
        </div>
    )
}