import React from 'react'
import NotFoundSVG from "../../../assets/svg/not-found-error-alert.svg"

interface Props {
    title?: string,
    message?: string
}

export default function NotFoundMessage(props: Props) {

    const title: string = props.title ? props.title : "Not Found"
    const message: string = props.message ? props.message : "This page doesn't exist"

    return(
        <div className="not-found-page-message-container">
            <div className="content-of-not-found-page-message-container">
                
                <div className="not-found-page-icon-container">
                    <div className="content-0f-not-found-page-icon-container">
                        <img src={NotFoundSVG} />
                    </div>
                </div>

                <div className="text-box-of-not-found-page-icon-container">
                    <div className="content-of-text-box-of-not-found-page-icon-container">
                        <h1>{title}</h1>
                        <p>{message}</p>
                    </div>
                </div>  

            </div>
        </div>
    )
}