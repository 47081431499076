import React, { useEffect, useState } from 'react'
import ChipsApp, { NFCChipInterface } from './ChipsApp'
import GenerateMessageBox, { MessageBoxTypes } from '../../FormElements/GenerateMessageBox'
import { DataDisplayerPagination } from "../../DataDisplayer/Interfaces"
import FolderChip from './FolderChip'
import FoldersApp from './FoldersApp'
import { LoadingButton } from '@mui/lab'
import GenerateIcon from '../../FormElements/GenerateIcon'

interface Props {
    folderId: number,
    disanbleNothingFoundMessage?: boolean
}

export default function ChipsByFolder(props: Props) {

    const chipsApp = new ChipsApp()
    const foldersApp = new FoldersApp()

    const [chips, setChips] = useState<Array<NFCChipInterface>>([])
    const [loadingChips, setLoadingChips] = useState<boolean>(true)
    const [pagination, setPagination] = useState<DataDisplayerPagination>({
        count: 1,
        current_page: 1,
        total_pages: 1
    })

    const [folderId, setFolderId] = useState<number>(props.folderId)

    /**
     * Load chips
     */
    const loadChips = (page: number, search: string) => {
        setLoadingChips(true)

        foldersApp.listOfChipsByFolder(page, search, false, folderId)
            .then(response => {

                const { status, statusText, data } = response
        
                switch(status){

                    case 200:

                        let chipsArray: Array<NFCChipInterface> = [
                            ...chips
                        ]
                        
                        if (data.results && data.results.length > 0){
                            data.results.map((chip: NFCChipInterface) => {
                                chipsArray.push(chip)
                            })
                        }
                        
                        setChips(chipsArray)

                        setPagination({
                            count: data.count ? data.count : 0,
                            current_page: page,
                            total_pages: data.total_pages ? data.total_pages : 0
                        })

                        break

                }

                setLoadingChips(false)

            })
            .catch(error => {
                chipsApp.logResults(error)

                const { status, statusText, data } = error.response 
                const message = data.message ? data.message : statusText

                setSubmitRequestResponse({
                    display: true,
                    type: "error",
                    message: message,
                    title: "Error"
                })
                setLoadingChips(false)
            })

    }

    /**
     * Submit Request Response Text
     */
    interface ISubmitRequestResponse {
        type: MessageBoxTypes,
        title: string,
        message: string,
        display: boolean
    }

    const [submitRequestResponse, setSubmitRequestResponse] = useState<ISubmitRequestResponse>({
        type: "info",
        title: "No title",
        message: "No message",
        display: false
    })

    /**
     * Generate Response Message
     */
    const generateResponseMessage = () => {
        if (!submitRequestResponse.display){
            return <></>
        }

        return (
            <div className="request-response-message-container">
                <div className="content-of-request-response-message-container">
                    <GenerateMessageBox type={submitRequestResponse.type} title={submitRequestResponse.title} message={submitRequestResponse.message} />
                </div>
            </div>
        )
    }

    /**
     * effect
     */
    useEffect(() => {

        loadChips(1, "")

    }, [])

    useEffect(() => {
        setFolderId(props.folderId)
    }, [props.folderId])

    /**
     * Render chips
     */
    const renderChips = () => {

        let folderChips: Array<NFCChipInterface> = []

        if (loadingChips){
            for (let i = 0;i < 5;i ++){
                folderChips.push({
                    id: i,
                    title: "",
                    unique_name: "",
                    type: "card",
                    chip_type: "card",
                    redirect_url: "",
                    card_id: 0,
                    company_id: 0,
                    user_id: 0,
                    is_registered: false,
                    is_public: true,
                    is_active: true,
                    date_created: "",
                    folder_id: 0,
                    company_name: "",
                    user_name: ""
                })
            }
        }else{
            folderChips = chips
        }

        if (folderChips.length === 0 && !props.disanbleNothingFoundMessage){
            return <GenerateMessageBox type="info" title="Nothing" message="You haven't added any NFC chip to the folder yet." />
        }

        return folderChips.map((chip: NFCChipInterface, index: number) => <FolderChip {...chip} key={index} loading={loadingChips} />)

    }

    /**
     * Load more
     */
    const renderLoadMore = () => {
        if (pagination.current_page !== pagination.total_pages){
            return(
                <div className="load-more-item-container">
                    <div className="content-of-load-more-item-container">
                        <LoadingButton loading={loadingChips} variant="contained" startIcon={<GenerateIcon icon="refresh" />} onClick={() => loadChips(pagination.current_page + 1, "")}>
                            Load more
                        </LoadingButton>
                    </div>
                </div>
            )
        }
    }

    return(
        <div className="folder-chips-container">
            <div className="content-of-folder-chips-container">
                {renderChips()}
                {generateResponseMessage()}
                {renderLoadMore()}
            </div>
        </div>
    )
}