import React from 'react'
import { PageComponentProps } from '../../Router/Interfaces'
import NotFoundMessage from "./NotFoundMessage"
import BaseStructuredPage from "../components/BaseStructuredPage"

import NotFoundSVG from "../../../assets/svg/not-found-error-alert.svg"
import { Button } from '@mui/material'

export default function NotFoundPage(props: PageComponentProps) {
    return(
        <BaseStructuredPage>
            <div className="intro-section-home-page">
                <div className="content-of-intro-section-home-page content-section-wrapper">

                    <div className="wrapper-of-intro-section-home-page">

                        <div className="text-box-of-intro-section-home-page">
                            <div className="content-of-text-box-of-intro-section-home-page">

                                <h1>
                                    404
                                </h1>

                                <p>
                                    This page doesn't exist.
                                </p>

                                <div style={{ marginTop: 35 }}>
                                    <Button className="create-card-header-button rounded-button-mui" href="/">Home</Button>
                                </div>

                            </div>
                        </div>

                        <div className="img-box-of-intro-section-home-page">
                            <div className="content-of-img-box-of-intro-section-home-page">
                                <img src={NotFoundSVG} />
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>
        </BaseStructuredPage>
    )
}