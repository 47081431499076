import React, { useEffect, useState } from 'react'
import BaseStructuredPage from "../components/BaseStructuredPage"

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import GenerateIcon from '../../FormElements/GenerateIcon';
import { Skeleton } from '@mui/material';
import HelpSectionsApp from '../../apps/help_center/HelpSectionsApp';
import { useParams } from 'react-router-dom';

import Breadcrumbs from "../components/Breadcrumbs"

import Articles from "./Articles"
import { Helmet } from 'react-helmet';

export default function HelpSection() {

    const [search, setSearch] = useState<string>("")
    const [sectionTitle, setSectionTitle] = useState<string>("")

    const helpSectionsApp = new HelpSectionsApp()

    const params = useParams()
    const slug = params.section_slug ? params.section_slug : ""

    /**
     * Load title
     */
    const loadSectionDetails = () => {
        helpSectionsApp.getSingleSectionDetailsBySlug(slug)
            .then(response => {

                const { data } = response
                setSectionTitle(data.title ? data.title : data.title)

            })
    }

    useEffect(() => {
        loadSectionDetails()
    }, [])

    return(
        <BaseStructuredPage>
            <Helmet>
                {sectionTitle && <title>{sectionTitle} - Help Center</title>}
            </Helmet>
            <div className="intro-section-home-page">
                <div className="content-of-intro-section-home-page content-section-wrapper">

                    <div className="text-box-of-intro-section-home-page" style={{ width: "100%" }}>
                            <div className="content-of-text-box-of-intro-section-home-page" style={{ width: "100%" }}>

                                <h1>
                                    {sectionTitle ? sectionTitle : <Skeleton variant="text" width={100} />}
                                </h1>

                                {sectionTitle &&   <Breadcrumbs breadcrumbs={[
                                    {
                                        title: "Help",
                                        link: "/help",
                                        index: 0,
                                        total: 0
                                    },
                                    {
                                        title: sectionTitle,
                                        index: 0,
                                        total: 0
                                    }
                                ]} />}

                                <div className="search-field-container">
                                    <div className="content-of-search-field-container">
                                        <TextField
                                            id="outlined-adornment-amount"
                                            label="Search"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><GenerateIcon icon="search" /></InputAdornment>,
                                            }}
                                            fullWidth
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                </div>
            </div>

            <Articles section_slug={slug} search={search} />
        </BaseStructuredPage>
    )
}