import BaseApp from "../BaseApp";
import AddNewChipPage from "./AddNewChipPage"
import ViewChipsPage from "./ViewChipsPage"
import EditChipPage from "./EditChipPage"
import { FormSelectSingleItem } from "../../FormElements/Interfaces"
import axios from "axios";

/**
 * Nfc Chip Interface
 */
export interface NFCChipInterface {
    id: number,
    title: string,
    unique_name: string,
    type: string,
    chip_type: string,
    redirect_url: string | null,
    card_id: number,
    company_id: number,
    user_id: number,
    is_registered: boolean,
    is_public: boolean,
    is_active: boolean,
    date_created: string,
    folder_id: number,
    company_name: string,
    user_name: string
}

/**
 * Folder Interface
 */
export interface FolderInterface {
    id: number,
    title: string,
    company_id: number,
    is_active: boolean,
    date_created: string,
}

/**
 * Companies App
 */
export default class ChipsApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "chips"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "nfc-chips"

    /**
     * Required fields
     */
    requiredFields = [
        "title",
        "unique_name",
        "type",
    ]

    /**
     * Add new chip page
     */
    addNewAdminContentPage = () => {
        return <AddNewChipPage />
    }

    /**
     * View Page
     */
    customBasePageContent = () => {
        return <ViewChipsPage />
    }

    /**
     * Edit page
     */
    editAdminContentPage = () => {
        return <EditChipPage />
    }

    /**
     * Get single instance details
     */
    getSingleChipDetails = (unique_name: string, check_is_registered?: boolean) => {

        let link: string = this.appBaseEndPointPath + "/" + unique_name

        if (check_is_registered){
            link = link + "?check_is_registered=1"
        }

        return this.sendRequest({
            method: "GET",
            url: link,
            withCredentials: true
        })
    }

    /**
     * Chip types
     */
    chipTypes: Array<FormSelectSingleItem> = [
        {
            id: "card",
            title: "Digital Business Card",
            subItems: []
        },
        {
            id: "link",
            title: "External url",
            subItems: []
        },
    ]

    /**
     * Nfc chip types
     */
    nfcChipTypes: Array<FormSelectSingleItem> = [
        {
            id: "card",
            title: "Plastic NFC Card",
            subItems: []
        },
        {
            id: "chip",
            title: "Sticker NFC Chip",
            subItems: []
        },
    ]

    /**
     * Return type tittle
     */
    returnTypeTitle = (id: string) => {
        let title: string = ""

        this.chipTypes.map(type => {
            if (type.id === id){
                title = type.title
            }
        })

        return title
    }

    /**
     * Return nfc chip type tittle
     */
    returnNFCChipTypeTitle = (id: string) => {
        let title: string = ""

        this.nfcChipTypes.map(type => {
            if (type.id === id){
                title = type.title
            }
        })

        return title
    }

    /**
     * Generate chip unique name
     */
    generateChipUniqueName = () => this.sendRequest({
        method: "POST",
        data: {},
        url: this.appBaseEndPointPath + "/chip/generate-unique-name",
        withCredentials: true
    })

    /**
     * Download qr code
     */
    downloadQrCode = async(uniqueName: string) => axios({
        baseURL: this.baseApiUrl,
        url: this.appBaseEndPointPath + "/" + uniqueName + "/generate-qr-code",
        method: "GET",
        data: {},
        withCredentials: true,
        responseType: "blob"
    })

}