import { Grid } from '@mui/material'
import React from 'react'
import { BaseSingleFormElement, FormElementsFormElement } from './Interfaces'
import RenderSingleFormElement from './RenderSingleFormElement'

interface Props {
    elements: Array<FormElementsFormElement>,
    handleSubmitForm?: () => void,
    isUpdateForm?: boolean
}

export default function RenderFormElements(props: Props) {

    const elements = props.elements

    if (elements && elements.length > 0){
        return(
            <Grid container>
                {elements.map((element: FormElementsFormElement, index: number) => {
                    return(
                        <Grid item key={index} xs={element.xs}>
                            <RenderSingleFormElement isUpdateForm={props.isUpdateForm} {...element} handleSubmitForm={props.handleSubmitForm} />
                        </Grid>
                    )
                })}
            </Grid>
        )
    }else{
        return (<div></div>)
    }

}