import { Button, Grid, IconButton } from "@mui/material"
import React, { useEffect, useState } from "react"
import MirajLogo from "../../../assets/svg/hitap-logo.svg"
import GenerateIcon from "../../FormElements/GenerateIcon"
import Menu from "./Menu"
import User from "../../Api/User/User"
import { IsUserLoggedInResponse } from "../../Api/Interfaces"
import { showDemoForm } from "../Home/DemoForm"

export default function Header(){

    const renderLogo = () => <img src={MirajLogo} style={{ cursor: "pointer" }} onClick={() => window.location.replace("/")} />
    const [mobileMenu, setMobileMenu] = useState<boolean>(false)

    let mobileMenuClassName: string = "mobile-sidebar-container"

    if (mobileMenu){
        mobileMenuClassName += " active"
    }

    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

    const user = new User()

    useEffect(() => {
       user.isLoggedIn()
        .then((response: IsUserLoggedInResponse) => {
            if (response.isLoggedIn){
                setIsLoggedIn(true)
            }
        })
    }, [])
    
    return(
        <header className="miraj-warehouse-header">
            <div className="content-of-miraj-warehouse-header content-section-wrapper">

                <Grid container spacing={2}>

                    <Grid item xs={2}>
                        <div className="logo-box-of-miraj-warehouse-header">
                            <div className="content-of-logo-box-of-miraj-warehouse-header">
                                {renderLogo()}
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={7} className="grid-item-header-menu-section">
                        <div className="menu-box-of-miraj-warehouse-header">
                            <Menu />
                        </div>

                        <div className="container-of-show-mobile-menu">
                            <div className="show-mobile-menu">
                                <div className="content-of-show-mobile-menu">
                                    <IconButton onClick={() => setMobileMenu(true)}>
                                        <GenerateIcon icon="menu" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={3} className="grid-item-header-buttons">
                        <div className="header-button-right-side">
                            <div className="content-of-header-button-right-side">

                                {isLoggedIn ? (
                                    <>
                                        <Button className="create-card-header-button rounded-button-mui" href="/panel/digitalcards">My Business Cards</Button>
                                    </>
                                ) : (
                                    <>
                                        <Button className="sign-in-header-button rounded-outlined-button-mui" href="/sign-in">Sign-in</Button>
                                        <Button className="create-card-header-button rounded-button-mui" onClick={() => showDemoForm()}>Create a card</Button>
                                    </>
                                )}

                            </div>
                        </div>
                    </Grid>

                </Grid>

                <div className={mobileMenuClassName}>
                    <div className="content-of-mobile-sidebar-container">

                        <div className="mobile-header-container">
                            <div className="content-of-mobile-header-container">

                                <div className="logo-box-of-mobile-sidebar-container">
                                    <div className="content-of-logo-box-of-mobile-sidebar-container">
                                        {renderLogo()}
                                    </div>
                                </div>

                                <div className="show-mobile-menu">
                                    <div className="content-of-show-mobile-menu">
                                        <IconButton onClick={() => setMobileMenu(false)}>
                                            <GenerateIcon icon="close" />
                                        </IconButton>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="menu-of-mobile-sidebar-container">
                            <Menu />
                        </div>

                    </div>
                </div>
                <div className={`mask-of-mobile-sidebar-container ${mobileMenu ? "active" : ""}`}></div>

            </div>
        </header>
    )
}