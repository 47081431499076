import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AxiosDefaultDataObject } from '../../Api/Interfaces'
import { addManyElementsData, returnSingleFormElementData, updateElementDisabledStatus, updateElementLoadingStatus, updateElementsDataFromInstanceDetailsRequest } from '../../FormElements/formsDataSlice'
import { addNewForm } from '../../FormElements/formsSlice'
import GenerateForm from '../../FormElements/GenerateForm'
import { updateLoadingStatus } from '../../PagePreloader/pagePreloaderSlice'
import AdminPanel from '../AdminPanel'
import { FormElementModalSingleButton, FormElementsFormElement } from ".././../FormElements/Interfaces"
import FormValidator from '../../FormElements/FormValidator'
import GenerateModal from '../../FormElements/GenerateModal'
import Api from '../../Api/Api'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import GenerateMessageBox, { MessageBoxTypes } from '../../FormElements/GenerateMessageBox'
import PagePreloader from '../../PagePreloader/PagePreloader'

interface ComponentProps {
    update?: boolean,
    itemId?: string,
    customCurrentSectionName?: string,
    executeInEffect?: () => void,
    customAfterSubmit?: () => void
}

export default function SectionDefaultForm(props: ComponentProps) {

    const { update, itemId } = props

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const pluralize = require("pluralize")
    const parameters = new URLSearchParams(useLocation().search)

    /**
     * Use Effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
        if (form!){
            dispatch(
                addNewForm(form)
            )
        }

        /**
         * Add new form elements data
         */
        if (formData!){
            dispatch(
                addManyElementsData(formData)
            )
        }

        /**
         * Load insatnce details if update
         */
        if (update && itemId){
            if (currentSection.appClass){

                updatePageLoadingStatus(true)

                appClass.getSingleInstanceDetails(Number(itemId))
                    .then(response => {
                        appClass.logResults(response)

                        const { status, statusText, data } = response

                        switch(status){

                            case 200:
                                if (data){
                                    dispatch(updateElementsDataFromInstanceDetailsRequest({
                                        elemPrefix: currentSection.name,
                                        instances: data
                                    }))
                                }else{
                                    setMessageBoxDetails({
                                        display: true,
                                        type: "warning",
                                        message: "Data is empty",
                                        title: "Nothing"
                                    })
                                }
                                break

                        }

                        updatePageLoadingStatus(false)

                    })
                    .catch(error => {
                        appClass.logResults(error)

                        const { status, statusText, data } = error.response 
                        const message = data.message ? data.message : statusText

                        setMessageBoxDetails({
                            display: true,
                            type: "error", 
                            message: message,
                            title: "Error"
                        })

                        updatePageLoadingStatus(false)

                    })
            }
        }

        if (props.executeInEffect){
            props.executeInEffect()
        }

    }, [])

    /**
     * Update page loading status
     */
    const updatePageLoadingStatus = (loading: boolean) => dispatch(updateLoadingStatus({loading}))

    const [ modalDetails, setModalDetails ] = useState({
        open: false,
        title: "No title",
        message: "No message",
    })

    interface IMessageBoxDetails {
        display: boolean,
        type: MessageBoxTypes,
        title: string,
        message: string
    }

    const [ messageBoxDetails, setMessageBoxDetails ] = useState<IMessageBoxDetails>({
        display: false,
        type: "info",
        title: "Title",
        message: "message"
    })

    /**
     * Update modal details
     */
    const updateModalDetails = (open: boolean, title: string, message: string, buttons?: Array<FormElementModalSingleButton>) => setModalDetails({
        open,
        title,
        message,
    })

    const adminPanel = new AdminPanel()
    const currentSectionName = props.customCurrentSectionName ? props.customCurrentSectionName : adminPanel.returnCurrentSectionName()

    const currentSection = adminPanel.returnSingleAdminSectionDetails(currentSectionName)
    const form = currentSection.forms?.defaultForm
    const formData = currentSection.formsData

    const appClass = currentSection.appClass!


    if (!currentSection.appClass){
        return <GenerateMessageBox type="error" title="Error" message="appClass was not provided in the section config" />
    }

    if (form!){
    
        const elemPrefix = currentSectionName + "_"

        /**
         * Update button loading
         */
        const updateButtonLoadingStatus = (loading: boolean) => {
            dispatch(
                updateElementLoadingStatus({
                    name: elemPrefix + "submit",
                    loading
                })
            )
        }
    
        /**
         * Update button loading
         */
        const updateButtonDisabledStatus = (status: boolean) => {
            dispatch(
                updateElementDisabledStatus({
                    name: elemPrefix + "submit",
                    disabled: status
                })
            )
        }

        /**
         * Submit form
         */
        const submitForm = () => {

            if (currentSection.appClass!){

                updateButtonLoadingStatus(true)

                let data: AxiosDefaultDataObject = {}
                const fields = form.elements
                const requiredFields = appClass.returnRequiredFields()

                if (update){
                    data.update = true
                    data[pluralize.singular(currentSection.name) + "_id"] = itemId ? itemId : 0
                }
    
                /**
                 * Add fields into data
                 */
                if (fields && fields.length > 0){
                    fields.map((field: FormElementsFormElement) => {
                        data[field.id.replace(elemPrefix, "")] = returnSingleFormElementData(field.id).value
                    })
                }

                /**
                 * Validate form
                 */
                const formValidator = new FormValidator(requiredFields)

                if (formValidator.isValid().valid){
                    updateButtonLoadingStatus(false)
                    updateButtonDisabledStatus(true)


                    appClass.createNewInstance(data)
                        .then(response => {
                        
                            const { status, statusText, data } = response
        
                            switch(status){
        
                                case 200:
                                    updateModalDetails(false, "Success", data.message! ? data.message : "Success")
                                    setMessageBoxDetails({
                                        display: true,
                                        type: "success",
                                        message: `${data.title ? data.title : "_"} - has been successfully ${update ? "updated" : "created"}`,
                                        title: "Done!"
                                    })

                                    let linkBack = "/panel/" + currentSectionName

                                    if (appClass.customBackLinkForDefaultForm !== undefined){
                                        linkBack = appClass.customBackLinkForDefaultForm(data, update ? true : false)
                                    }

                                    // if (parameters.get("back")){
                                    //     let back = parameters.get("back")
                                    //     if (back === null){
                                    //         back = ""
                                    //     }

                                    //     linkBack = back
                                    // }

                                    if (props.customAfterSubmit){
                                        props.customAfterSubmit()
                                    }else{
                                        setTimeout(() => navigate(linkBack), 1000)
                                    }

                                    break
        
                            }
        
                            updateButtonLoadingStatus(false)
                            updateButtonDisabledStatus(true)
        
                        })
                        .catch(error => {
                            updateButtonLoadingStatus(false)
                            updateButtonDisabledStatus(false)
                            
                            appClass.logResults(error)

                            const { status, statusText, data } = error.response 
                            const message = data.message ? data.message : statusText

                            setMessageBoxDetails({
                                display: true,
                                type: "error",
                                message: message,
                                title: "Error"
                            })
    
                        
                        })

                }else{
                    updateButtonLoadingStatus(false)
                    updateButtonDisabledStatus(false)
                    formValidator.showRequiredFields(dispatch)
                }

            }

        }


        return(
            <React.Fragment>
                <GenerateModal {...modalDetails} updateModalDetails={updateModalDetails} />
                {messageBoxDetails.type !== "success" && <GenerateForm isUpdateForm={props.update} name={form.name} handleSubmitForm={submitForm} />}
                {messageBoxDetails.display && <GenerateMessageBox type={messageBoxDetails.type} title={messageBoxDetails.title} message={messageBoxDetails.message} />}
            </React.Fragment>
        )
    }else{
        return <React.Fragment></React.Fragment>
    }

}