import { Button } from '@mui/material'
import React from 'react'

import MockupImage from "../../../assets/img/mockup-img-2.png"
import { showDemoForm } from "./DemoForm"

export default function IntroSection() {
    return(
        <div className="intro-section-home-page">
            <div className="content-of-intro-section-home-page content-section-wrapper">

                <div className="wrapper-of-intro-section-home-page">

                    <div className="text-box-of-intro-section-home-page">
                        <div className="content-of-text-box-of-intro-section-home-page">

                            <h1>
                                The Best Digital Business Card
                            </h1>

                            <p>
                                For people who want to make a great first impression. Tap and share your contact details instantly with anyone, wherever you go.
                            </p>

                            <Button className="create-card-header-button rounded-button-mui" onClick={() => showDemoForm()}>Get a free demo</Button>

                        </div>
                    </div>

                    <div className="img-box-of-intro-section-home-page">
                        <div className="content-of-img-box-of-intro-section-home-page">
                            <img src={MockupImage} />
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    )
}